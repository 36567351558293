import React, {memo, useContext, useEffect, useMemo, useState} from 'react';
import './LiveTable.scss';
import moment from "moment-timezone";
import {AppContext} from "../../../App";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";
import {LEAGUE_DATA, UNSUBSCRIBE_DATA} from "../../../services/RequestDatas";
import {useTranslation} from "react-i18next";

function LiveTables({firstRound, nextRound, roundData, initMarketRequest, selectedGameID, activeRoundIndex}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const [dataLT, setDataLT] = useState([]);
  const iconsBaseURL = process.env.PUBLIC_URL + './assets/flags/';

  useEffect(() => {
    LEAGUE_DATA.params.where.sport.id = context.activeGameID.get;
    socket.send(JSON.stringify(LEAGUE_DATA));

    socket.addEventListener(RequestManager.LEAGUE_DATA_EVENT, response => initLiveTable(response.detail), {once: true});
    socket.addEventListener(RequestManager.AUTO_LEAGUE_TABLE_DATA, response => initLiveTable(response.detail, true));

    return () => {
      if (LEAGUE_DATA.subID) {
        UNSUBSCRIBE_DATA.params.subid = LEAGUE_DATA.subID;
        socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
        LEAGUE_DATA.subID = null;
      }
    }
  }, [])

  useEffect(() => {
    openSelectedGame(roundData[0]);
  }, [activeRoundIndex])

  const initLiveTable = (response, isUpdate) => {
    if (response.data === undefined && !isUpdate) {
      return;
    }

    if (response.data) {
      LEAGUE_DATA.subID = response.data['subid'];

      let liveTableData = isUpdate ? response.competition : response.data.data.competition;
      liveTableData = liveTableData[Utils.firstKey(liveTableData)].info;
      liveTableData.sort((a, b) => parseFloat(a['Points']) - parseFloat(b['Points'])).reverse();

      setDataLT([...liveTableData]);

      if (!isUpdate) {
        openSelectedGame(roundData[0]);
      }
    }
  }

  const openSelectedGame = gameData => {
    if (context.activeGameID.get === 7) {

      if (roundData[0].round === firstRound || roundData[1].round === nextRound) {
        const videoID = gameData['info']['virtual'][3]['video_id'];
        context.activeVideoID.set(videoID);
      }

      initMarketRequest(gameData['id']);
    }
  }

  const getLiveScores = useMemo(() => {
    let score;
    let team1;
    let team2;
    let teamName1;
    let teamName2;
    let isLastItem;
    let itemClassName;

    return <div className='live-scores-content'>
      {
        roundData.map((gameData, index) => {
          teamName1 = gameData['team1_name'];
          teamName2 = gameData['team2_name'];
          score = gameData['info']['virtual'][2]['SecondHalf'];
          team1 = gameData['info']['virtual'][0]['PlayerName'];
          team2 = gameData['info']['virtual'][1]['PlayerName'];
          isLastItem = index === roundData.length - 1;
          itemClassName = selectedGameID === gameData['id'] ? 'live-score-info active' : 'live-score-info';

          return <div className={isLastItem ? itemClassName + " last-item" : itemClassName}
                      onClick={() => openSelectedGame(gameData)} key={index}>
            <div className="date-data">
              {moment.unix(gameData['start_ts']).format("MM/DD/YY")} &nbsp;&nbsp;
              {Utils.timestampToLocalTime(gameData['start_ts'])}
            </div>
            <div className="teams-data">
              <div className='container'>
                <div className="team-data first">
                  <img src={iconsBaseURL + team1.toLowerCase() + '.png?d=' + Date.now()} alt=''/><p>{teamName1}</p>
                </div>
                <div className="team-data last">
                  <img src={iconsBaseURL + team2.toLowerCase() + '.png?d=' + Date.now()} alt=''/><p>{teamName2}</p>
                </div>
              </div>
            </div>
            <div className="score-data">
              <div className="score player1">{gameData['homescore']}</div>
              <div className="score player2">{gameData['awayscore']}</div>
            </div>
          </div>
        })
      }
    </div>
  }, [roundData, selectedGameID])

  const getUpDownArrow = value => {
    return value !== 0 ? <i className={value > 0 ? 'triangle-up' : 'triangle-down'}/> : null;
  }

  const getLiveTableContent = useMemo(() => {
    return <div className="live-table-content">
      <div className='live-table-block team-data'>
        <div className='team-position-title life-table-item'>{t('position LG')}<p>{t('team')}</p></div>
        {
          dataLT.map(({TeamName, Points, Win, Draw, Lose, GF, GA, GD, UpDown}, index) => {
            const iconName = TeamName.toLowerCase();

            return <div className='team-position-data life-table-item' key={index}> {index + 1}
              <img className='team-flag' src={iconsBaseURL + iconName + '.png'} alt=''/>
              {getUpDownArrow(UpDown)}
              {t(TeamName)}
            </div>
          })
        }
      </div>
      <div className='live-table-block'>
        <div className='team-info-title life-table-item'>
          <p className='table-result title'>{t('played LG')}</p>
          <p className='table-result title'>{t('win LG')}</p>
          <p className='table-result title'>{t('draw LG')}</p>
          <p className='table-result title'>{t('lose LG')}</p>
          <p className='table-result title'>{t('goals for LG')}</p>
          <p className='table-result title'>{t('goals against LG')}</p>
          <p className='table-result title'>{t('goals diff LG')}</p>
          <p className='table-result title'>{t('points LG')}</p>
        </div>
        {
          dataLT.map(({Points, Played, Win, Draw, Lose, GF, GA, GD, UpDown}, index) => {
            return <div className='team-info-block life-table-item' key={index}>
              <p className='table-result'>{Played}</p>
              <p className='table-result'>{Win}</p>
              <p className='table-result'>{Draw}</p>
              <p className='table-result'>{Lose}</p>
              <p className='table-result'>{GF}</p>
              <p className='table-result'>{GA}</p>
              <p className='table-result'>{GD}</p>
              <p className='table-result'>{Points}</p>
            </div>
          })
        }
      </div>
    </div>
  }, [dataLT])

  return <div className="live-tables">
    <div className="live-tables-content">
      <div className='live-scores'>
        <div className="live-table-title">
          <div className="live-icon"/>
          {Utils.upperCase(t('matches'), context.language.get)}
        </div>
        {getLiveScores}
      </div>
      <div className='live-table'>
        <div className='live-table-title'>
          <div className="live-icon"/>
          {Utils.upperCase(t('live table'), context.language.get)}
        </div>
        {getLiveTableContent}
      </div>
    </div>
  </div>
}

export default memo(LiveTables);