import {
  AUTHORIZATION_RID, PARTNER_CONFIG_RID, SPORTS_RID, CURRENT_LEAGUE_RID, GAME_RID, REGION_RID, GAME_RESULTS_RID,
  AUTO_DATA_UPDATE_RID, MARKET_RID, LEAGUE_ROUNDS_RID, CHECK_BET_RID, SWARM_LOGIN_RID, BET_HISTORY_RID,
  WRESTLING_RID, TENNIS_RID, OPENED_BETS_COUNT_RID, OPENED_BETS_RID, PROFILE_RID, CASH_OUT_RID, LOGIN_DATA, MAX_BET_RID,
  RE_CAPTCHA_DATA, BONUS_RID, CASH_OUT_SUBSCRIBE_DATA, GAME_MATCH_ID_RESULT, RE_CAPTCHA_MAX_DATA_RID, CHANGE_LANG_RID, PLACE_BETS_DATA
} from "./RequestDatas";
import Utils from "../utils/Utils"

const SOCKET_URL = 'wss://eu-swarm-virtual-ws.deimosphobos.net/';
const OLD_SOCKET_URL = 'wss://eu-swarm-virtual-ws.betconstruct.com/';
const SOCKET_URL_STAGING = 'wss://swarm-virtuals-staging.betconstruct.com/';

let webSocket;

export default class RequestManager {
  static SWARM_LOGIN_EVENT = 'SWARM_LOGIN_EVENT';
  static SOCKET_CLOSED_EVENT = 'SOCKET_CLOSED_EVENT';
  static AUTHORIZATION_EVENT = 'AUTHORIZATION_EVENT';
  static PARTNER_DATA_EVENT = 'PARTNER_DATA_EVENT';
  static LOGIN_DATA_EVENT = 'LOGIN_DATA_EVENT';
  static CHANGE_LANG_EVENT = 'CHANGE_LANG_EVENT';
  static BONUS_DATA_EVENT = 'BONUS_DATA_EVENT';
  static PROFILE_DATA_EVENT = 'PROFILE_DATA_EVENT';
  static SPORTS_DATA_EVENT = 'SPORTS_DATA_EVENT';
  static REGION_DATA_EVENT = 'REGION_DATA_EVENT';
  static LEAGUE_DATA_EVENT = 'LEAGUE_DATA_EVENT';
  static GAME_DATA_EVENT = 'GAME_DATA_EVENT';
  static GAME_RESULTS_EVENT = 'GAME_RESULTS_EVENT';
  static GAME_MATCH_ID_RESULTS_EVENT = 'GAME_MATCH_ID_RESULTS_EVENT';
  static MARKET_DATA_EVENT = 'MARKET_DATA_EVENT';
  static LEAGUE_ROUNDS_DATA_EVENT = 'LEAGUE_ROUNDS_DATA_EVENT';
  static WRESTLING_DATA_EVENT = 'WRESTLING_DATA_EVENT';
  static TENNIS_DATA_EVENT = 'TENNIS_DATA_EVENT';
  static BET_CHECKED_EVENT = 'BET_CHECKED_EVENT';
  static PLACE_BETS_EVENT = 'PLACE_BETS_EVENT';
  static BET_HISTORY_EVENT = 'BET_HISTORY_EVENT';
  static OPENED_BETS_COUNT_EVENT = 'OPENED_BETS_COUNT_EVENT';
  static OPENED_BETS_EVENT = 'OPENED_BETS_EVENT';
  static CASH_OUT_EVENT = 'CASH_OUT_EVENT';
  static CASH_OUT_SUBSCRIBE_EVENT = 'CASH_OUT_SUBSCRIBE_EVENT';
  static MAX_BET_EVENT = 'MAX_BET_EVENT';
  static RE_CAPTCHA_EVENT = 'RE_CAPTCHA_EVENT';
  static RE_CAPTCHA_MAX_EVENT = 'RE_CAPTCHA_MAX_EVENT';

  static AUTO_GAME_DATA = 'AUTO_GAME_DATA';
  static AUTO_MARKET_DATA = 'AUTO_MARKET_DATA';
  static AUTO_BALANCE_DATA = 'AUTO_BALANCE_DATA';
  static AUTO_SCORES_DATA = 'AUTO_SCORES_DATA';
  static AUTO_LEAGUE_TABLE_DATA = 'AUTO_LEAGUE_TABLE_DATA';
  static AUTO_TENNIS_UPDATE = 'AUTO_TENNIS_UPDATE';
  static AUTO_TENNIS_MARKET_UPDATE = 'AUTO_TENNIS_MARKET_UPDATE';
  static AUTO_CASH_OUT_DATA = 'AUTO_CASH_OUT_DATA';

  static instance = null;

  constructor(siteID) {
    this.setupWebSocket(siteID);
  }

  static getInstance(siteID) {
    if (RequestManager.instance === null) {
      RequestManager.instance = new RequestManager(siteID);
    }

    return RequestManager.instance;
  }

  getSocketURL(siteID) {
    const whiteIDs = [18746641, 1877183, 18747163, 18747874, 18749622, 333];
    if (whiteIDs.includes(parseInt(siteID))) {
      return OLD_SOCKET_URL;
    }

    return (Utils.STAGING_MODE /*|| Utils.DEV_MODE*/) ? SOCKET_URL : SOCKET_URL;
  }

  setupWebSocket(siteID) {
    const socketURL = this.getSocketURL(siteID);
    webSocket = new WebSocket(socketURL);

    webSocket.onmessage = event => {
      const response = JSON.parse(event.data);
      const requestID = response.rid.toString();
      let socketEvent;

      switch (requestID) {
        case SWARM_LOGIN_RID:
          socketEvent = new CustomEvent(RequestManager.SWARM_LOGIN_EVENT, {detail: response});
          break;
        case AUTHORIZATION_RID:
          socketEvent = new CustomEvent(RequestManager.AUTHORIZATION_EVENT, {detail: response});
          break;
        case PARTNER_CONFIG_RID:
          socketEvent = new CustomEvent(RequestManager.PARTNER_DATA_EVENT, {detail: response});
          break;
        case LOGIN_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.LOGIN_DATA_EVENT, {detail: response});
          break;
        case RE_CAPTCHA_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.RE_CAPTCHA_EVENT, {detail: response});
          break;
        case RE_CAPTCHA_MAX_DATA_RID:
          socketEvent = new CustomEvent(RequestManager.RE_CAPTCHA_MAX_EVENT, {detail: response});
          break;
        case CHANGE_LANG_RID:
          socketEvent = new CustomEvent(RequestManager.CHANGE_LANG_EVENT, {detail: response});
          break;
        case PROFILE_RID:
          socketEvent = new CustomEvent(RequestManager.PROFILE_DATA_EVENT, {detail: response});
          break;
        case BONUS_RID:
          socketEvent = new CustomEvent(RequestManager.BONUS_DATA_EVENT, {detail: response});
          break;
        case SPORTS_RID:
          socketEvent = new CustomEvent(RequestManager.SPORTS_DATA_EVENT, {detail: response});
          break;
        case CURRENT_LEAGUE_RID:
          socketEvent = new CustomEvent(RequestManager.LEAGUE_DATA_EVENT, {detail: response});
          break;
        case GAME_RID:
          socketEvent = new CustomEvent(RequestManager.GAME_DATA_EVENT, {detail: response});
          break;
        case REGION_RID:
          socketEvent = new CustomEvent(RequestManager.REGION_DATA_EVENT, {detail: response});
          break;
        case GAME_RESULTS_RID:
          socketEvent = new CustomEvent(RequestManager.GAME_RESULTS_EVENT, {detail: response});
          break;
        case GAME_MATCH_ID_RESULT.rid:
          socketEvent = new CustomEvent(RequestManager.GAME_MATCH_ID_RESULTS_EVENT, {detail: response});
          break;  
        case MARKET_RID:
          socketEvent = new CustomEvent(RequestManager.MARKET_DATA_EVENT, {detail: response});
          break;
        case LEAGUE_ROUNDS_RID:
          socketEvent = new CustomEvent(RequestManager.LEAGUE_ROUNDS_DATA_EVENT, {detail: response});
          break;
        case WRESTLING_RID:
          socketEvent = new CustomEvent(RequestManager.WRESTLING_DATA_EVENT, {detail: response});
          break;
        case TENNIS_RID:
          socketEvent = new CustomEvent(RequestManager.TENNIS_DATA_EVENT, {detail: response});
          break;
        case CHECK_BET_RID:
          socketEvent = new CustomEvent(RequestManager.BET_CHECKED_EVENT, {detail: response});
          break;
        case PLACE_BETS_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.PLACE_BETS_EVENT, {detail: response});
          break;
        case BET_HISTORY_RID:
          socketEvent = new CustomEvent(RequestManager.BET_HISTORY_EVENT, {detail: response});
          break;
        case OPENED_BETS_COUNT_RID:
          socketEvent = new CustomEvent(RequestManager.OPENED_BETS_COUNT_EVENT, {detail: response});
          break;
        case OPENED_BETS_RID:
          socketEvent = new CustomEvent(RequestManager.OPENED_BETS_EVENT, {detail: response});
          break;
        case CASH_OUT_RID:
          socketEvent = new CustomEvent(RequestManager.CASH_OUT_EVENT, {detail: response});
          break;
        case CASH_OUT_SUBSCRIBE_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.CASH_OUT_SUBSCRIBE_EVENT, {detail: response});
          break;
        case MAX_BET_RID:
          socketEvent = new CustomEvent(RequestManager.MAX_BET_EVENT, {detail: response});
          break;
        case AUTO_DATA_UPDATE_RID:
          this.handleAutoEvent(response);
          return;
        default:
        //console.log('REQUEST ID NOT DEFINED: ', response);
      }

      if (socketEvent) {
        webSocket.dispatchEvent(socketEvent);
      }
    };

    webSocket.onclose = () => {
      RequestManager.instance = null;
      webSocket.dispatchEvent(new CustomEvent(RequestManager.SOCKET_CLOSED_EVENT));
      webSocket.close();
    };
  }

  handleAutoEvent({data}) {
    const dataObjects = Object.keys(data).map(key => data[key]);
    let dataString;
    let event;

    dataObjects.forEach(dataObject => {
      dataString = JSON.stringify(dataObject);

      // DETECT TENNIS LIVE UPDATE
      if (dataString.includes('update_state')) {
        event = new CustomEvent(RequestManager.AUTO_TENNIS_UPDATE, {detail: dataObject});
        webSocket.dispatchEvent(event);
      } else if (dataString.includes('"sport":{"14":')) {
        event = new CustomEvent(RequestManager.AUTO_TENNIS_MARKET_UPDATE, {detail: dataObject});
        webSocket.dispatchEvent(event);
      }

      // CHECK REMOVE GLOBAL SPORT CASE(bug from swarm side).
      if (dataObject['sport'] && dataObject['sport'][Utils.firstKey(dataObject['sport'])] === null) {
        return;
      }

      if (dataString.includes('market')) {
        event = new CustomEvent(RequestManager.AUTO_MARKET_DATA, {detail: dataObject});
      } else if (dataString.includes('homescore') || dataString.includes('awayscore')
        || dataString.includes('team1_name') || dataString.includes('team1_region')
        || dataString.includes('team2_name') || dataString.includes('team2_region')) {
        // For handling on League or Wrestling.
        event = new CustomEvent(RequestManager.AUTO_SCORES_DATA, {detail: dataObject});
      } else if (dataString.includes('TeamName')) {
        event = new CustomEvent(RequestManager.AUTO_LEAGUE_TABLE_DATA, {detail: dataObject});
      } else if (dataString.includes('game') && !dataString.includes('competition')) {
        event = new CustomEvent(RequestManager.AUTO_GAME_DATA, {detail: dataObject});
      } else if (dataString.includes('profile')) {
        event = new CustomEvent(RequestManager.AUTO_BALANCE_DATA, {detail: dataObject});
      } else if (dataString.includes('cashout')) {
        event = new CustomEvent(RequestManager.AUTO_CASH_OUT_DATA, {detail: dataObject});
      } else {
        //console.log('>> NOTE DEFINED LIVE DATA:', JSON.stringify(data));
      }

      try {
        webSocket.dispatchEvent(event);
      } catch {
        console.log('>> FAILED DISPATCHING LIVE EVENT:', dataString);
      }
    })
  }

  get webSocket() {
    return webSocket;
  }
}