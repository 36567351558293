import React, {
  memo,
  useRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import "./VideoScene.scss";
import { NEXT_POINT_DATA, START_SCENE_DATA } from "../../../../VSAPI/APIData";
import { TennisContext } from "../../../../../components/PromotionBlock/PromotionBlock";
import PlayersBlock from "../Match/PlayersBlock/PlayersBlock";
import apiRequest from "../../../../VSAPI/ApiRequest";
import IsLoadingHOC from "../../../../Hoc/IsLoadingHOC";
import Utils from "../../../../../utils/Utils";

function VideoScene({ changeScene, setSkipeScene, isSkipScene, setLoading }) {
  const tennisContext = useContext(TennisContext);
  const instantsIconBaseURL =
    process.env.PUBLIC_URL + "./assets/instants/icons/svg/";
  const iconBaseURL = process.env.PUBLIC_URL + "./assets/icons/";
  const MARKET_ID = tennisContext.marketsData.get?.id;
  const data = tennisContext.sceneData.get;
  const API_REQUEST = apiRequest();
  const video = useRef();
  const videoReql = useRef();
  const servingPlayerRef = useRef();
  const gameIsEnded = useRef(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [disableBtns, setDisableBtns] = useState(false);
  const [playerMuted, setPlayerMute] = useState(false);
  const [volumeIcon, setVolumeicon] = useState("");
  const volumeValue = Utils.getStorageData("volumeValueInstants") || 0;
  const videoQuality =
    Utils.getStorageData("videoQuality") !== null
      ? Utils.getStorageData("videoQuality")
      : 0;
  const [quality, setQuality] = useState(videoQuality);
  const [showHideSettings, setShowHideSettings] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!videoLoading) {
      Utils.getVideo(
        data.current_scene.scene_urls[quality],
        video,
        video.current?.currentTime
      );
    }
  }, [data, videoLoading, quality]);

  useEffect(() => {
    if (videoLoading) {
      Utils.getVideo(
        "https://stream-eu3hz.streamerdata.com:8443/vod/VirtualTennis/Nandal/Cutout1/v720p/index.m3u8",
        videoReql
      );
    } else {
      setVolume(volumeValue);
      setDisableBtns(false);
    }
  }, [videoLoading]);

  const nextPoint = () => {
    setDisableBtns(true);
    if (!tennisContext.isMatchEnded.get) {
      NEXT_POINT_DATA.UserMatchID = MARKET_ID;
      API_REQUEST("nextPoint", "POST", NEXT_POINT_DATA)
        .then((res) => {
          tennisContext.marketsData.set(res.next_point);
          tennisContext.balance.set(res.balance);
          servingPlayerRef.current = res.next_point.serving_player;
          setLoading(false);
          setVideoLoading(true);
          isSkipScene ? changeScene(1) : startScene();
        })
        .catch((err) => {
          console.log(err, ">>>");
          setLoading(false);
        });
    } else {
      setDisableBtns(false);
      tennisContext.isMatchEnded.set(false);
      setLoading(false);
      setTimeout(() => {
        changeScene(4);
      }, 200);
    }
  };

  const startScene = () => {
    if (!gameIsEnded.current) {
      START_SCENE_DATA.Events = [];

      API_REQUEST("startScene", "POST", START_SCENE_DATA)
        .then((res) => {
          if (res.message_id === 15) {
            let currentMarketData = tennisContext.marketsData.get;
            currentMarketData.w = res.current_scene.w;
            currentMarketData.away_score = res.current_scene.away_score;
            currentMarketData.home_score = res.current_scene.home_score;
            gameIsEnded.current = true;
            tennisContext.marketsData.set(currentMarketData);
            tennisContext.isMatchEnded.set(true);
          }
          res.current_scene.serving_player = servingPlayerRef.current;
          tennisContext.sceneData.set(res);
          setTimeout(() => {
            setVideoLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const skipScene = () => {
    setLoading(true);
    setSkipeScene(true);
    nextPoint();
  };

  const stopPoint = () => {
    setSkipeScene(true);
  };

  const setVolume = useCallback((value) => {
    Utils.writeStorage("volumeValueInstants", parseInt(value));
    value = value / 100;

    if (video.current) {
      video.current.volume = value;

      if (value === 0) {
        setPlayerMute(true);
        setVolumeicon("volumeXmark.svg");
        return
      } else if (playerMuted) {
        setPlayerMute(false);
      }
    }

    if (value >= 0.5) {
      setVolumeicon("volumeHigh.svg");
    } else {
      setVolumeicon("volumeLow.svg");
    }
  }, []);

  const muteVideo = useCallback(() => {
    const player = video.current;
    const volumeSlider = document.getElementsByClassName(
      "video-volume-instant"
    )[0];

    if (player) {
      const value = playerMuted ? 100 : 0;
      volumeSlider.value = value;
      setVolume(value);
    }
  }, []);

  useEffect(() => {
    Utils.writeStorage("videoQualityInstant", quality);
    setShowHideSettings(false);
  }, [quality]);

  const changeQuality = (value) => {
    setQuality(value);
  };

  const getVideoControls = () => {
    return (
      <div className="video-controls">
        <div className="video-volume">
          <img
            className="control-icon"
            src={instantsIconBaseURL + volumeIcon}
            onClick={muteVideo}
            alt=""
          />
          <input
            className="video-volume-instant"
            type="range"
            min="0"
            max="100"
            defaultValue={volumeValue}
            step="1"
            onChange={(e) => setVolume(e.target.value)}
          />
        </div>

        <div className="setting-container">
          {showHideSettings ? (
            <div className="video-quality">
              <div
                className="quality-icon high-quality"
                onClick={() => changeQuality(0)}
              >
                720
                <img
                  className="hd hd-720"
                  src={iconBaseURL + "hd.svg"}
                  alt=""
                />
              </div>
              <div
                className="quality-icon low-quality"
                onClick={() => changeQuality(1)}
              >
                360
              </div>
              <img
                className={`selected-quality ${quality === 0 ? "hd" : "sd"}`}
                src={iconBaseURL + "selected.svg"}
                alt=""
              />
            </div>
          ) : null}
          <div className="setting-icons">
            <img
              className="setting"
              src={iconBaseURL + "setting.svg"}
              alt=""
              onClick={() => setShowHideSettings(!showHideSettings)}
            />
            {quality === 0 ? (
              <img className="hd-icon" src={iconBaseURL + "hd.svg"} alt="" />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="video-scene">
      <PlayersBlock showScore={true} data={data.current_scene} />
      <div className="ti-video-player-block">
        <div className="player-container">
          {!videoLoading ? (
            <video
              key={data.current_scene.scene_url}
              className="video-player"
              ref={video}
              src=""
              autoPlay
              type="application/x-mpegURL"
              onEnded={nextPoint}
            />
          ) : (
            <video
              key={videoReql}
              className="video-player"
              ref={videoReql}
              src=""
              autoPlay
              type="application/x-mpegURL"
            />
          )}
          {getVideoControls()}
        </div>
      </div>
      <div className="ti-video-scene-footer">
        <button
          className="button"
          onClick={() => {
            changeScene(4);
            tennisContext.isMatchEnded.set(
              tennisContext.sceneData.get.message_id !== 15 ? true : false
            );

            tennisContext.isSkipScene.set(false);
          }}
          disabled={disableBtns}
        >
          END MATCH
        </button>
        <button
          className="button"
          onClick={isSkipScene ? skipScene : stopPoint}
          disabled={disableBtns}
        >
          {isSkipScene ? "SKIP SCENE" : "STOP AFTER POINT"}
        </button>
      </div>
    </div>
  );
}

export default memo(IsLoadingHOC(VideoScene));
