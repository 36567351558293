import React, {memo, useContext, useState} from 'react';
import './SportsBlock.scss';
import moment from 'moment';
import Utils from "../../utils/Utils";
import {AppContext} from "../../App";

function SportTabButton({name, isActive, iconURL, games, clickHandler, isFirst, isLast, alias}) {
  const context = useContext(AppContext);
  const [, setRemainingTime] = useState('');
  const [timestampIndex, setTimestampIndex] = useState(0);
  const gameTimestamps = [];
  const isTennis = alias === 'VirtualTennis';

  let className = isActive ? 'sports-tab-button sports-tab-active' : 'sports-tab-button';
  if (isFirst) {
    className += ' is-first-tab';
  } else if (isLast) {
    className += ' is-last-tab';
  }

  const increaseTimestampIndex = () => {
    setTimestampIndex(timestampIndex + 1);
  }

  const gamesData = Object.keys(games).map(key => games[key]);
  for (let i = 0; i < gamesData.length; ++i) {
    if (gamesData && gamesData[i] && gamesData[i]['start_ts']) {
      gameTimestamps.push(gamesData[i]['start_ts']);
    }
  }
  const getDifference = (endTime, currentTime) => {
    // TODO Wresting start times needs to check.
    const isWrestling = name === 'Virtual Wrestling';

    const formattedEndTime = moment(endTime, "HH:mm:ss");
    const formattedCurrentTime = moment(currentTime, "HH:mm:ss");
    const timeLeft = formattedEndTime.diff(formattedCurrentTime);

    if (timeLeft < 0 && !isWrestling) {
      increaseTimestampIndex();
    }

    return moment.utc(timeLeft).format("mm:ss");
  }

  const getTimeOutBlock = () => {
    if (!isTennis) {
      return null;
    }

    const currentTimestamp = Date.now();
    const nextGameTimestamp = gameTimestamps[timestampIndex];
    const currentTime = moment(currentTimestamp).format('HH:mm:ss');
    const endTime = Utils.timestampToLocalTime(nextGameTimestamp, 'HH:mm:ss');
    let remainingTime = getDifference(endTime, currentTime);

    const interval = setInterval(() => {
      clearInterval(interval);
      setRemainingTime(remainingTime);
    }, 1000);

    const isTennisPreMatch = isTennis && context.tennisData.get['Type'] === 1;
    let remTimeValue = remainingTime === 'Invalid date' ? 'Live' : '';
    let timeOutClass = remainingTime === 'Invalid date' ? 'sport-timeout live' : 'sport-timeout';
    remainingTime = remainingTime === 'Invalid date' ? '' : remainingTime;

    if (isTennisPreMatch) {
      remTimeValue = remainingTime;
      timeOutClass = 'sport-timeout';
    }

    return <div className={timeOutClass}>{remTimeValue}</div>
  }

  const getTabContent = () => {

    return <div className={className} onClick={clickHandler}>
      <div className='sport-icon'><img src={iconURL} alt=''/></div>
      <div className='sport-name'>{name}</div>
      {getTimeOutBlock()}
    </div>
  }

  return getTabContent();
}

export default memo(SportTabButton);