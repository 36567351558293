import './ServerErrorMessage.scss';

function ServerErrorMessage() {
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/Artboard.png';

  return <div className='empty-data'>
    <img src={iconBaseURL} alt=""/>
    <h3 data-text="503">503</h3>
    <p>OOPS! <br/>An Error Occurred</p>
    <p>Sorry, something went wrong, and your action couldn’t <br/> be completed.Please wait for a while and try again.
    </p>
  </div>
}

export default ServerErrorMessage;