import React, {memo, useContext, useState} from 'react';
import './FightsBlock.scss';
import {useTranslation} from 'react-i18next';
import Utils from "../../../../utils/Utils";
import {AppContext} from "../../../../App";

function FightsBlock({fightsData, changeFightCallback, activeFightID}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const [activeKGIndex, setActiveKGIndex] = useState(0);

  const iconsBaseURL = process.env.PUBLIC_URL + './assets/flags/';
  const weightList = [57, 65, 74, 86, 97, 125];
  const graphicData = [4, 2, 1, 1, 1, 2, 4];
  let buttonClassName;

  const getVerticalLine = (itemsCount, isFirst, rightItem) => {
    return (!isFirst || itemsCount === 1) ? null : <div className={'line-v i-' + itemsCount + (rightItem ? ' r' : '')}/>
  }

  const getHorizontalLine = (itemsCount, isFirst, rightItem) => {
    return <div className={'line-h i-' + itemsCount + (rightItem ? ' r' : '')}/>
  }

  const getColumnTitle = (itemsCount, isFinal) => {
    if (itemsCount === 1) {
      return isFinal ? Utils.upperCase(t("final"), context.language.get) : '1/2';
    }

    return itemsCount === 2 ? '1/4' : '1/8';
  }

  const getFormattedName = name => {
    const splitName = name.split(" ");

    return splitName.length > 1 ? splitName[0][0] + '. ' + splitName[1].toUpperCase() : '';
  }

  const getFightsColumnItems = (itemsCount, index, rightItem, isFinal, activeKGFights) => {
    const items = [];
    let isActiveFight;
    let itemClassName;
    let team1FlagURL;
    let team2FlagURL;
    let player1ScoreClassName;
    let player2ScoreClassName;
    let fightIndex = rightItem ? itemsCount : 0;

    if (itemsCount === 2) {
      fightIndex += 8;
    } else if (itemsCount === 1) {
      fightIndex += isFinal ? 14 : 12;
    }

    for (let i = 0; i < itemsCount; i++) {
      const activeFightData = activeKGFights[fightIndex];
      isActiveFight = activeFightData && activeFightData.id === activeFightID;
      itemClassName = "item " + (isActiveFight ? 'active' : (activeFightData && JSON.stringify(activeFightData).indexOf('__') !== -1 ? 'passive' : ''));

      team1FlagURL = '';
      team1FlagURL = '';
      player1ScoreClassName = 'player-score ';
      player2ScoreClassName = 'player-score ';

      if (activeFightData) {
        if ((activeFightData['homescore'] || activeFightData['awayscore']) && activeFightData['awayscore'] !== -1) {
          if (activeFightData['homescore'] === activeFightData['awayscore']) {
            player1ScoreClassName += 'draw';
            player2ScoreClassName += 'draw';
          } else {
            player1ScoreClassName += activeFightData['homescore'] > activeFightData['awayscore'] ? 'winner' : 'defeated';
            player2ScoreClassName += activeFightData['awayscore'] > activeFightData['homescore'] ? 'winner' : 'defeated';
          }
        }

        team1FlagURL = activeFightData['team1_region'] ? iconsBaseURL + activeFightData['team1_region'].toLowerCase() + '.png' : '';
        team2FlagURL = activeFightData['team2_region'] ? iconsBaseURL + activeFightData['team2_region'].toLowerCase() + '.png' : '';
      }

      items.push(
        <div className={itemClassName} key={i} onClick={() => changeFightCallback(activeFightData)}>
          {i === 0 ? <div className='column-title'>{getColumnTitle(itemsCount, isFinal)}</div> : null}
          <div className="player top-player">
            <div className='player-name'>
              {team1FlagURL ? <img className='team-flag' src={team1FlagURL} alt=''/> : null}
              {activeFightData ? getFormattedName(activeFightData['team1_name']) : ''}
            </div>
            <div className={player1ScoreClassName}>{activeFightData && activeFightData['homescore'] !== -1 ? activeFightData['homescore'] : ''}</div>
          </div>
          <div className="player">
            <div className='player-name'>
              {team2FlagURL ? <img className='team-flag' src={team2FlagURL} alt=''/> : null}
              {activeFightData ? getFormattedName(activeFightData['team2_name']) : ''}
            </div>
            <div className={player2ScoreClassName}>{activeFightData && activeFightData['awayscore'] !== -1 ? activeFightData['awayscore'] : ''}</div>
          </div>
          {getHorizontalLine(itemsCount, i === 0, rightItem)}
          {getVerticalLine(itemsCount, i === 0, rightItem)}
        </div>
      );

      fightIndex++;
    }

    return <div className="fights-column" key={index}>
      {items}
    </div>
  }

  const getFightsContainer = () => {
    const activeKGFights = [];
    let isRightItem;

    Object.values(fightsData).forEach(item => {
      if (item['weight'] === weightList[activeKGIndex]) {
        activeKGFights.push(item);
      }
    });

    return <div className='fights-container'>
      {
        graphicData.map((itemsCount, index) => {
          isRightItem = index > 4 || (itemsCount === 1 && index > 3);

          return getFightsColumnItems(itemsCount, index, isRightItem, index === 3, activeKGFights);
        })
      }
    </div>
  }

  return <div className="fights-block">
    <div className='weight-list'>
      <div className='weight-buttons'>
        {
          weightList.map((weight, index) => {
            buttonClassName = activeKGIndex === index ? "weight-button active" : "weight-button";

            return <div className={buttonClassName} key={index} onClick={() => setActiveKGIndex(index)}>
              {weight + ' ' + t('kg')}
            </div>
          })
        }
      </div>
    </div>
    {getFightsContainer()}
  </div>
}

export default memo(FightsBlock);