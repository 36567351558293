import React, {memo, useContext, useEffect, useState} from 'react';
import './MarketTabMenu.scss';
import {useTranslation} from "react-i18next"
import {AppContext} from "../../../../App";

function MarketTabMenu({getElementsGroup, groupNames, activeGroupName, changeGroupCallback, bookmarksCount}) {
  const {t} = useTranslation();
  const activeGroupIndex = groupNames.indexOf(activeGroupName);
  const [activeTabIndex, setActiveTabIndex] = useState(activeGroupIndex);
  const context = useContext(AppContext);
  const isLoggedIn = context.userLoggedIn.get;
  
  let tabClasses;

  useEffect(() => {
    setActiveTabIndex(1)
  }, [context.activeGameID.get])

  const changeActiveTab = (index) => {
    setActiveTabIndex(index);
    changeGroupCallback(groupNames[index]);
  }

  return <div className='market-tab-menu'>
    <div className='market-tabs'>
      {
        groupNames.map((name, index) => {
          tabClasses = index === activeTabIndex ? 'market-tab active-tab' : 'market-tab';
          if (name === 'Bookmarks' && isLoggedIn ) {
            tabClasses += ' market-tab-menu-bookmark';

            return <div className={tabClasses} key={index} onClick={() => changeActiveTab(index)}>
              &#9733; { bookmarksCount ? <p className='bookmarks-count'>{bookmarksCount}</p> : null }
            </div>
          } else if( name === 'Bookmarks' && !isLoggedIn ) {
            return null;
          }

          return <div className={tabClasses} key={index} onClick={() => changeActiveTab(index)}>
            {t(name.toLowerCase())} <p> ({getElementsGroup(name).length})</p>
          </div>
        })
      }
    </div>
  </div>
}

export default memo(MarketTabMenu);