import React, {memo, useContext, useEffect, useMemo, useState} from 'react';
import './LargeMarket.scss';
import UpcomingGames from "../UpcomingGames/UpcomingGames";
import MarketTabMenu from "./MarketTabMenu/MarketTabMenu";
import Utils from "../../../utils/Utils";
import LargeMarketElement from "./LargeMarketElement/LargeMarketElement";
import { BET_CHECK_DATA, LOGIN_DATA, MARKET_DATA } from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";

function LargeMarket({setBetsData, isFootballLeague, isWrestling, isTennis, marketExternalData, marketDisabled}) {
  const [marketData, setMarketData] = useState(null);
  const [selectedPriceData, setSelectedPriceData] = useState({});
  const [activeGroupName, setActiveGroupName] = useState('All');
  const [stadiumName, setStadiumName] = useState('');
  const [eventTime, setEventTime] = useState();
  const [activeGameID, setActiveGameID] = useState(0);
  const webSocket = RequestManager.getInstance().webSocket;
  const context = useContext(AppContext);
  const bookmarks = context.bookmarks.get[LOGIN_DATA.params.user_id] || {};
  const activeSportID = context.activeGameID.get;
  const {t} = useTranslation();

  useEffect(() => {
    changeGroup('All')
  }, [context.activeGameID.get])

  useEffect(() => {
    if (!context.betsData.get.length) {
      setSelectedPriceData({});
    }
  }, [context.betsData.get])

  useEffect(() => {
    // CHECK TENNIS MARKET ACTUAL BOOKMARKS.
    const isTennis = context.activeGameID.get === 14;

    if (isTennis && bookmarks[activeSportID]) {
      const marketDataString = JSON.stringify(marketData);

      bookmarks[activeSportID].map(marketType => {
        if (!marketDataString.includes(marketType)) {
          addRemoveBookmarks(marketType)
        }
      })
    }
  }, [marketData])

  const parseMarketData = (data, marketSubID) => {
    if (Object.keys(data['sport']).length === 0) {
      setMarketData(null);
      return;
    }

    const eventTime = data['event_time'];
    data = data.sport[Utils.firstKey(data.sport)].region;
    data = data[Utils.firstKey(data)].competition;
    const stadiumName = data[Utils.firstKey(data)].name;

    data = data[Utils.firstKey(data)].game;
    data = data[Utils.firstKey(data)];
    const gameID = data['id'];

    // Add market elements to array
    data = Object.keys(data['market']).map(key => {
      return data['market'][key];
    });

    // Sort data by order property.
    data = data.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    // Make sorted market elements array based on "Market Type".
    const createdMarkets = [];
    const sortedData = [];

    data.map(item => {
      const marketType = item['market_type'];
      const typeIndex = createdMarkets.indexOf(marketType);

      if (typeIndex === -1) {
        if (Object.keys(item['event']).length) {
          createdMarkets.push(marketType);
          sortedData.push(item);
        }
      } else if (typeIndex >= 0) {
        const foundIndex = sortedData.findIndex(element => element['market_type'] === marketType);

        Object.keys(item.event).map(key => sortedData[foundIndex].event[key] = item.event[key]);
      }
    });

    if (JSON.stringify(sortedData) !== JSON.stringify(marketData)) {
      setEventTime(eventTime);
      setStadiumName(stadiumName);
      setActiveGameID(gameID);

      if (!isFootballLeague) {
        MARKET_DATA.subID = marketSubID;
      }

      setMarketData(sortedData);
    }
  }

  const updateMarketData = response => {
    if (Object.keys(response).length && marketData && JSON.stringify(response).includes(marketData.id)) {
      setMarketData(null);
    }
  }

  const changeGroup = groupName => {
    setActiveGroupName(groupName);
  }

  const getGroupNames = () => {
    const groupNames = context.userLoggedIn.get ? ['Bookmarks', 'All'] : ['All'];
    let groupName;

    marketData.map(item => {
      groupName = item['group_name'];
      if (groupName && groupNames.indexOf(groupName) === -1) {
        groupNames.push(groupName);
      }
    });

    return groupNames;
  }

  const getElementsGroup = name => {
    if (name === 'Bookmarks') {
      const bookmarkedMarkets = [];
      let bookmarkedMarket;

      if (bookmarks[activeSportID]) {
        bookmarks[activeSportID].map(marketName => {
          bookmarkedMarket = marketData.filter(item => item['market_type'] === marketName)[0];
          bookmarkedMarkets.push(bookmarkedMarket);
        })
      }

      return bookmarkedMarkets;
    }

    return name === 'All' ? marketData : marketData.filter(item => item['group_name'] === name);
  }

  const addRemoveBookmarks = marketType => {
    const currentUserBookmarks = { ...context.bookmarks.get };
    const currentBookmarks = { ...currentUserBookmarks[LOGIN_DATA.params.user_id] };
    const sportBookmarks = currentBookmarks[activeSportID] || [];
    const marketIndex = sportBookmarks.indexOf(marketType);

    if (marketIndex === -1) {
      sportBookmarks.push(marketType);
    } else {
      sportBookmarks.splice(marketIndex, 1);
    }

    currentBookmarks[activeSportID] = sportBookmarks;
    currentUserBookmarks[LOGIN_DATA.params.user_id] = currentBookmarks
    context.bookmarks.set(currentUserBookmarks);
    Utils.writeStorage('bookmarkedMarkets', currentUserBookmarks, 5400);
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "login"}, '*');
  }

  const selectPrice = (selectedItemData, betType, isActiveItem) => {
    if (context.userLoggedIn.get === false) {
      openSignInPopUp();
      return;
    }

    if (isActiveItem) {
      const betsData = context.betsData.get;
      const filteredItems = betsData.filter(item => item.id !== selectedItemData.id);

      setSelectedPriceData({});
      setBetsData(filteredItems);
      return;
    }

    const activeGameName = context.sportsData.get.find(data => data.id === context.activeGameID.get)['name'];
    selectedItemData['stadium_name'] = stadiumName;
    selectedItemData['event_time'] = eventTime;
    selectedItemData['bet_type'] = betType;
    selectedItemData['game_id'] = activeGameID;
    selectedItemData['game_name'] = activeGameName;

    // Request to check bets before adding to current bets list.
    const eventIDs = [];
    const gameIDs = [];
    const marketIDs = [];

    context.betsData.get.map(item => {
      eventIDs.push(parseInt(item['id']));
      gameIDs.push(parseInt(item['game_id']));
      marketIDs.push(parseInt(item['market_id']));
    });

    eventIDs.push(selectedItemData['id']);
    gameIDs.push(selectedItemData['game_id']);
    marketIDs.push(parseInt(selectedItemData['market_id']));

    BET_CHECK_DATA.params.where.game.id['@in'] = gameIDs;
    BET_CHECK_DATA.params.where.event.id['@in'] = eventIDs;
    BET_CHECK_DATA.params.where.market.id['@in'] = marketIDs;

    webSocket.send(JSON.stringify(BET_CHECK_DATA));
    webSocket.addEventListener(RequestManager.BET_CHECKED_EVENT, response => parseBetData(response.detail, selectedItemData), {once: true});
  }

  const parseBetData = (response, selectedData) => {
    let currentBets = [...context.betsData.get];

    currentBets = currentBets.filter(item => {
      if (item['game_id'] !== selectedData['game_id']) {
        return item;
      }
    });

    const successBet = Object.keys(response.data.data.game).length;
    selectedData['status'] = successBet ? 'success' : 'failed';
    selectedData.subID = response.data['subid'];
    currentBets.push(selectedData);

    setBetsData(currentBets);
    setSelectedPriceData(selectedData);
  }

  const getMarketData = () => {
    if (dataIsEmpty()) {
      return matchIsAlreadyRunningInfo();
    }

    const groupedElements = getElementsGroup(activeGroupName);

    return groupedElements.map((item) => {
      if (item) {
        return <LargeMarketElement elementData={item} selectedID={selectedPriceData.id} key={item.id}
                                   selectCallback={selectPrice} addRemoveBookmarksCallback={addRemoveBookmarks} marketDisabled={marketDisabled} />
      }
    });
  }

  const dataIsEmpty = () => {
    return !marketData || marketData.length === 0;
  }

  const getMarketTabs = () => {
    const bookmarksCount = bookmarks[activeSportID] ? bookmarks[activeSportID].length : 0;

    return dataIsEmpty() ? null : <MarketTabMenu getElementsGroup={getElementsGroup} activeGroupName={activeGroupName}
                                                 bookmarksCount={bookmarksCount}
                                                 groupNames={getGroupNames()} changeGroupCallback={changeGroup}/>;
  }

  const getTopBlock = useMemo(() => {
    if (isWrestling || isTennis || isFootballLeague) {
      return getMarketTabs();
    }

    return <div>
      <UpcomingGames marketDataParser={parseMarketData} updateMarketData={updateMarketData}/>
      {getMarketTabs()}
    </div>
  }, [marketData, context.bookmarks.get])

  const matchIsAlreadyRunningInfo = () => {
    return isTennis ? null : <p className='match-running-info'>{t('match running')}</p>
  }

  if (marketExternalData) {
    parseMarketData(marketExternalData);
  }

  const getMarketContent = () => {
    return <div className={'large-market'}>
      {getTopBlock}
      <div className='large-market-container'>
        {getMarketData()}
      </div>
    </div>
  }

  return getMarketContent();
}

export default memo(LargeMarket);