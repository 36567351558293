import React, {memo, useContext, useEffect, useMemo, useState} from 'react';
import './BasicMarket.scss';
import UpcomingGames from "../UpcomingGames/UpcomingGames";
import Utils from "../../../utils/Utils";
import {AppContext} from "../../../App";
import {BET_CHECK_DATA, MARKET_DATA} from "../../../services/RequestDatas"
import RequestManager from "../../../services/RequestManager"
import {useTranslation} from "react-i18next"

function BasicMarket({setBetsData}) {
  const [marketData, setMarketData] = useState(null);
  const [stadiumName, setStadiumName] = useState('');
  const [alias, setAlias] = useState('');
  const [eventTime, setEventTime] = useState();
  const [selectedPriceData, setSelectedPriceData] = useState({});
  const iconBaseURL = process.env.PUBLIC_URL + './assets/market/';
  const webSocket = RequestManager.getInstance().webSocket;
  const context = useContext(AppContext);
  const {t} = useTranslation();

  useEffect(() => {
    if (context.betsData.get.length === 0) {
      setSelectedPriceData({});
    }
  }, [context.betsData.get])

  const parseMarketData = (data, marketSubID) => {
    if (Object.keys(data['sport']).length === 0) {
      setMarketData(null);
      return;
    }

    const eventTime = data['event_time'];
    const aliasValue = data.sport[Utils.firstKey(data.sport)].alias;
    data = data.sport[Utils.firstKey(data.sport)].region;
    data = data[Utils.firstKey(data)].competition;
    const stadiumName = data[Utils.firstKey(data)].name;

    data = data[Utils.firstKey(data)].game;
    data = data[Utils.firstKey(data)];

    setStadiumName(stadiumName);
    setEventTime(eventTime);
    setAlias(aliasValue);

    MARKET_DATA.subID = marketSubID;

    setMarketData(data);
  }

  const updateMarketData = response => {
    if (Object.keys(response).length && marketData && JSON.stringify(response).includes(marketData.id)) {
      setMarketData(null);
    }
  }

  const dataIsEmpty = () => {
    return !marketData || Object.keys(marketData).length === 0 || Object.keys(marketData.market).length === 0;
  }

  const getMarblePlayerIcon = number => {
    const colors = ['#005efc', '#009600', '#fcb000', '#f10f4c']
    return <div style={{backgroundColor: colors[number - 1]}} className='marble-icon'/>
  }

  const getPlayersList = useMemo(() => {
    if (!marketData) {
      return;
    }

    let playerIconURL;
    let currentNumber;
    let playerName;

    return marketData['info']['virtual'].map(({PlayerName, Number, AnimalName}, index) => {
      if (Number) {
        currentNumber = Number.toString();
        playerIconURL = iconBaseURL + alias + '/' + currentNumber + '.svg';
        playerName = PlayerName ? ' / ' + PlayerName : '';

        return <div className='basic-market-list-item list-info-item player-item' key={index}>
          {context.activeGameID.get === 12 ? getMarblePlayerIcon(currentNumber) : <embed src={playerIconURL}/>}
          <p>{AnimalName + playerName}</p>
        </div>
      }
    })
  }, [marketData])

  const matchIsAlreadyRunningInfo = () => {
    return <p className='match-running-info'>{t('match running')}</p>
  }

  const getFormattedPrice = (price) => {
    return Utils.getFormattedCoef(price, context.oddType.get);
  }

  const getMarketData = useMemo(() => {
    if (!marketData) {
      return;
    }

    let currentItem;
    let marketListData;

    return Object.keys(marketData['market']).map( (key, index) => {
      currentItem = marketData['market'][key];
      marketListData = currentItem.event;
      const marketID = key;

      return <div className='basic-market-list' key={index}>
        <div className='basic-market-list-item list-title'>{currentItem.name}</div>
        {
          Object.keys(marketListData).map(function (key, index) {
            const currentListItem = marketListData[key];
            currentListItem['market_id'] = marketID;
            
            const price = getFormattedPrice(currentListItem['price']);
            const activebets = context.betsData.get;
            const isActiveItem = activebets.find(el => el.id === currentListItem.id);
            const betType = currentItem.name;
            let className = 'basic-market-list-item price';
            className += isActiveItem  ? ' active' : '';

            return <div className={className} key={index}
                        onClick={() => selectPrice(currentListItem, betType, isActiveItem)}>
              {price}
            </div>;
          })
        }
      </div>
    });
  }, [marketData, selectedPriceData, context.oddType.get, context.userLoggedIn.get])

  const openSignInPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "login"}, '*');
  }

  const selectPrice = (selectedItemData, betType, isActiveItem) => {
    if (context.userLoggedIn.get === false) {
      openSignInPopUp();
      return;
    }

    if (isActiveItem) {
      const betsData = context.betsData.get;
      const filteredItems = betsData.filter(item => item.id !== selectedItemData.id);

      setSelectedPriceData({});
      setBetsData(filteredItems);
      return;
    }

    const activeGameName = context.sportsData.get.find(data => data.id === context.activeGameID.get)['name'];
    selectedItemData['stadium_name'] = stadiumName;
    selectedItemData['event_time'] = eventTime;
    selectedItemData['bet_type'] = betType;
    selectedItemData['game_id'] = parseInt(marketData.id);
    selectedItemData['game_name'] = activeGameName;

    // Request to check bets before adding to current bets list.
    let eventIDs = [];
    let gameIDs = [];
    let marketIDs = [];

    context.betsData.get.forEach(item => {
      eventIDs.push(parseInt(item['id']));
      gameIDs.push(parseInt(item['game_id']));
      marketIDs.push(parseInt(item['market_id']));
    });

    eventIDs.push(selectedItemData['id']);
    gameIDs.push(selectedItemData['game_id']);
    marketIDs.push(parseInt(selectedItemData['market_id']));

    BET_CHECK_DATA.params.where.game.id['@in'] = gameIDs;
    BET_CHECK_DATA.params.where.event.id['@in'] = eventIDs;
    BET_CHECK_DATA.params.where.market.id['@in'] = marketIDs;

    webSocket.send(JSON.stringify(BET_CHECK_DATA));
    webSocket.addEventListener(RequestManager.BET_CHECKED_EVENT,
      response => parseBetData(response.detail, selectedItemData), {once: true});
  }

  const parseBetData = (response, selectedData) => {
    let currentBets = [...context.betsData.get];

    currentBets = currentBets.filter(item => {
    if (item['game_id'] !== selectedData['game_id']){
        return item;
      }
    });

    const successBet = Object.keys(response.data.data.game).length;
    selectedData['status'] = successBet ? 'success' : 'failed';
    selectedData.subID = response.data['subid'];
    currentBets.push(selectedData);

    setBetsData(currentBets);
    setSelectedPriceData(selectedData);
  }

  const getContent = () => {
    return dataIsEmpty()
      ? matchIsAlreadyRunningInfo()
      : <div className='basic-market-container'>
        <div className='basic-market-list'>
          <div className='basic-market-list-item list-title player-name'>{t('player names')}</div>
          {getPlayersList}
        </div>
        {getMarketData}
      </div>
  }

  return <div className='basic-market'>
    <UpcomingGames marketDataParser={parseMarketData} updateMarketData={updateMarketData}/>
    {getContent()}
  </div>
}

export default BasicMarket;