import React, { memo, useContext, useEffect, useState } from 'react';
import './BetItem.scss';
import { AppContext } from '../../../App';
import Utils from '../../../utils/Utils';
import RequestManager from '../../../services/RequestManager';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { MAX_BET_DATA, RE_CAPTCHA_MAX_DATA } from '../../../services/RequestDatas';

function BetItem({ itemData, betType, isTaxCase, removeItem, updateBetsData }) {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const price = itemData['price'].toString().substring(0, 4);
  const minBetValue = context.partnerConfig.get['min_bet_stakes'][context.currency.get];

  const [ betValue, setBetValue ] = useState(minBetValue * 2);

  if (!itemData.betValue) {
    itemData.betValue = betValue;
  }

  useEffect(() => {
    if (itemData) {
      socket.addEventListener(RequestManager.AUTO_MARKET_DATA, checkBetItemStatus);
    }

    return () => socket.removeEventListener(RequestManager.AUTO_MARKET_DATA, checkBetItemStatus);
  }, [context.betsData.get]);

  useEffect(() => {
    if (itemData.betValue) {
      setBetValue(itemData.betValue);
    }
  }, [context.betsData.get])

  useEffect(() => {
    if (context.currency.get) {
      setBetValue( context.partnerConfig.get['min_bet_stakes'][context.currency.get] * 2);
    }
  }, [context.currency.get])

  const checkBetItemStatus = ({ detail }) => {
    const itemMarketId = itemData['market_id'];
    const responseString = JSON.stringify(detail);

    // UPDATE IN BETS DATA
    if (responseString.includes(itemMarketId)) {
      const newBetsData = [];
      context.betsData.get.forEach(item => {
        if (responseString.includes('"' + item['market_id'] + '":null')) {
          item['status'] = 'failed';
        }

        newBetsData.push(item);
      });

      updateBetsData(newBetsData);
    }
  }

  const getFailedIcon = () => {
    const toolTipText = t('event not available');

    return itemData['status'] === 'failed'
      ? <div className="bet-failed-container">
        <div className="bet-failed-icon" data-tip={ toolTipText }/>
        <ReactTooltip/>
      </div>
      : null;
  }

  const prepareToGetMaxBetValue = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=' + context.reCaptchaData.get.siteKey;
      script.addEventListener('load', handleMaxBetReCaptchaScriptLoaded);

      document.body.appendChild(script);
    } else {
      getMaxBetValue();
    }
  }

  const handleMaxBetReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, { action: 'get_max_bet' }).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_MAX_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_MAX_EVENT, response => handleMaxReCaptchaRequestResponse(response.detail), { once: true });
  }

  const handleMaxReCaptchaRequestResponse = response => {
    // TODO needs to check response status.
    getMaxBetValue();
  }

  const getMaxBetValue = () => {
    MAX_BET_DATA.params.type = 1;
    MAX_BET_DATA.params.events = [ itemData['id'] ];

    socket.send(JSON.stringify(MAX_BET_DATA));
    socket.addEventListener(RequestManager.MAX_BET_EVENT, response => updateMaxBetValue(response.detail), { once: true });
  }

  const updateMaxBetValue = ({ data }) => {
    if (data && data.details?.amount) {
      changeSingleBetValue(data.details.amount)
    }
  }

  const changeSingleBetValue = value => {
    const pointIndex = String(value).indexOf('.');
    value = pointIndex !== -1 && String(value).length > pointIndex + 2 ? Number(Number(value).toFixed(2)) : value;

    const newBetsData = [];
    context.betsData.get.forEach(item => {
      if (item['id'] === itemData['id']) {
        item['betValue'] = value;
        itemData.betValue = value;
      }

      newBetsData.push(item);
    });

    updateBetsData(newBetsData);
    setBetValue(value);
  }

  const getStakeTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (betValue >= item['MinAmount'] && betValue <= item['MaxAmount'] && item['TaxType'] === 4)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getWinTaxPercentage = () => {
    const potentialWin = isTaxCase ? getTaxedPossibleWin() : (price * betValue).toFixed(2);
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];

    const selectedWinTaxRange = taxAmountRanges.filter(item => {
      return (potentialWin >= item['MinAmount'] && potentialWin <= item['MaxAmount'] && item['TaxType'] === 2)
    })[0];

    return selectedWinTaxRange ? selectedWinTaxRange['TaxPrecentage'] : 0;
  }

  const getProfitTaxPercentage = taxedPossibleWin => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const taxStakePercentage = getStakeTaxPercentage();
    const calcTaxedPossibleWin = taxedPossibleWin - (betValue - betValue * taxStakePercentage / 100).toFixed(2);

    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (calcTaxedPossibleWin >= item['MinAmount'] && calcTaxedPossibleWin <= item['MaxAmount'] && item['TaxType'] === 1)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxStakeInfoSingle = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxStakePercentage = getStakeTaxPercentage();
    const totalAmount = (betValue - betValue * taxStakePercentage / 100).toFixed(2);
    const taxedPossibleWin = getTaxedPossibleWin();
    const profitTaxPercentage = getProfitTaxPercentage(taxedPossibleWin);

    let taxedTotalProfit;
    if (profitTaxPercentage) {
      taxedTotalProfit = ((taxedPossibleWin - totalAmount) * profitTaxPercentage / 100).toFixed(2);
    }

    return taxStakePercentage ? <div className="tax-info">
      <div className="win-info">
        <p>{ t('total bet amount') }</p>
        <p>{ (betValue - betValue * taxStakePercentage / 100).toFixed(2) }</p>
      </div>
      <div className="win-info">
        <p>{ t('tax amount') + '(' + taxStakePercentage + '%)' }</p>
        <p>{ betValue * taxStakePercentage / 100 }</p>
      </div>
      {
        taxedTotalProfit ? <div className="win-info">
          <p>Tax to be paid to RRA:</p>
          <p>{ taxedTotalProfit }</p>
        </div> : null
      }
      {
        taxedTotalProfit ? <div className="win-info">
          <p>{ t('final payout') }</p>
          <p>{ (Number(taxedPossibleWin) - Number(taxedTotalProfit)).toFixed(2) }</p>
        </div> : null
      }
    </div> : null
  }

  const getTaxWinInfoSingle = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxWinPercentage = getWinTaxPercentage();
    const possibleWin = getTaxedPossibleWin();

    return taxWinPercentage ? <div className="tax-info">
      <div className="win-info">
        <p>{ t('tax amount') + '(' + taxWinPercentage + '%)' }</p>
        <p>{ (possibleWin * taxWinPercentage / 100).toFixed(2) }</p>
      </div>
      <div className="win-info">
        <p>{ t('final payout') }</p>
        <p>{ (possibleWin - (possibleWin * taxWinPercentage / 100)).toFixed(2) }</p>
      </div>
    </div> : null
  }

  const getTaxedPossibleWin = () => {
    const taxStakePercentage = getStakeTaxPercentage();
    return (price * (betValue - (betValue * taxStakePercentage / 100))).toFixed(2);
  }

  const getMinBetValue = () => {
    const value = Number(minBetValue);
    changeSingleBetValue(value);
  }

  const minusMin = () => {
    if (betValue > minBetValue) {
      const value = Number(betValue) - Number(minBetValue);
      changeSingleBetValue(value);
    }
  }

  const plusMin = () => {
    const value = Number(betValue) + minBetValue;
    changeSingleBetValue(value);
  }

  const doubleUp = () => {
    if (betValue) {
      const value = betValue * 2;
      changeSingleBetValue(value);
    }
  }

  const divideValue = () => {

    if (betValue && betValue && (betValue / 2 >= minBetValue)) {
      const value = betValue / 2;
      changeSingleBetValue(value);
    }
  }

  const getSingleBetItem = () => {
    const potentialWin = isTaxCase ? getTaxedPossibleWin() : (price * betValue).toFixed(2);

    return <div className="bet-item">
      <div className="bet-item-header">
        { getFailedIcon() }
        <p className="bet-game-name">{ itemData['game_name'] }</p>
        <p className="bet-time">{ itemData['event_time'] }</p>
        <div className="remove-item" onClick={ () => removeItem(itemData) }>&#10006;</div>
      </div>
      <div className="bet-type">{ t('single') }</div>
      <div className="bet-type">{ itemData['game_name'] + ' / ' + itemData['bet_type'] }</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{ itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '') }</p>
        <p className="bet-coef">{ Utils.getFormattedCoef(price, context.oddType.get) }</p>
      </div>
      <div className="bet-input-container">

        <div className="stake-block">
          <div className="stake-button max-button" onClick={ getMinBetValue }>Min</div>
          <div className="stake-button" onClick={ divideValue }>1/2</div>
          <div className="stake-button minus" onClick={ minusMin }>-</div>
          <input className="bet-input" type="text" pattern="^[0-9]" id="stake" placeholder={ t('stake') } maxLength={12}
                 value={ itemData.betValue } min="0" autoFocus onKeyDown={ Utils.blockInvalidNumbers }
                 onPaste={ e => Utils.blockInvalidPastedNumbers(e) }
                 onChange={ e => {
                   Utils.correctBetInputValue(e);
                   changeSingleBetValue(e.currentTarget.value)
                 } }
          />
          <div className="stake-button plus" onClick={ plusMin }>+</div>
          <div className="stake-button" onClick={ doubleUp }>x2</div>
          <div className="stake-button min-button" onClick={ prepareToGetMaxBetValue }>Max</div>
        </div>

      </div>
      { getTaxStakeInfoSingle() }
      <div className="possible-win">
        <p>{ t('possible win') }</p>
        <p>{ potentialWin + ' ' + context.currency.get }</p>
      </div>
      { getTaxWinInfoSingle() }
    </div>
  }

  const getMultipleBetItem = () => {
    return <div className="bet-item">
      <div className="bet-item-header">
        { getFailedIcon() }
        <p className="bet-game-name">{ itemData['game_name'] }</p>
        <p className="bet-time">{ itemData['event_time'] }</p>
        <div className="remove-item" onClick={ () => removeItem(itemData) }>&#10006;</div>
      </div>
      <div className="bet-type">{ t('multiple') }</div>
      <div className="bet-type">{ itemData['game_name'] + ' / ' + itemData['bet_type'] }</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{ itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '') }</p>
        <p className="bet-coef">{ Utils.getFormattedCoef(price, context.oddType.get) }</p>
      </div>
    </div>
  }

  const getSystemBetItem = () => {
    return <div className="bet-item">
      <div className="bet-item-header">
        { getFailedIcon() }
        <p className="bet-game-name">{ itemData['game_name'] }</p>
        <p className="bet-time">{ itemData['event_time'] }</p>
        <div className="remove-item" onClick={ () => removeItem(itemData) }>&#10006;</div>
      </div>
      <div className="bet-type">{ t('system') }</div>
      <div className="bet-type">{ itemData['game_name'] + ' / ' + itemData['bet_type'] }</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{ itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '') }</p>
        <p className="bet-coef">{ Utils.getFormattedCoef(price, context.oddType.get) }</p>
      </div>
    </div>
  }

  const getBetItem = () => {
    if (betType === 'Single') {
      return getSingleBetItem()
    } else if (betType === 'Multiple') {
      return getMultipleBetItem()
    } else if (betType === 'System') {
      return getSystemBetItem()
    }
  }

  return getBetItem()
}

export default memo(BetItem);
