import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
  useEffect,
  memo,
} from "react";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";
import "./PlayersSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";

const baseURL = process.env.PUBLIC_URL + "./assets/instants/tennisPlayers/";

let latestSlideName = "";
function PlayersSlider(props, ref) {
  const { secondSliderRef, isEcual, setIsEcual, slideName } = props;
  const tennisContext = useContext(TennisContext);

  useEffect(() => {
    latestSlideName = slideName;
  }, [props]);

  let currentSliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState();

  const settings = {
    dots: false,
    speed: 250,
    slidesToScroll: 4,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 5,
    infinite: true,
    afterChange: (index) => {
      if (secondSliderRef.current.index === index) {
        setIsEcual(true);
      } else {
        setIsEcual(false);
      }
      handleChangeSlider(index, true);
    },
  };

  const handleChangeSlider = (index, withoutGo) => {
    !withoutGo && currentSliderRef.current.slickGoTo(index);
    setSlideIndex(index);
    ref.current.index = index;
    ref.current.name = slideName;
  };

  useImperativeHandle(
    ref,
    () => ({
      selectRandomPlayer() {
        if (currentSliderRef.current) {
          let randomIndex = Math.floor(Math.random() * 10);
          if (secondSliderRef.current.index === randomIndex) {
            if (randomIndex != 0) {
              randomIndex-=1
            } else {
              randomIndex+=1
            }
          }
          handleChangeSlider(randomIndex);
        }
      },
    }),
    []
  );

  const getPlayerBlock = (item, index) => {
    const currentAvtivePlayer = item.id === slideIndex + 1;
    const firstExample = {
      size: 30,
      value: item.strength_stars,
      edit: false,
      isHalf: true,
    };

    return (
      <React.Fragment key={item.id}>
        <div
          className={`player-block-inst ${
            currentAvtivePlayer ? "active " : ""
          } ${isEcual && latestSlideName !== ref.current.name && "isEcual"}`}
          onClick={() => handleChangeSlider(index)}
        >
          <img
            className="player-icon"
            src={baseURL + item.id + ".png"}
            alt={""}
          />
          <div className="name">{item.name}</div>
        </div>
        {currentAvtivePlayer ? (
          <ReactStars {...firstExample} classNames={"player-star"} />
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div className="players-slider">
      <Slider {...settings} ref={currentSliderRef}>
        {!!tennisContext.teams.get?.length &&
          tennisContext.teams.get.map(getPlayerBlock)}
      </Slider>
    </div>
  );
}

export default memo(forwardRef(PlayersSlider));
