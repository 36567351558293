import { memo } from "react";

const AnimationLoader = () => {
  return (
    <div className="instantLoading">
      <div className="app-loader"></div>
    </div>
  );
};

export default memo(AnimationLoader);
