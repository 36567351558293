import React, {memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import './Viewport.scss';
import HLS from 'hls.js';
import {AppContext} from '../../App';
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import {VIDEO_DATA} from "../../services/RequestDatas";
import RequestManager from "../../services/RequestManager";
import Utils from "../../utils/Utils";

function Viewport({name, activeGameIndex, changeActiveSport}) {
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const sportsData = context.sportsData.get;
  const activeGameID = context.activeGameID.get;
  const nextGameIndex = activeGameIndex === sportsData.length - 1 ? 0 : activeGameIndex + 1;
  const prevGameIndex = activeGameIndex - 1 < 0 ? sportsData.length - 1 : activeGameIndex - 1;
  const imagesBaseURL = process.env.PUBLIC_URL + './assets/slider/';
  const nextGameImageURL = imagesBaseURL + sportsData[nextGameIndex].alias + '.jpg';
  const prevGameImageURL = imagesBaseURL + sportsData[prevGameIndex].alias + '.jpg';
  const videoQuality = Utils.getStorageData('videoQuality') !== null ? Utils.getStorageData('videoQuality') : 0;
  const video = useRef(null);
  const videoIDS = {1: 9, 2: 10, 3: 13, 4: 11, 7: 16, 6: 15, 9: 21, 12: 20, 13: 22, 14: 23};
  let hlsURLs = [];
  let hls;

  const [quality, setQuality] = useState(videoQuality);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [update, updateState] = React.useState(false);
  const forceUpdate = () => updateState(!update);

  useEffect(() => {
    if (activeGameID && context.activeVideoID.get) {
      getVideoData();
    }
  }, [activeGameID, quality, update, context.activeVideoID.get]);

  useLayoutEffect(() => {
    Utils.writeStorage('videoQuality', quality);
  }, [quality])

  const getVideoData = () => {

    const isFootballLeague = activeGameID === 7;
    VIDEO_DATA.params.video_id = isFootballLeague ? context.activeVideoID.get : videoIDS[activeGameID];

    socket.send(JSON.stringify(VIDEO_DATA));
    socket.addEventListener(RequestManager.GAME_DATA_EVENT, response => initGame(response.detail), {once: true});
  }

  const getVideoURLs = stringURLs => {
    return stringURLs.includes(',') ? stringURLs.split(',') : [stringURLs, stringURLs];
  }

  const initGame = ({data}) => {
    let startTime = 0;

    try {
      if (activeGameID === 14) {
        data = JSON.parse(data);
        startTime = data['PlayTime'];
        hlsURLs = getVideoURLs(data['VideoURL']);

        context.tennisData.set(data);
      } else {
        hlsURLs = getVideoURLs(data);
      }
    } catch (e) {
      hlsURLs = [data, data];
    }

    initHLS(startTime);
  }

  const initHLS = startTime => {
    if (HLS.isSupported()) {
      hls = new HLS({startPosition: startTime});
      hls.loadSource(hlsURLs[quality]);
      hls.attachMedia(video.current);

      if (videoLoaded === false) {
        hls.on(HLS.Events.FRAG_LOADED, () => setVideoLoaded(true), {once: true})
      }
    }
  }

  const changeQuality = useCallback(value => {
    setQuality(value);
  }, [])

  const getGamesSlider = useMemo(() => {

    return <div className="games-slider">
      <div className='arrow-to-left slide-arrow'
           onClick={() => changeActiveSport(sportsData[prevGameIndex].id, sportsData[prevGameIndex].alias)}>
        <img className='slide-img' src={prevGameImageURL} alt={''}/>
        <img className="arrow-icon arrow-left" src={imagesBaseURL + 'SliderArrow.png'} alt=''/>
      </div>
      <div className='arrow-to-right slide-arrow'
           onClick={() => changeActiveSport(sportsData[nextGameIndex].id, sportsData[nextGameIndex].alias)}>
        <img className='slide-img' src={nextGameImageURL} alt={''}/>
        <img className="arrow-icon arrow-right" src={imagesBaseURL + 'SliderArrow.png'} alt=''/>
      </div>
    </div>
  }, [activeGameID])
  
  return useMemo(() => {

    return <div className='viewport-container'>
      <VideoPlayer video={video} gameName={name} spotsData={sportsData} loaded={videoLoaded} isHD={quality === 0}
                   changeQualityCallback={changeQuality} forceUpdate={forceUpdate}/>
      {getGamesSlider}
    </div>
  }, [videoLoaded, update, quality])
}

export default memo(Viewport);