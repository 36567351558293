import { useContext } from "react";
import { AppContext } from "../../App";
import { TennisContext } from "../../components/PromotionBlock/PromotionBlock";
import Utils from "../../utils/Utils";

const ApiRequest = () => {
  let requestURL = "https://vg-instant-api-dev.betcoapps.com/api/";
  const tennisContext = useContext(TennisContext);
  const context = useContext(AppContext);
  const { token, partnerid, language } = Utils.getURLParams();

  return (path, method, body) => {
    if (body) {
      body["Token"] = token || localStorage.getItem("auth_token");
      body["Language"] = language || context.language.get;
      body["PartnerId"] = +partnerid || context.siteID.get;
    }

    return fetch(requestURL + path, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
      method,
      body: method === "GET" ? null : JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401 && Object.keys(tennisContext).length) {
          tennisContext.activeInstantGameData.set(null);
        }
        return response.json();
      })
      .then((req) => {
        return req;
      })
      .catch((err) => {
        console.log(err, ">>>");
      });
  };
};

export default ApiRequest;
