import {
  memo,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import { TennisContext } from "../../../../../../../components/PromotionBlock/PromotionBlock";
import IsLoadingHOC from "../../../../../../Hoc/IsLoadingHOC";
import { CASH_OUT_DATA } from "../../../../../../VSAPI/APIData";
import apiRequest from "../../../../../../VSAPI/ApiRequest";
import "./myBets.scss";
import { MY_BETS_DATA } from "../../../../../../VSAPI/APIData";

const MyBets = ({
  setLoading,
  isLoading,
  setTotalStake,
  setTotalPossibleAmount,
}) => {
  const tennisContext = useContext(TennisContext);
  const currency = tennisContext.currency.get;
  const API_REQUEST = apiRequest();
  const baseURL = process.env.PUBLIC_URL + "./assets/instants/";
  const MARKET_ID = tennisContext.marketsData.get.id;
  const [myBets, setMyBets] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    getMyBets();
  }, [tennisContext.isMatchEnded.get]);

  const getMyBets = useCallback(() => {
    MY_BETS_DATA.UserMatchID = MARKET_ID;
    setLoading(true);

    API_REQUEST("myBets", "POST", MY_BETS_DATA)
      .then((res) => {
        setTotalStake(
          res.current_bets?.reduce(function (sum, current) {
            return sum + current.bet_stake;
          }, 0)
        );

        setTotalPossibleAmount(
          res.current_bets?.reduce(function (sum, current) {
            if (current.state === 0) {
              sum += current.possible_win;
            }
            return sum;
          }, 0)
        );

        setMyBets(res.current_bets);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const doCashOut = (betID, doCashOut) => {
    if (!doCashOut) {
      return;
    }

    if (betID.length) {
      const currentBetItem = myBets;
      currentBetItem.forEach((item) =>
        betID.includes(item.bet_id) ? (item.state = 5) : item
      );

      CASH_OUT_DATA.BetIds = betID;
      setLoading(false);

      CASH_OUT_DATA.UserMatchId = tennisContext.marketsData.get.id;
      API_REQUEST("doCashOut", "POST", CASH_OUT_DATA).then((res) => {
        tennisContext.balance.set(res.balance);
        setTotalPossibleAmount(res.cashouts[0].total_possible_winnings);
        setMyBets([...currentBetItem]);
        setLoading(false);
      });
    }
  };

  const getAllBetsCashOutAmount = useMemo(() => {
    return myBets.reduce(function (sum, current) {
      return current.state === 0 && current.is_cashoutable
        ? sum + current.cashout_amount
        : sum;
    }, 0);
  }, [myBets]);

  const getAllCashOutBetIds = useCallback(() => {
    return myBets
      .filter((bet) => bet.state != 5 && bet.is_cashoutable)
      .map((item) => item.bet_id);
  }, [myBets]);

  const drwoBetItem = (item, obj) => {
    return (
      <div className="my-bets-instant-item" key={item.bet_id}>
        <div>
          <p className="state">
            <span>Bet ID: {item.bet_id}</span>
            {obj.status}
          </p>
          <p>
            <span>{item.market_name}</span>
            <span>{item.selection_name}</span>
          </p>
          <p>
            <span> Stake </span>
            <span>{`${item.bet_stake} ${currency}`}</span>
          </p>
          <p>
            <span>{obj.oddText}</span>
            <span className="odd">
              {obj.oddNewPrice || null}
              <del>{obj.oddOldPrice}</del>
            </span>
          </p>
          {obj.amountText ? (
            <p>
              <span>{obj.amountText}</span>
              <span>{obj.amount}</span>
            </p>
          ) : null}
        </div>
        <footer
          className={`my-bets-instant-item-footer ${
            item.is_cashoutable && item.state === 0 ? "active" : ""
          }`}
          onClick={() =>
            doCashOut([item.bet_id], item.is_cashoutable && item.state === 0)
          }
        >
          {item.is_cashoutable && item.state === 0
            ? `Cash-out ${item.cashout_amount} ${currency}`
            : null}
        </footer>
      </div>
    );
  };

  const betItemInfo = (item) => {
    let state = item.state;
    let obj;
    if (state === 5) {
      obj = {
        status: (
          <span>
            <img src={baseURL + "icons/cashOutIcon.png"} />
            Cashed Out
          </span>
        ),
        oddText: "Cashed Out ODD",
        oddNewPrice: item.cashout_new_price,
        oddOldPrice: item.cashout_old_price,
        amountText: "Cashed Out Amount",
        amount: item.cashout_amount,
      };
    } else if (state === 1) {
      obj = {
        status: <span className="lost">✖ Lost</span>,
        oddText: "ODD",
        oddNewPrice: null,
        oddOldPrice: item.cashout_old_price,
        amountText: " ",
        amount: " ",
      };
    } else if (state === 3) {
      obj = {
        status: <span> <span className="won-icon">✔</span> Won</span>,
        oddText: "ODD",
        oddNewPrice: item.cashout_new_price,
        oddOldPrice: item.cashout_old_price,
        amountText: "Winning Amount",
        amount: item.winning_amount + " AMD",
      };
    } else if (state === 0) {
      obj = {
        status: <span>Not resulted</span>,
        oddText: "ODD",
        oddNewPrice: item.cashout_new_price,
        oddOldPrice: item.cashout_old_price,
        amountText: "Possible Winning Amount",
        amount: item.possible_win + " AMD",
      };
    }

    return drwoBetItem(item, obj);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="my-bets-instant">
      <div className="my-bets-instant-content">
        {myBets.length ? (
          myBets.map((item, i) => {
            return betItemInfo(item);
          })
        ) : (
          <p className="not-bet-text">There is no bet</p>
        )}
      </div>
      {myBets.length ? (
        <div
          className={`my-bets-instant-footer ${
            getAllBetsCashOutAmount && "active"
          }`}
          onClick={() => doCashOut(getAllCashOutBetIds(), true)}
        >
          {getAllBetsCashOutAmount
            ? "Cash out all bets: " +
              getAllBetsCashOutAmount.toFixed(2) +
              " " +
              currency
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default memo(IsLoadingHOC(MyBets));
