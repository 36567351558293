import React, {useCallback, useContext, useEffect} from 'react';
import './SportsBlock.scss';
import RequestManager from "../../services/RequestManager";
import SportTabButton from "./SportTabButton";
import Viewport from "../Viewport/Viewport";
import {AppContext} from "../../App";
import MarketBlock from "../MarketBlock/MarketBlock";
import {MARKET_DATA, SPORTS_DATA, UNSUBSCRIBE_DATA} from "../../services/RequestDatas";
import Utils from "../../utils/Utils";
import {useHistory} from "react-router-dom";
import ServerErrorMessage from "../ServerErrorMessage/ServerErrorMessage";

function SportsBlock() {
  const context = useContext(AppContext);
  const history = useHistory();
  const sportsData = context.sportsData.get;
  const activeGameID = context.activeGameID.get;
  const socket = RequestManager.getInstance().webSocket;
  const iconBaseURL = process.env.PUBLIC_URL + './assets/sports/';
  const allSportsData = [];
  const videoIDS = {
    'VirtualFootballLeague': 16,
    'VirtualFootball': 9,
    'PenaltyKicks': 10,
    'VirtualGreyhounds': 11,
    'VirtualBicycle': 15,
    'VirtualHorseRacing': 13,
    'DragRacing': 21,
    'MarbleRacing': 20,
    'WorldWrestling': 22,
    'VirtualTennis': 23
  }

  const [update, updateState] = React.useState(false);
  const forceUpdate = () => updateState(!update);

  useEffect(() => {

    if (context.availableSportsId.get.length) {
      SPORTS_DATA.params.where.sport.id['@in'] = context.availableSportsId.get;
      socket.send(JSON.stringify(SPORTS_DATA));
      socket.addEventListener(RequestManager.SPORTS_DATA_EVENT, response => initSportsData(response.detail), {once: true});

      socket.addEventListener(RequestManager.AUTO_GAME_DATA, response => handleGamesDataUpdate(response.detail));

      return () => {
        unsubscribeFromSportsData();
      }
    }
  }, [context.availableSportsId.get, context.language.get]);

  const unsubscribeFromSportsData = () => {
    UNSUBSCRIBE_DATA.params.subid = SPORTS_DATA.subID;
    socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
  }

  const unsubscribeFromPreviousMarket = () => {
    if (MARKET_DATA.subID && UNSUBSCRIBE_DATA.params.subid !== MARKET_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = MARKET_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    }
  }

  useEffect(() => {
    forceUpdate();
  }, [context.sportsData.get]);

  const initSportsData = response => {
    SPORTS_DATA.subID = response.data['subid'];

    const data = response.data.data.sport;
    if (Object.keys(data).length === 0) {
      return;
    }

    for (let key in data) {
      if (data[key]) {
        const sportData = data[key];
        const regionData = sportData['region'];

        sportData['game'] = regionData ? Object.values(regionData)[0]['game'] : {}
        sportData['region_id'] = regionData ? Object.values(regionData)[0]['id'] : {}

        allSportsData.push(sportData);
      }
    }

    if (activeGameID) {
      const activeGameData = allSportsData.find(a => a.id === activeGameID);

      if (activeGameData) {
        updateSportsData(allSportsData);
        context.activeVideoID.set(videoIDS[activeGameData['alias']]);
      }
    }
  };

  const updateSportsData = updatedSportsData => {
    const orderedSportsData = [...updatedSportsData];
    orderedSportsData.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    context.sportsData.set(orderedSportsData);
  }

  /**
   * Add or Remove the sports data depending on update type.
   * @param response AUTO(live) SPORTS DATA UPDATE
   */
  const handleGamesDataUpdate = response => {
    if (response['sport']) {
      Object.keys(response['sport']).map(key => {
        if (response['sport'][key]) {
          const regionData = response['sport'][key]['region'];
          const gameData = regionData[Utils.firstKey(regionData)];

          updateGameData(parseInt(key), gameData);
        }
      })
    } else {
      updateGameData(activeGameID, response);
    }
  }

  const updateGameData = (gameID, gameData) => {
    const updates = [];
    gameData = Object.keys(gameData).map(key => {
      updates.push(gameData[key]);
    });

    // CHECK AND SET GAME UPDATE.
    const currentSportsData = [...allSportsData];
    updates.forEach(update => {
      Object.keys(update).forEach(matchID => {
        currentSportsData.forEach(sportData => {

          if (parseInt(sportData['id']) === gameID) {
            if (update[matchID] === null) {
              // IF IS UPDATE TO DELETE GAME.
              delete sportData['game'][matchID];
            } else {
              // IF IS UPDATE TO ADD NEW GAME.
              sportData['game'][matchID] = update[Utils.firstKey(update)];
            }
          }
        })
      })
    })

    updateSportsData(currentSportsData);
  }

  const getContent = () => {
    return <div className="content">
      {
        sportsData.map(({name, id}, index) => {
          return activeGameID === id
            ? <Viewport name={name} activeGameIndex={index} changeActiveSport={changeActiveSport} key={index}/>
            : null;
        })
      }
    </div>
  }

  const changeActiveSport = useCallback((id, alias) => {
    /*if (id === 12) {
      const url = 'http://staging-rgs_3rdparties.betconstruct.com/authorization.php?partnerid=4&gameid=10301&language=eng&openType=fun&devicetypeid=1&platformType=0&exitURL=https:%2f%2fstaging-rgs-games.betconstruct.com%2f'
      document.location.href = url;
      return;
    }*/

    history.push('?game=' + id.toString());
    window.parent.postMessage({action: 'setLocation', location: {game: id.toString()}}, '*');

    unsubscribeFromPreviousMarket();
    context.activeVideoID.set(videoIDS[alias]);
    context.activeGameID.set(id);
  }, [])

  const getSportTabs = () => {
    return context.sportsData.get.length
      ? <div className="sports-tabs">
        {
          context.sportsData.get.map(({id, name, alias, game}, index) => {
            return <SportTabButton
              name={name}
              alias={alias}
              isActive={activeGameID === id}
              iconURL={iconBaseURL + alias + '.png'}
              clickHandler={() => changeActiveSport(id, alias)}
              games={game}
              isFirst={index === 0}
              isLast={index === sportsData.length - 1}
              key={id}/>
          })
        }
      </div>
      : null;
  }

  return sportsData.length
    ? <div className="sports-block">
      {getSportTabs()}
      {getContent()}
      <MarketBlock/>
    </div>
    : <ServerErrorMessage/>
}

export default SportsBlock;