import React, {memo} from 'react';
import './Select.scss';

function Select({list, onChangeCallback, defaultValue = 0, hardValue, matchId}) {
  const selectList = list.length ? list : [];

  return <div className='custom-select'>
    {matchId && <div className='disable-input'/>}
    <select defaultValue={defaultValue} value={hardValue} onChange={event => onChangeCallback(event.target.value)}>
      {
        selectList.map((name, index) => {
          return <option style={{fontSize: '15px'}} value={index} key={index}>{name}</option>;
        })
      }
    </select>
  </div>
}

export default memo(Select);