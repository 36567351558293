import React, {memo, useState} from 'react';
import './ProvidersBlock.scss';
import TabButton from "../../layout/TabButton/TabButton";
import BetContainer from "../../containers/BetContainer/BetContainer";
import EdgeContainer from "../../containers/EdgeContainer/EdgeContainer";
import LeapContainer from "../../containers/LeapContainer/LeapContainer";
import InspiredContainer from "../../containers/InspiredContainer/InspiredContainer";
import GoldenRaceContainer from "../../containers/GoldenRaceContainer/GoldenRaceContainer";

function ProvidersBlock() {
  const [active, setActive] = useState(0);
  const providersData = [
    {id: 0, icon: 'betconstruct.svg', content: <BetContainer key={0}/>},
    {id: 1, icon: 'edge.svg', content: <EdgeContainer key={1}/>},
    {id: 2, icon: 'leap.svg', content: <LeapContainer key={2}/>},
    {id: 3, icon: 'inspired.svg', content: <InspiredContainer key={3}/>},
    {id: 4, icon: 'goldenrace.svg', content: <GoldenRaceContainer key={4}/>}
  ];

  const getProviderTabs = () => {
    const baseURL = process.env.PUBLIC_URL + './assets/providers/white/';

    return providersData.map(({id, icon}) => {
      return <TabButton key={id} iconURL={baseURL + icon} isActive={active === id} clickHandler={() => setActive(id)}/>
    })
  }

  return <div className="providers-block">
    <div className='providers-container'>
      <div className="providers-tabs">
        {/*{getProviderTabs()}*/}
      </div>
    </div>
    <div className="content">
      {/*{providersData.map(({id, content}) => active === id ? content : '')}*/}
      <BetContainer key={0}/>
    </div>
  </div>
}

export default memo(ProvidersBlock);