import React, { memo, useEffect, useState } from 'react';
import './Switcher.scss';

function Switcher({id, isEnabled, enableHandler}) {
  const [enabled, setEnabled] = useState(isEnabled);

  useEffect(() => {
    setEnabled(isEnabled);

  }, [isEnabled])

  const enableDisable = () => {
    setEnabled(!enabled);
    enableHandler(id, !enabled);
  }

  return <div className='bet-switcher'>
    <input id={id} type="checkbox" onClick={enableDisable} checked={isEnabled}/>
    <label htmlFor={id}/>
  </div>
}

export default memo(Switcher);