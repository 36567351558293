import React, { memo, useState, useContext } from "react";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";
import PlayersBlock from "../PlayersBlock/PlayersBlock";
import "./TennisInstantMarket.scss";

function TennisInstantMarket({
  setBetItems,
  betItems,
  setSelectedBetsId,
  selectedBetsId,
}) {
  const tennisContext = useContext(TennisContext);
  const marketData = tennisContext.marketsData.get;
  const [activeMarketId, setActiveMarketId] = useState(0);
  const [activeMarketType, setActiveMarketType] = useState(
    marketData.MarketGroupNames[0]
  );
  const iconBaseURL = process.env.PUBLIC_URL + "./assets/instants/icons/";
  const baseURL = process.env.PUBLIC_URL + "./assets/instants/";

  const addBetItem = (selection) => {
    if (!selectedBetsId.includes(selection.id)) {
      setSelectedBetsId([...selectedBetsId, selection.id]);
    } else {
      setSelectedBetsId(selectedBetsId.filter((id) => id != selection.id));
      setBetItems(betItems.filter((item) => item.bet_id != selection.id));
    }

    const isAvalibaleBetItem = betItems.find(
      ({ bet_id }) => bet_id === selection.id
    );

    if (!isAvalibaleBetItem) {
      setBetItems([
        ...betItems,
        {
          odd_type: selection.price,
          market_name: activeMarketType,
          selection_name: selection.name,
          bet_id: selection.id,
        },
      ]);
    }
  };

  const showMarket = () => {
    return (
      <div>
        {marketData.markets.length &&
          marketData.markets.map((type) =>
            type.market_group_name == activeMarketType
              ? type.selections.map((selection, i) => (
                  <div
                    key={i}
                    onClick={
                      !selection.is_blocked ? () => addBetItem(selection) : null
                    }
                    className={`selection ${
                      selectedBetsId.includes(selection.id) ? "choose" : ""
                    }`}
                  >
                    <div>
                      <p className="marketName">
                        {type.market_name}
                        {type.is_cashoutable ? (
                          <img
                            src={baseURL + "/icons/cashOut.png"}
                            className="cashOut"
                          />
                        ) : null}
                      </p>
                      <span className="score">{selection.name}</span>
                    </div>
                    {selection.is_blocked ? (
                      <img src={iconBaseURL + "blockidIcon.png"} />
                    ) : (
                      <span className="coefficient">{selection.price} </span>
                    )}
                  </div>
                ))
              : null
          )}
      </div>
    );
  };

  return (
    <div className="tennis-instant-market">
      <PlayersBlock data={marketData} />
      <div className="market-body">
        <div className="market-types">
          <div>
            {marketData.MarketGroupNames?.map((name, id) => (
              <p
                onClick={() => {
                  setActiveMarketId(id);
                  setActiveMarketType(name);
                }}
                key={id}
                className={activeMarketId === id ? "active" : ""}
              >
                {name}
              </p>
            ))}
          </div>
        </div>

        <div className="market-content">{showMarket()}</div>
      </div>
    </div>
  );
}

export default memo(TennisInstantMarket);
