import { useState } from "react"
import AnimationLoader from "../AnimationLoader/AnimationLoader"

export const IsLoadingHOC = (WrappedComponent) => {
    function HOC(props) {
        const [isLoading, setLoading] = useState(true)

        const setLoadingState = isComponentLoading => {
            setLoading(isComponentLoading);
        }

        return (
            <>
            {isLoading  && <AnimationLoader />}
            <WrappedComponent {...props} setLoading={setLoadingState} isLoading={isLoading}/>
            </>
        )
    }

    return HOC;
}

export default IsLoadingHOC;