import React, { useState, useEffect, useRef, useMemo } from 'react';
import { BrowserRouter } from "react-router-dom";
import ProvidersBlock from "./components/ProvidersBlock/ProvidersBlock";
import PromotionBlock from "./components/PromotionBlock/PromotionBlock";
import RequestManager from "./services/RequestManager";
import "react-datepicker/dist/react-datepicker.css";
import {
  SWARM_LOGIN_DATA,
  PARTNER_CONFIG_DATA,
  AUTHORIZATION_DATA,
  PROFILE_DATE,
  LOGIN_DATA,
  LOGOUT_DATA,
  RE_CAPTCHA_DATA,
  RE_CAPTCHA_MAX_DATA, CHANGE_LANG_DATA
} from "./services/RequestDatas";
import Utils from "./utils/Utils";
import i18next from 'i18next';
import './i18n';
import './App.scss';
import * as ReactGA from 'react-ga';

export const AppContext = React.createContext({});

function App() {
  const storedBetsData = Utils.getStorageData('betsData', true) || []; 
  const storedBookmarks = Utils.getStorageData('bookmarkedMarkets', true) || {};
  const storedOddTypes = Utils.getStorageData('oddType', true)?.length ? Utils.getStorageData('oddType', true) : 0;

  const [URLParams, setURLParams] = useState();
  const [betsData, setBetsData] = useState(storedBetsData.map(item => {
    item.betValue = "";
    return item
  }));
  const [activeBets, setActiveBets] = useState(storedBetsData);
  const [language, setLanguage] = useState('eng');
  const [reCaptchaData, setReCaptchaData] = useState(null);
  const [partnerConfig, setPartnerConfig] = useState(null);
  const [activeGameID, setActiveGameID] = useState(2);
  const [activeVideoID, setActiveVideoID] = useState(0);
  const [sportsData, setSportsData] = useState([]);
  const [bookmarks, setBookmarks] = useState(storedBookmarks);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [applicationReady, setApplicationReady] = useState(false);
  const [calculatorOpened, setCalculatorOpened] = useState(false);
  const [promotionNotShown, setPromotionNotShown] = useState(true);
  const [siteID, setSiteID] = useState(1);
  const [oddType, setOddType] = useState(storedOddTypes);
  const [balance, setBalance] = useState(1000);
  const [baseHost, setBaseHost] = useState('vbet');
  const [platform, setPlatform] = useState(null);
  const [currency, setCurrency] = useState('AMD');
  const [tennisData, setTennisData] = useState({});
  const [tennisUpdateState, setTennisUpdateState] = useState(0);
  const [availableSportsId, setAvailableSportsId] = useState([]);
  const [disableMarket, setDisableMarket] = useState(false);

  const loginData = useRef();
  const socket = useRef();

  const store = {
    siteID: { get: siteID, set: setSiteID },
    baseHost: { get: baseHost, set: setBaseHost },
    platform: { get: platform, set: setPlatform },
    currency: { get: currency, set: setCurrency },
    language: { get: language, set: setLanguage },
    reCaptchaData: { get: reCaptchaData, set: setReCaptchaData },
    promotionNotShown: { get: promotionNotShown, set: setPromotionNotShown },
    partnerConfig: { get: partnerConfig, set: setPartnerConfig },
    activeGameID: { get: activeGameID, set: setActiveGameID },
    activeVideoID: { get: activeVideoID, set: setActiveVideoID },
    bookmarks: { get: bookmarks, set: setBookmarks },
    sportsData: { get: sportsData, set: setSportsData },
    tennisData: { get: tennisData, set: setTennisData },
    calculatorOpened: { get: calculatorOpened, set: setCalculatorOpened },
    betsData: { get: betsData, set: setBetsData },
    activeBets: { get: activeBets, set: setActiveBets }, // TODO needs to check.
    oddType: { get: oddType, set: setOddType },
    balance: { get: balance, set: setBalance },
    userLoggedIn: { get: userLoggedIn, set: setUserLoggedIn },
    tennisUpdateState: { get: tennisUpdateState, set: setTennisUpdateState },
    availableSportsId: { get: availableSportsId, set: setAvailableSportsId },
    disableMarket: { get: disableMarket, set: setDisableMarket }
  };


  useEffect(() => {
    if (document.location.href.includes('token=')) {
      if (URLParams) {
        console.log('>> C1 URLParams:', URLParams);
        // Authorization has passed.
        const userID = URLParams['userid'] || 1;
        const gameID = URLParams['gameId'] || 0;
        sendLoginRequest(userID, URLParams['token']);

        if (gameID) {
          openCasinoChosenGame(gameID);
        }

        changeAppLanguage(Utils.correctLanguageKey(URLParams['language']));
        setSiteID(Number(URLParams['partnerid']));
      } else {
        console.log('>> C1.1:');
        initInitialParameters();
      }
    } else {
      console.log('>> C2 appReady:', URLParams);
      window.addEventListener('message', parsePostMessage);
      window.parent.postMessage({ action: 'appReady' }, '*');
    }

    if (Utils.DEV_MODE && applicationReady === false) {
      initAuthorization(language, siteID);
    }

    if (applicationReady) {
      ReactGA.initialize('GTM-MP95GVZ2');
      ReactGA.pageview('Betconstruct Virtual Sport');
    }

    return () => {
      window.removeEventListener('message', parsePostMessage);
    }
  }, [applicationReady]);

  useEffect(() => {
    if (reCaptchaData && reCaptchaData.siteKey) {
      initReCaptchaScript();
    }
  }, [reCaptchaData]);

  useEffect(() => {
    window.onpopstate = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params['game']) {
        setActiveGameID(parseInt(params['game']));
      } else {
        setPromotionNotShown(true);
      }
    };
  }, []);

  useEffect(() => {
    if (activeGameID) {
      Utils.manageGoogleScript(activeGameID);
    }
  }, [activeGameID])

  const openCasinoChosenGame = gameID => {
    const casinoIDs = {
      'VirtualFootball': 1,
      'PenaltyKicks': 2,
      'KinsleyPark': 4,
      'SantaAnitaPark': 3,
      'Velodrome': 6,
      'WorldLeague': 7,
      'DragRacing': 9,
      'MarbleRace': 12,
      'VirtualTennis': 14
    };

    const chosenGameID = casinoIDs[gameID];
    if (chosenGameID) {
      setActiveGameID(chosenGameID);
      setPromotionNotShown(false);
    }
  }

  const initInitialParameters = () => {
    const iFrameURL = new URL(document.location.href);
    const params = Object.fromEntries([...iFrameURL.searchParams.entries()]);

    initAuthorization(params['language'], params['partnerid']);
    setURLParams(params);
  }

  const initReCaptchaScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + reCaptchaData.siteKey;
    script.addEventListener("load", handleReCaptchaScriptLoaded);

    document.body.appendChild(script);
  }

  const handleReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(reCaptchaData.siteKey, { action: "do_bet" }).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token);
      window.grecaptcha.execute(reCaptchaData.siteKey, { action: "get_max_bet" }).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token);
    });
  }

  const initAuthorization = (lang, siteID) => {
    socket.current = RequestManager.getInstance(siteID).webSocket;

    socket.current.onopen = () => {
      if (lang === 'pt-br') {
        AUTHORIZATION_DATA.params.language = 'por_2';
      } else if (lang === 'es-pe') {
        AUTHORIZATION_DATA.params.language = 'spa'
      } else {
        AUTHORIZATION_DATA.params.language = Utils.correctLanguageKey(lang);
      }

      AUTHORIZATION_DATA.params.site_id = siteID;

      socket.current.send(JSON.stringify(AUTHORIZATION_DATA));
      socket.current.addEventListener(RequestManager.AUTHORIZATION_EVENT, response => handleAuthorization(response), { once: true });
      socket.current.addEventListener(RequestManager.SOCKET_CLOSED_EVENT, handleSocketClosing, { once: true });
    }
  }

  const handleAuthorization = ({ detail }) => {
    getPartnerConfig();
    handleReCaptchData(detail.data);

    if (Utils.DEV_MODE) {
      loginTestUser();
      changeAppLanguage(language);
    }
  }

  const loginTestUser = () => {
    socket.current.send(JSON.stringify(SWARM_LOGIN_DATA));
    socket.current.addEventListener(RequestManager.SWARM_LOGIN_EVENT, sendProfileRequest, { once: true });
    //setUserLoggedIn(true);
  }

  const handleReCaptchData = data => {
    // ReCaptcha v3.0
    if (data['recaptcha_enabled']) {
      const recaptchaData = {
        version: data['recaptcha_version'],
        siteKey: data['site_key'],
        siteID: data['sid']
      }

      setReCaptchaData(recaptchaData);
    }
  }

  const handleSocketClosing = () => {
    setApplicationReady(false);
  }

  const getPartnerConfig = () => {
    socket.current.send(JSON.stringify(PARTNER_CONFIG_DATA));
    socket.current.addEventListener(RequestManager.PARTNER_DATA_EVENT,
      response => parsePartnerConfigData(response.detail.data.data.partner), { once: true });
  }

  const parsePartnerConfigData = data => {
    data = data[Utils.firstKey(data)];
    const availableSportsId = data["available_sports"].map(item => item.id);
    setAvailableSportsId(availableSportsId);

    if (data['theme_colors']) {
      Utils.applyApplicationTheme(data['theme_colors']);
    }

    setPartnerConfig(data);
    setCurrency(data['currency']);
    setTimeout(() => setApplicationReady(true), 100) ;
  }

  const changeAppLanguage = lang => {
    setLanguage(lang);

    if (lang === 'pt-br') {
      lang = 'por_2';
    } else if (lang === 'es-pe') {
      lang = 'spa'
    }

    i18next.changeLanguage(lang, error => error ? console.log('i18n error: ', error) : null).then();
  }

  const sendLoginRequest = (userID, authToken) => {
    if (loginData.current && !authToken) {
      userID = loginData.current['user_id'];
      authToken = loginData.current['auth_token'];
    }

    if (userID && authToken && userLoggedIn === false) {

      localStorage.setItem("auth_token", authToken);

      LOGIN_DATA.params.user_id = userID;
      LOGIN_DATA.params.auth_token = authToken;
      LOGIN_DATA.rid = Utils.getUniqNumber().toString();

      socket.current.send(JSON.stringify(LOGIN_DATA));
      socket.current.addEventListener(RequestManager.LOGIN_DATA_EVENT, sendProfileRequest, { once: true });
    }
  }

  const sendProfileRequest = response => {
    if (response.detail && response.detail.data.auth_token) {

      socket.current.send(JSON.stringify(PROFILE_DATE));
      socket.current.addEventListener(RequestManager.PROFILE_DATA_EVENT, response => parseProfileData(response.detail.data.data.profile), { once: true });
      socket.current.addEventListener(RequestManager.AUTO_BALANCE_DATA, response => updateUserBalance(response.detail.profile));

      setUserLoggedIn(true);
    }
  }

  const updateUserBalance = response => {
    response = response[Utils.firstKey(response)];
    setBalance(response['casino_balance']);
  }

  const parseProfileData = response => {
    response = response[Utils.firstKey(response)];

    const userCurrency = response['currency'] ? response['currency'] : 'AMD';
    const bonus = response['casino_bonus'] ? response['casino_bonus'] : 0;
    const balance = response['casino_balance'] === null ? response['balance'] + bonus : response['casino_balance'] + bonus;

    setBalance(balance);
    setCurrency(userCurrency);
  }

  const sendLogoutRequest = () => {
    LOGOUT_DATA.rid = Utils.getUniqNumber().toString();
    socket.current.send(JSON.stringify(LOGOUT_DATA));
  }

  const manageRouteData = routData => {
    if (routData['game']) {
      const possibleGameIDS = [1, 2, 3, 4, 6, 7, 9, 12, 13, 14];
      const parameterGameID = parseInt(routData['game']);

      if (possibleGameIDS.includes(parameterGameID)) {
        setActiveGameID(parameterGameID);
        setPromotionNotShown(false);
      }
    }
  }

  const sendSwarmLanguageChangeRequest = language => {
    CHANGE_LANG_DATA.params.language = language;

    if (socket && socket.current && socket.current.readyState === 1) {
      socket.current.send(JSON.stringify(CHANGE_LANG_DATA));
      socket.current.addEventListener(RequestManager.CHANGE_LANG_EVENT, () => handleSwarmLanguageChange(language), { once: true });
    }
  }

  const handleSwarmLanguageChange = language => {
    changeAppLanguage(language);
  }

  const parsePostMessage = ({ data }) => {
    const messageType = data['action'];
    const messageData = data['data'];
    // console.log('>>> POST-MESSAGE DATA:', data);

    switch (messageType) {
      case 'initialConfig':
        changeAppLanguage(messageData['lang']);
        initAuthorization(Utils.correctLanguageKey(messageData['lang']), messageData['site_id']);

        if (applicationReady === false) {
          socket.current.addEventListener(RequestManager.AUTHORIZATION_EVENT, sendLoginRequest, { once: true });
        }

        setSiteID(Number(messageData['site_id']));
        setBaseHost(messageData['cmsBaseHost']);
        setPlatform(messageData['platform']);
        break;
      case 'setRouteState':
        manageRouteData(data['data']);
        break;
      case 'restore_login':
        loginData.current = { ...messageData };

        sendLoginRequest(messageData['user_id'], messageData['auth_token']);
        break;
      case 'setConfig':
        if (messageData['lang']) {
          sendSwarmLanguageChangeRequest(Utils.correctLanguageKey(messageData['lang']));
        }

        break;
      case 'logout':
        sendLogoutRequest();
        setUserLoggedIn(false);

        // Clear all bets
        Utils.writeStorage('betsData', []);
        setBetsData([]);
        break;
      default:
        break;
    }
  }

  const getAppContent = useMemo(() => {
    if (promotionNotShown && partnerConfig === null) {
      return null;
    }

    return promotionNotShown
      ? <PromotionBlock partnerConfig={partnerConfig} />
      : <div className="app">
        <ProvidersBlock />
      </div>
  }, [promotionNotShown, partnerConfig])

  return applicationReady
    ? <BrowserRouter>
      <AppContext.Provider value={store}>
        {getAppContent}
      </AppContext.Provider>
    </BrowserRouter>
    : <div className='app-loader' />
}

export default App;