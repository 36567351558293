import React, {useContext, useState, useEffect, memo, useMemo} from 'react';
import './BannerContainer.scss';
import {AppContext} from "../../../App"
import Utils from "../../../utils/Utils";

function BannerContainer() {
  const context = useContext(AppContext);
  const lang = context.language.get;
  const host = context.baseHost.get;
  const siteID = context.siteID.get;
  const platform = context.platform.get;

  const oldDataURL = `https://cmsbetconstruct.com/json?base_host=${siteID}&ssl=1&lang=${lang}&json=widgets/get_sidebar&sidebar_id=under-betslip-banners-virtualsports-${lang}`;
  const newDataURL = `https://cmsbetconstruct.com/api/public/v1/${lang}/partners/${siteID}/components/virtual_sport_betslip_banners/contents?use_webp=1&platform=${platform}`;

  const [bannersData, setBannersData] = useState([]);
  const gamesIDsData = [
    {gameID: 1, id: 173, vid: 9},   // Virtual Football Pro
    {gameID: 2, id: 174, vid: 10},  // The Penalty Kicks
    {gameID: 3, id: 54, vid: 13},   // Virtual Horse Racing
    {gameID: 4, id: 55, vid: 11},   // Virtual Greyhounds Racing
    {gameID: 6, id: 150, vid: 15},  // Virtual Bicycle
    {gameID: 7, id: 188, vid: 16},  // Virtual Football League
    {gameID: 9, id: 209, vid: 21},  // Virtual Drag Racing
    {gameID: 12, id: 118, vid: 20}  // Virtual Marble Racing
  ];

  useEffect(() => {
    const dataURL = typeof platform === 'number' ? newDataURL : oldDataURL;

    fetch(dataURL)
      .then(response => response.json())
      .then(data => initBannersData(data))
      .catch(error => console.log('>> BANNER DATA ERROR:', error))
  }, [])

  const initBannersData = data => {
    const dataArray = [];
    data = data['widgets'];

    data.forEach(item => {
      dataArray.push({
        link: item['instance']['link'],
        image: item['instance']['image']
      })
    })

    setBannersData(dataArray);
  }

  const changeGame = link => {
    if (link && link.indexOf('game=') !== -1) {
      const url = new URL(link);
      const gameID = url.searchParams.get("game");

      if (gameID) {
        context.activeGameID.set(parseInt(gameID));
      }
    }
  }

  const getBanner = (image, link, index) => {
    return <div className='banner' onClick={() => changeGame(link)} key={index}>
      <img src={image} alt=''/>
    </div>
  }

  return useMemo(() => {
    return bannersData.length
      ? <div className='banner-container'>
        {bannersData.map(({image, link}, index) => getBanner(image, link, index))}
      </div>
      : null;
  }, [bannersData]);
}

export default memo(BannerContainer);