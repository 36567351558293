import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './LeagueMarket.scss';
import MatchdayBlock from "../MatchdayBlock/MatchdayBlock";
import LiveTables from "../../Viewport/LiveTables/LiveTables";
import {LEAGUE_ROUNDS_DATA, MARKET_DATA, UNSUBSCRIBE_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import LargeMarket from "../LargeMarket/LargeMarket";
import {AppContext} from "../../../App";
import Utils from "../../../utils/Utils";

function LeagueMarket({setBetsData}) {
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const activeSportData = context.sportsData.get.find(data => data.id === context.activeGameID.get);

  const [roundsData, setRoundsData] = useState([]);
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [activeRoundData, setActiveRoundData] = useState([]);
  const [activeRoundIndex, setActiveRoundIndex] = useState(0);
  const [isFirstDrawing, setIsFirstDrawing] = useState(true);
  const [selectedGameID, setSelectedGameID] = useState(0);
  const [leagueData, setLeagueData] = useState(null);

  useEffect(() => {
    sendLeagueDataRequest();

    return () => {
      unsubscribeFromMarketData();
      unsubscribeFromLeagueData();
    }
  }, []);

  useEffect(() => {
    updateActiveRoundData(activeRoundIndex);
    socket.addEventListener(RequestManager.AUTO_SCORES_DATA, updateScores);
    socket.addEventListener(RequestManager.AUTO_GAME_DATA, updateLeagueData);

    return () => {
      socket.removeEventListener(RequestManager.AUTO_SCORES_DATA, updateScores);
      socket.removeEventListener(RequestManager.AUTO_GAME_DATA, updateLeagueData);
    };
  }, [roundsData]);

  const sendLeagueDataRequest = () => {
    socket.send(JSON.stringify(LEAGUE_ROUNDS_DATA));
    socket.addEventListener(RequestManager.LEAGUE_ROUNDS_DATA_EVENT, response => parseRoundsData(response.detail), {once: true});
  }

  const updateLeagueData = ({detail}) => {
    if (detail['sport'] && detail['sport']['7']) {
      let roundsUpdatedData = [...roundsData];
      let needsToUpdate = false;
      let updateData = detail['sport']['7']['region'];
      updateData = updateData[Utils.firstKey(updateData)]['game'];

      Object.keys(updateData).map(key => {
        if (updateData[key] === null) {
          const index = roundsData.findIndex(round => +round.id === +key);

          if (index !== -1) {
            const completedMatchRound = roundsData[index].round;
            const previousRoundData = roundsData.find(round => +round.round === completedMatchRound - 1);

            if (index === 0) {
              roundsUpdatedData.shift();
            } else {
              roundsUpdatedData.splice(index, 1);
            }

            needsToUpdate = true;

            // Hard remove completed rounds.
            if (previousRoundData) {
              roundsUpdatedData = roundsUpdatedData.filter(round => round.round > previousRoundData.round);
            }
          }
        }
      });

      if (needsToUpdate) {
        updateRoundNumbers(roundsUpdatedData);
        setRoundsData(roundsUpdatedData);
      }
    }
  }

  const unsubscribeFromMarketData = () => {
    if (MARKET_DATA.subID && UNSUBSCRIBE_DATA.params.subid !== MARKET_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = MARKET_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    }
  }

  const unsubscribeFromLeagueData = () => {
    if (LEAGUE_ROUNDS_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = LEAGUE_ROUNDS_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
      LEAGUE_ROUNDS_DATA.subID = null;
    }
  }

  const parseRoundsData = response => {
    let roundsData = [];

    LEAGUE_ROUNDS_DATA.subID = response.data['subid'];
    response = response.data.data.game;

    Object.values(response).forEach(item => {
      roundsData.push(item);
    });

    roundsData = roundsData.sort((a, b) => a['start_ts'] - b['start_ts']);
    updateRoundNumbers(roundsData);
    setRoundsData(roundsData);

    initMarketRequest(roundsData[1]['id']);
  }

  const updateRoundNumbers = roundsData => {
    const roundNumbers = [];
    roundsData.forEach(item => {
      if (roundNumbers.indexOf(item.round) === -1) {
        roundNumbers.push(item.round);
      }
    });

    setRoundNumbers(roundNumbers);
  }

  const initMarketRequest = useCallback(gameID => {
    MARKET_DATA.params.where.game.id = parseInt(gameID);
    MARKET_DATA.params.where.sport.id = parseInt(activeSportData.id);
    MARKET_DATA.params.where.region.id = parseInt(activeSportData.region_id);

    unsubscribeFromMarketData();

    socket.send(JSON.stringify(MARKET_DATA));
    socket.addEventListener(RequestManager.MARKET_DATA_EVENT, response => parseMarketData(response.detail), {once: true});

    setSelectedGameID(gameID);
  }, [activeSportData])

  const parseMarketData = response => {
    try {
      const responseData = response.data.data;
      const isEmptyData = Object.keys(responseData['sport']).length === 0;
      if (isEmptyData) {
        return;
      }

      MARKET_DATA.subID = response.data['subid'];

      setLeagueData(responseData);
    } catch {
      console.log('>> LEAGUE data update error:', JSON.stringify(response));
    }
  }

  const updateScores = ({detail}) => {
    if (!detail) {
      return;
    }

    let response = detail;
    if (response['sport']) {
      response = response['sport'][Utils.firstKey(response['sport'])]['region'];
      response = response[Utils.firstKey(response)]['competition'];
      response = response[Utils.firstKey(response)]['game'];
    }

    const updates = [];
    if (response['game']) {
      Object.keys(response['game']).map(key => updates.push({[key]: response['game'][key]}));
    } else {
      const id = Utils.firstKey(response);
      updates.push({[id]: response[id]})
    }

    let updatedGameID;
    const updatedRoundsData = [...roundsData];

    updates.map(updateData => {
      updatedGameID = parseInt(Utils.firstKey(updateData));

      updatedRoundsData.map(item => {
        if (item['id'] === updatedGameID) {
          const updateInfo = updateData[updatedGameID];
          
          if (updateInfo && updateInfo['homescore']) {
            item['homescore'] = updateInfo['homescore']
          }

          if (updateInfo && updateInfo['awayscore']) {
            item['awayscore'] = updateInfo['awayscore']
          }
        }
      })
    })

    setRoundsData(updatedRoundsData);
  }

  const updateActiveRoundData = useCallback(activeRoundIndex => {
    const selectedRoundData = [];

    roundsData.map(item => {
      if (item.round === roundNumbers[activeRoundIndex]) {
        selectedRoundData.push(item);
      }
    });

    setActiveRoundIndex(activeRoundIndex);
    setActiveRoundData([...selectedRoundData]);
  }, [roundsData])

  const getMarketContent = useMemo(() => {

    return activeRoundData.length
      ? <div className='league-market'>
        <MatchdayBlock changeRoundCallback={updateActiveRoundData} activeRoundIndex={activeRoundIndex}
                       rounds={roundNumbers}/>
        <LiveTables firstRound={roundNumbers[0]} nextRound={roundNumbers[1]} roundData={activeRoundData}
                    initMarketRequest={initMarketRequest} selectedGameID={selectedGameID}
                    activeRoundIndex={activeRoundIndex}/>
        <LargeMarket setBetsData={setBetsData} isFootballLeague={true} marketExternalData={leagueData}/>
      </div>
      : null;
  }, [activeRoundData, selectedGameID, leagueData, roundsData])

  if (roundsData.length && isFirstDrawing) {
    setIsFirstDrawing(false);
    updateActiveRoundData(0);
  }

  return getMarketContent;
}

export default memo(LeagueMarket);