import React, { useRef, useEffect, useContext, useState, memo } from "react";
import "./PlayNow.scss";
import PlayersSlider from "./PlayersSlider/PlayersSlider";
import {
  LOGIN_INSTANTS_DATA,
  USER_MATCH_DATA,
} from "../../../../VSAPI/APIData";
import { TennisContext } from "../../../../../components/PromotionBlock/PromotionBlock";
import apiRequest from "../../../../VSAPI/ApiRequest";
import IsLoadingHOC from "../../../../Hoc/IsLoadingHOC";
import useDebounce from "../../../../hooks/useDebounce";
import Utils from "../../../../../utils/Utils";

function PlayNow({ changeScene, setLoading }) {
  const tennisContext = useContext(TennisContext);
  const iconBaseURL = process.env.PUBLIC_URL + "./assets/icons/";
  const slider1Ref = useRef();
  const slider2Ref = useRef();
  const API_REQUEST = apiRequest();
  const [isEcual, setIsEcual] = useState(false);
  const { gameId } = Utils.getURLParams();

  useEffect(() => {
    setLoading(false);

    if (!tennisContext.teams.get?.length) {
      LOGIN_INSTANTS_DATA.SportId =
        +gameId || tennisContext.activeInstantGameData.get.id;

      API_REQUEST("auth", "POST", LOGIN_INSTANTS_DATA)
        .then((res) => {
          tennisContext.teams.set(res.auth?.teams);
          getRandomPlayer();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, ">>>");
        });
    } else {
      setLoading(false);
      getRandomPlayer();
    }
  }, []);

  const getRandomPlayer = () => {
    if (slider1Ref.current && slider2Ref.current) {
      slider1Ref.current.selectRandomPlayer();
      slider2Ref.current.selectRandomPlayer();
    }
  };

  const playGame = () => {
    let teams = tennisContext.teams.get;
    let player1 = slider1Ref.current?.index;
    let player2 = slider2Ref.current?.index;
    if (player1 !== player2) {
      tennisContext.teamStars.set({
        homeTeam: teams[player1]?.strength_stars,
        awayTeam: teams[player2]?.strength_stars,
      });

      USER_MATCH_DATA.HomeTeamId = slider1Ref.current?.index + 1;
      USER_MATCH_DATA.AwayTeamId = slider2Ref.current?.index + 1;
      USER_MATCH_DATA.SportId = +gameId || tennisContext.activeInstantGameData.get.id
      tennisContext.isMatchEnded.set(false);
      setLoading(true);

      API_REQUEST("createMatch", "POST", USER_MATCH_DATA)
        .then((res) => {
          setLoading(false);
          if (res.match) {
            tennisContext.marketsData.set(res.match);
            tennisContext.balance.set(res.balance);
            changeScene(1);
          }
        })
        .catch((err) => {
          console.log(err, ">>>");
          setLoading(false);
        });
    }
  };

  return (
    <div className="play-now">
      <div className="container">
        <PlayersSlider
          ref={slider1Ref}
          secondSliderRef={slider2Ref}
          slideName="slide1"
          setIsEcual={setIsEcual}
          isEcual={isEcual}
        />
        <div className="vs-container">
          <img className="vs" src={iconBaseURL + "vs.png"} alt="" />
        </div>
        <PlayersSlider
          ref={slider2Ref}
          secondSliderRef={slider1Ref}
          slideName="slide2"
          setIsEcual={setIsEcual}
          isEcual={isEcual}
        />
      </div>
      <div className="footer">
        <button
          type="button"
          className="button random-button"
          onClick={useDebounce(getRandomPlayer, 300)}
        >
          RANDOM
        </button>
        <button type="button" className="button play-button" onClick={playGame}>
          PLAY
        </button>
      </div>
    </div>
  );
}

export default memo(IsLoadingHOC(PlayNow));
