import { memo } from "react";

const BetSlip = ({ children }) => {
  return (
    <div className="tennis-instant-bet-slip">
      <div className="bet-items-block-instant">
        <div className="bet-items-block-instant-content">{children}</div>
      </div>
    </div>
  );
};

export default memo(BetSlip);
