import React, { memo, useContext, useEffect, useState, useRef } from "react";
import "./ResultsPopUp.scss";
import {REGION_DATA, GAME_RESULTS_DATA, REGION_RID, GAME_MATCH_ID_RESULT} from "../../services/RequestDatas";
import RequestManager from "../../services/RequestManager";
import { AppContext } from "../../App";
import Select from "../Native/Select/Select";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Utils from "../../utils/Utils";

function ResultsPopUp({ closePopup, spotsData }) {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const dayBySeconds = 24 * 60 * 60 * 1000;
  const [activeGameID, setActiveGameID] = useState(context.activeGameID.get);
  const [gameResults, setGameResults] = useState([]);
  const [startDate, setStartDate] = useState(Date.now() - dayBySeconds);
  const [endDate, setEndDate] = useState(Date.now());
  const [loadingProcess, setLoadingProcess] = useState(true);
  const [matchId, setMatchId] = useState(null);
  const [matchIdRequestData, setMatchIdRequestData] = useState([]);
  const socket = RequestManager.getInstance().webSocket;
  const iconBaseURL = process.env.PUBLIC_URL + "./assets/icons/";
  const spotsList = spotsData.map(({ name }) => name);
  const activeIndex = spotsData.findIndex((data) => data.id === activeGameID);
  const regionsData = [];

  const fromCalendar = useRef();
  const toCalendar = useRef();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    getRegionData();

    return () => (document.body.style.overflow = "scroll");
  }, []);

  const getRegionData = () => {
    REGION_DATA.params.where.sport.id = activeGameID;
    REGION_DATA.rid = REGION_RID;

    socket.send(JSON.stringify(REGION_DATA));
    socket.addEventListener(RequestManager.REGION_DATA_EVENT, getGameResults, {
      once: true,
    });
  };

  const getGameResults = ({ detail }) => {
    const regionData = detail.data.data.region;

    for (let key in regionData) {
      if (
        regionData.hasOwnProperty(key) &&
        Object.keys(regionData[key].competition).length !== 0
      ) {
        regionsData.push(regionData[key]);
      }
    }

    const selectedRegionData = regionsData[0];
    const competitionObjectKey = Object.keys(selectedRegionData.competition)[0];

    let fromDate = parseInt(startDate / 1000);
    let toDate = parseInt(endDate / 1000);
    if (toDate === fromDate) {
      fromDate -= 36000;
    }

    GAME_RESULTS_DATA.params.sport_id = activeGameID;
    GAME_RESULTS_DATA.params.is_date_ts = 1;
    GAME_RESULTS_DATA.params.competition_id = selectedRegionData.competition[competitionObjectKey].id;
    GAME_RESULTS_DATA.params.from_date = fromDate;
    GAME_RESULTS_DATA.params.to_date = toDate;

    socket.send(JSON.stringify(GAME_RESULTS_DATA));
    socket.addEventListener(
      RequestManager.GAME_RESULTS_EVENT,
      initGameResults,
      { once: true }
    );
  };

  const getResultByMatchId = () => {
    GAME_MATCH_ID_RESULT.params.game_id = Number(matchId);
    GAME_MATCH_ID_RESULT.rid = Utils.getUniqNumber().toString()

    socket.send(JSON.stringify(GAME_MATCH_ID_RESULT));
    socket.addEventListener(
      RequestManager.GAME_MATCH_ID_RESULTS_EVENT,
      initMatchIdGameResults,
      { once: true }
    );
  };

  const initGameResults = ({ detail }) => {
    if (!detail.data.games) {
      return;
    }

    const results = detail.data.games.game;
    setGameResults(results);
    setLoadingProcess(false);
  };

  const sortResults = () => {
    const results = Array.from(gameResults.reverse());
    setGameResults(results);
  };

  const changeGame = (selectedValue) => {
    setActiveGameID(spotsData[selectedValue].id);
  };

  const changeResultsDate = (date, dateType) => {
    const selectedDateTimestamp = date.valueOf();
    // The maximum difference between the selected days can be 3 days.
    const maxDaysValue = 3 * 24 * 3600 * 1000;

    if (dateType === "start") {
      setStartDate(selectedDateTimestamp);

      const datesDifferance = endDate - selectedDateTimestamp;

      if (datesDifferance > maxDaysValue) {
        setEndDate(selectedDateTimestamp + maxDaysValue);
      }
    } else if (dateType === "end") {
      setEndDate(selectedDateTimestamp);

      const datesDifferance = selectedDateTimestamp - startDate;

      if (datesDifferance > maxDaysValue) {
        setStartDate(selectedDateTimestamp - maxDaysValue);
      }
    }
  };

  const initMatchIdGameResults = ({ detail }) => {
    const list = detail.data.lines.line;
    const middleIndex = Math.ceil(list.length / 2);

    const firstHalf = list.splice(0, middleIndex);
    const secondHalf = list.splice(-middleIndex);

    setMatchIdRequestData([firstHalf, secondHalf]);
    setLoadingProcess(false);
  };

  const searchResultsByFilter = () => {
    setLoadingProcess(true);

    if (matchId) {
      getResultByMatchId();
    } else {
      setMatchIdRequestData([]);
      getRegionData();
    }
  };

  const manageCalendarClosing = (event, calendarType) => {
    if (event.target.tagName === "INPUT") {
      if (calendarType === "from" && fromCalendar.current.state.open) {
        setTimeout(() => fromCalendar.current.setOpen(false), 200);
      } else if (calendarType === "to" && toCalendar.current.state.open) {
        setTimeout(() => toCalendar.current.setOpen(false), 200);
      }
    }
  };

  const getGameResultsList = (gameResults) => {
    return gameResults.length && !loadingProcess ? (
      <div className="game-results-list">
        {gameResults.map(
          (
            { competition_name, scores, sport_name, game_name, date },
            index
          ) => {
            const formattedData = new Date(date * 1000).toLocaleString('en-GB');

            if (formattedData) {
              return (
                <div className="game-result-items" key={index}>
                  <div className="result-info date">{formattedData.substring(0, formattedData.length - 3)}</div>
                  <div className="result-info">{game_name}</div>
                  <div className="result-info scores">{scores}</div>
                </div>
              );
            }
          }
        )}
      </div>
    ) : (
      <div className={"results-loader"} />
    );
  };

  const getMatchIdRequestDataList = (results) => {
    return results?.length && !loadingProcess ? (
      <div className="game-results-list matchIdResults">
        {matchIdRequestData.map((item, index) => (
          <div key={index}>
            {item.map((el, i) => (
              <div className="game-result-items" key={i}>
                <div className="result-info">{el.line_name}</div>
                {el.events.event_name.map((el, i) => (
                  <span> {`${!i ? ": " + el : ", " + el}`}</span>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    ) : (
      <div className={"results-loader"} />
    );
  };

  return (
    <div className="results-pop-up">
      <div className="pop-up-inner">
        <div className="pop-up-header">
          <span>{t("results")}</span>
          <div className="pop-up-close" onClick={closePopup} />
        </div>
        <div className="pop-up-content">
          <div className="pop-up-search-container">
            <div className="search-options">
              <div className="search-option">
                <span className="search-option-title">{t("sport")}</span>
                <Select list={spotsList} defaultValue={activeIndex} onChangeCallback={changeGame} matchId={matchId}/>
              </div>

              <div className="search-option">
                <span className="search-option-title">{t('matchID')}</span>
                <input
                  type="text"
                  className="matchId-input"
                  onChange={e => {
                    Utils.correctNumericInputValue(e);
                    setMatchId(e.target.value)
                  }}
                  onKeyDown={e => Utils.blockInvalidNumbers(e, true)}
                  onPaste={e => Utils.blockInvalidPastedNumbers(e, true)}
                />
              </div>

              <div className="search-option">
                <span className="search-option-title">{t("date")}</span>
                <div className="search-calendar"
                  onMouseDown={(event) => manageCalendarClosing(event, "from")}>
                  {matchId && <div className="disable-input"></div>}
                  <DatePicker
                    ref={fromCalendar}
                    className="calendar-input start"
                    selected={startDate}
                    maxDate={endDate}
                    timeFormat="HH:mm"
                    onChange={(date) => changeResultsDate(date, "start")}
                    readOnly={matchId ? true : false}
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                  <img className="calendar-icon" src={iconBaseURL + "calendar.svg"} alt=""/>
                </div>
              </div>
              <div className="search-option no-title">
                <div className="search-calendar untitled"
                  onMouseDown={(event) => manageCalendarClosing(event, "to")}>
                  {matchId && <div className="disable-input"></div>}
                  <DatePicker
                    ref={toCalendar}
                    className="calendar-input end"
                    selected={endDate}
                    minDate={startDate}
                    maxDate={Date.now()}
                    timeFormat="HH:mm"
                    onChange={(date) => changeResultsDate(date, "end")}
                    readOnly={matchId ? true : false}
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                  <img className="calendar-icon" src={iconBaseURL + "calendar.svg"} alt=""/>
                </div>
              </div>
            </div>
            <div className="search-results-button" onClick={searchResultsByFilter}>
              {Utils.upperCase(t("search"), context.language.get)}
            </div>
          </div>
          <div className="pop-up-results-container">
            {matchIdRequestData.length ? (
              getMatchIdRequestDataList(matchIdRequestData)
            ) : (
              <>
                <div className="game-result-items result-titles">
                  <div className="result-title sort-by-date" onClick={sortResults}>
                    {t("date")}
                    <div className="sort-arrow to-down" />
                  </div>
                  <div className="result-title">{t("event")}</div>
                  <div className="result-title scores">{t("score")}</div>
                </div>
                {getGameResultsList(gameResults)}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ResultsPopUp);
