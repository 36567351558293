const AUTH_TOKEN = null;

const LOGIN_INSTANTS_DATA = {
  AuthenticationType: -1,
  SportId: 1,
};

const USER_MATCH_DATA = {
  SportId: 1,
  HomeTeamId: 1,
  AwayTeamId: 2,
};

const START_SCENE_DATA = {
  UserMatchID: null,
  OddType: 1,
  SourceId: 42,
  Events: [],
};

const END_MATCH_DATA = {
  UserMatchID: null,
};

const NEXT_POINT_DATA = {
  UserMatchID: null,
};

const MY_BETS_DATA = {
  UserMatchID: null,
};

const BET_HISTORY_DATA = {
  SportId: 1,
  FromDate: null,
  ToDate: null,
  BetId: null,
};

const BET_URL_DATA = {
  BetId: null,
};

const CASH_OUT_DATA = {
  BetIds: [],
  UserMatchId: null,
};

// DATAS
export {
  AUTH_TOKEN,
  LOGIN_INSTANTS_DATA,
  USER_MATCH_DATA,
  START_SCENE_DATA,
  END_MATCH_DATA,
  NEXT_POINT_DATA,
  BET_HISTORY_DATA,
  BET_URL_DATA,
  CASH_OUT_DATA,
  MY_BETS_DATA,
};
