import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import './HistoryPopUp.scss';
import Select from "../../Native/Select/Select";
import DatePicker from "react-datepicker";
import {BET_HISTORY_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import HistoryBetItem from "./HistoryBetItem/HistoryBetItem";
import {useTranslation} from "react-i18next"
import Utils from "../../../utils/Utils";
import {AppContext} from "../../../App";

function HistoryPupUp({hideHistoryPopUp}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const isTaxCase = context.partnerConfig.get['tax_integration_type'] === 1;
  const socket = RequestManager.getInstance().webSocket;
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';
  const betTypes = [t('all'), t('single'), t('multiple'), t('system')];
  const periodTypes = [t('1h'), t('2h'), t('3h'), t('6h'), t('12h'), t('24h'), t('48h'), t('72h')];

  const [startDate, setStartDate] = useState(Utils.formatPeriodDate(3));
  const [toData, setToDate] = useState(Date.now());
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedBetType, setSelectedBetType] = useState('0');
  const [betsList, setBetList] = useState([]);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const [update, updateState] = React.useState(false);
  const [showAllFilters, setShowAllFilters] = useState(true);

  const fromCalendar = useRef();
  const toCalendar = useRef();
  const periodHardValue = useRef();
  const betIDInput = useRef();

  const forceUpdate = () => updateState(!update);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'scroll';
  }, [])

  useEffect(() => {
    const betID = betIDInput.current && Boolean(betIDInput.current.value) ? betIDInput.current.value : null;
    getHistoryData(betID);
  }, [update]);

  useEffect(() => {
    !context.userLoggedIn.get && hideHistoryPopUp()
  }, [ context.userLoggedIn.get ])

  const getHistoryData = betID => {
    setLoadingInProcess(true);

    BET_HISTORY_DATA.params.where.from_date = (new Date(startDate)) / 1000 ;
    BET_HISTORY_DATA.params.where.to_date = (new Date(toData).setHours(23, 59, 59)) / 1000;
    BET_HISTORY_DATA.params.where.bet_id = betID;

    socket.send(JSON.stringify(BET_HISTORY_DATA));
    socket.addEventListener(RequestManager.BET_HISTORY_EVENT, response => parseResponse(response.detail), {once: true});
  }

  const parseResponse = response => {
    const bets = response.data.bets;

    setLoadingInProcess(false);
    setBetList(bets);
  }

  const changeResultsDate = (date, dateType) => {
    const selectedDateTimestamp = date.valueOf();
    periodHardValue.current = 0;

    if (dateType === 'start') {
      setStartDate(selectedDateTimestamp);
    } else if (dateType === 'end') {
      setToDate(selectedDateTimestamp);
    }
  }

  const getHistoryBetItem = (itemData, i) => {
    if (selectedTabIndex === 0) {
      return <HistoryBetItem itemData={itemData} betTypes={betTypes} key={i} oddType={context.oddType.get} isTaxCase={isTaxCase}/>
    }

    // Open = 0, Win = 3, Lose = 1, Return = 2
    const statuses = [0, 3, 1, 2, 5];
    const itemStatus = itemData['outcome'];
    const statusIndex = statuses.indexOf(itemStatus);
    const filteredIndex = statusIndex >= 0 ? statusIndex + 1 : 0;

    return selectedTabIndex === filteredIndex ?
      <HistoryBetItem key={i} itemData={itemData} betTypes={betTypes} oddType={context.oddType.get} isTaxCase={isTaxCase}/> : null;
  }

  const getFilteredBetsList = () => {
    let filteredBetsList = [...betsList];
    if (selectedBetType !== '0') {
      filteredBetsList = betsList.filter(item => betTypes[item['type']] === betTypes[selectedBetType]);
    }

    return <div className='bets-list'>
      {
        filteredBetsList.map((betItemData, index) => {
          return getHistoryBetItem(betItemData, index);
        })
      }
    </div>
  }

  const getBetsList = () => {
    if (!betsList || betsList.length === 0) {
      return <div className='empty-history'>{t('history empty')}</div>;
    }

    return loadingInProcess
      ? <div className='history-data-loader'/>
      : <div className='bets-list-container'>
        <div className='bets-list-titles'>
          <div className="bets-list-title data-and-id">{t("data id")}</div>
          <div className="bets-list-title bet-type">{t("bet type")}</div>
          <div className="bets-list-title stake">{t("stake")}</div>
          <div className="bets-list-title odds">{t("odds")}</div>
          <div className="bets-list-title outcome">{t("outcome")}</div>
          <div className="bets-list-title status">{t("status")}</div>
        </div>
        {getFilteredBetsList()}
      </div>
  }

  const changeSelectedPeriod = value => {
    const currentTime = Date.now();
    const formattedDate = Utils.formatPeriodDate(value);
    
    periodHardValue.current = value;
    setStartDate(formattedDate);
    setToDate(currentTime);
  }

  const manageCalendarClosing = (event, calendarType) => {
    if (event.target.tagName === 'INPUT') {
      if (calendarType === 'from' && fromCalendar.current.state.open) {
        setTimeout(() => fromCalendar.current.setOpen(false), 200);
      } else if (calendarType === 'to' && toCalendar.current.state.open) {
        setTimeout(() => toCalendar.current.setOpen(false), 200);
      }
    }
  }

  const showFilteredData = () => {
    if (loadingInProcess === false) {
      forceUpdate();
    }
  }

  const getBetTypeFilter = () => {
    return <div className="history-filter filter-bet-type">
      <p>{t('bet type')}</p>
      <Select list={betTypes} defaultValue={selectedBetType} onChangeCallback={value => setSelectedBetType(value)}/>
    </div>
  }

  const getPeriodFilter = () => {
    const openBetsTabIsActive = selectedTabIndex === 1;

    return openBetsTabIsActive ? null : <div className="history-filter filter-bet-period">
      <p>{t('period')}</p>
      <Select list={periodTypes} hardValue={periodHardValue.current} defaultValue={3} onChangeCallback={changeSelectedPeriod}/>
    </div>
  }

  const getRangeFilter = () => {
    const openBetsTabIsActive = selectedTabIndex === 1;

    return openBetsTabIsActive ? null :  <div className="history-filter filter-bet-range">
      <p>{t('range')}</p>
      <div className='filter-calendars'>
        <div className="search-calendar" onMouseDown={event => manageCalendarClosing(event,'from')}>
          <DatePicker ref={fromCalendar} className='calendar-input start' selected={startDate} maxDate={toData}
                      timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'start')}
                      onFocus={(e) => e.target.readOnly = true}/>
          <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
        </div>
        <div className="search-calendar" onMouseDown={event => manageCalendarClosing(event,'to')}>
          <DatePicker ref={toCalendar} className='calendar-input end' selected={toData} minDate={startDate} maxDate={Date.now()}
                      timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'end')}
                      onFocus={(e) => e.target.readOnly = true}/>
          <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
        </div>
      </div>
    </div>
  }

  const getDynamicFilters = () => {
    const disabledClassName = showAllFilters ? '' : 'disabled';

    return <div className={"dynamic-filters " + disabledClassName}>
      {getBetTypeFilter()}
      {getPeriodFilter()}
      {getRangeFilter()}
    </div>
  }

  const getFilters = () => {

    return <div className="history-filters">
      <div className="filters">
        <div className="history-filter filter-bet-id">
          <p>{t('bet ID')}</p>
          <input ref={betIDInput} className="bet-id-input" type="text" placeholder="#"
                 onKeyDown={e => Utils.blockInvalidNumbers(e, true)} 
                 onPaste={e => Utils.blockInvalidPastedNumbers(e, true)}
                 onChange={e => {
                   Utils.correctNumericInputValue(e);
                   setShowAllFilters(e.currentTarget.value === '')
                 }}/>
        </div>
        {getDynamicFilters()}
      </div>
      <div className="history-filter show-button" onClick={showFilteredData}>
        {loadingInProcess ? <div className='loading-icon'/> : Utils.upperCase(t('show'), context.language.get)}
      </div>
    </div>
  }

  const getPopUpContent = () => {
    const tabTypes = [t('all bets'), t('open'), t('win'), t('lost'), t('return'), t('cash-out')];
    let tabClassName;

    return <div className='history-pop-up'>
      <div className="pop-up-inner">
        <div className='pop-up-header'>
          <span>{t('my bets')}</span>
          <div className='pop-up-close' onClick={hideHistoryPopUp}/>
        </div>
        <div className="history-pop-up-container">
          <div className="history-tabs">
            {
              tabTypes.map((name, index) => {
                tabClassName = index === selectedTabIndex ? 'history-tab active' : 'history-tab';

                return <div key={index} className={tabClassName} onClick={() => setSelectedTabIndex(index)}>{t(name)}</div>
              })
            }
          </div>
          {getFilters()}
        </div>
        {getBetsList()}
      </div>
    </div>
  }

  return getPopUpContent();
}

export default memo(HistoryPupUp);
