import { memo, useContext, useState } from "react";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";
import PlayersBlock from "../../Match/PlayersBlock/PlayersBlock";
import BetsHistory from "./BetHistory/BetsHistory";
import "./betInformation.scss";
import MyBets from "./MyBets/MyBets";

const BetInformation = ({ setTotalStake, setTotalPossibleAmount }) => {
  const tennisContext = useContext(TennisContext);
  const [activeTab, setActiveTab] = useState("MY BETS");

  return (
    <>
      <PlayersBlock data={tennisContext.marketsData.get} />
      <div className="bet-information">
        <div className="tabs">
          <button
            type="button"
            className={`button ${activeTab === "MY BETS" ? " active" : ""}`}
            onClick={() => setActiveTab("MY BETS")}
          >
            MY BETS
          </button>
          <button
            type="button"
            className={`button ${activeTab === "BET HISTORY" ? " active" : ""}`}
            onClick={() => setActiveTab("BET HISTORY")}
          >
            BET HISTORY
          </button>
        </div>

        {activeTab === "MY BETS" ? (
          <MyBets
            setTotalStake={setTotalStake}
            setTotalPossibleAmount={setTotalPossibleAmount}
          />
        ) : (
          <BetsHistory />
        )}
      </div>
    </>
  );
};

export default memo(BetInformation);
