import React, {memo, useContext, useEffect, useState} from 'react';
import './WrestlingMarket.scss';
import {MARKET_DATA, UNSUBSCRIBE_DATA, WRESTLING_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import FightsBlock from "./FightsBlock/FightsBlock";
import LargeMarket from "../LargeMarket/LargeMarket"
import {AppContext} from "../../../App";
import LiveFightInfo from "./LiveFightInfo/LiveFightInfo";

function WrestlingMarket({setBetsData}) {
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const activeSportData = context.sportsData.get.find(data => data.id === context.activeGameID.get);

  const [fightsData, setFightsData] = useState([]);
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [activeRoundData, setActiveRoundData] = useState([]);
  const [activeFightData, setActiveFightData] = useState({});
  const [isFirstDrawing, setIsFirstDrawing] = useState(true);
  const [marketData, setMarketData] = useState(null);
  const [liveFightData, setLiveFightData] = useState();

  useEffect(() => {
    initWrestlingDataRequest();

    return () => {
      unsubscribeFromMarketData();
      unsubscribeFromWrestlingData();
    }
  }, []);

  useEffect(() => {
    initMarketRequest();
  }, [activeFightData])

  useEffect(() => {
    socket.addEventListener(RequestManager.AUTO_SCORES_DATA, response => updateScores(response.detail), {once: true});
  }, [fightsData]);

  const unsubscribeFromWrestlingData = () => {
    if (WRESTLING_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = WRESTLING_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
      WRESTLING_DATA.subID = null;
    }
  }

  const unsubscribeFromMarketData = () => {
    if (MARKET_DATA.subID && UNSUBSCRIBE_DATA.params.subid !== MARKET_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = MARKET_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    }
  }

  const initWrestlingDataRequest = () => {
    socket.send(JSON.stringify(WRESTLING_DATA));
    socket.addEventListener(RequestManager.WRESTLING_DATA_EVENT, response => parseData(response.detail), {once: true});
  }

  const initMarketRequest = () => {
    if (Object.keys(activeFightData).length) {
      unsubscribeFromMarketData();

      MARKET_DATA.params.where.game.id = parseInt(activeFightData['id']);
      MARKET_DATA.params.where.sport.id = parseInt(activeSportData.id);
      MARKET_DATA.params.where.region.id = parseInt(activeSportData.region_id);

      socket.send(JSON.stringify(MARKET_DATA));
      socket.addEventListener(RequestManager.MARKET_DATA_EVENT, response => parseMarketData(response.detail), {once: true});
    }
  }

  const updateScores = response => {
    const updatedGamesData = response['game'] ? response['game'] : [];
    const updatedFightsData = [...fightsData];

    Object.keys(updatedGamesData).map(key => {
      updatedFightsData.map(fightData => {
        if (fightData.id === parseInt(key)) {
          if (updatedGamesData[key] === null && fightData['weight'] === 125) {
            initWrestlingDataRequest();
            setRoundNumbers([]);
            setFightsData([]);
          } else if (fightData && Object.keys(updatedGamesData).length) {
            Object.keys(updatedGamesData[key]).map(upKey => {
              fightData[upKey] = updatedGamesData[key][upKey];

              if (upKey === 'isLive' && updatedGamesData[key][upKey] === 1) {
                setLiveFightData(fightData);
              }
            });

            if (liveFightData && liveFightData['id'] === fightData.id) {
              const updateLiveFightData = {...liveFightData};
              Object.keys(updatedGamesData[key]).map(upKey => updateLiveFightData[upKey] = updatedGamesData[key][upKey]);
              setLiveFightData(updateLiveFightData);
            }
          }
        }
      })
    })

    setFightsData(updatedFightsData);
  }

  const parseMarketData = response => {
    try {
      const responseData = response.data.data;
      const isEmptyData = Object.keys(responseData['sport']).length === 0;
      if (isEmptyData) {
        return;
      }

      MARKET_DATA.subID = response.data['subid'];

      setMarketData(responseData);
    } catch {
      console.log('>> WRESTLING data update error:', JSON.stringify(response));
    }
  }

  const parseData = response => {
    const fightsNumbers = [];
    const data = [];
    let isLiveData;

    WRESTLING_DATA.subID = response.data['subid'];
    response = response.data.data.game;

    Object.values(response).forEach(item => {
      data.push(item);

      if (fightsNumbers.indexOf(item.round) === -1) {
        fightsNumbers.push(item.round);
      }

      if (item['isLive']) {
        isLiveData = item;
      }
    });

    if (isLiveData) {
      setLiveFightData(isLiveData);
    }

    setRoundNumbers(fightsNumbers.sort((a, b) => a - b));
    setFightsData(data);
    initMarketRequest();
  }

  const getMarket = () => {
    return marketData ?
      <LargeMarket setBetsData={setBetsData} isWrestling={true} marketExternalData={marketData}/> : null;
  }

  const updateActiveRoundData = activeRoundIndex => {
    const selectedRoundData = [];

    fightsData.forEach(item => {
      if (item.round === roundNumbers[activeRoundIndex]) {
        selectedRoundData.push(item);
      }
    });

    setActiveRoundData(selectedRoundData);
  }

  const updateActiveFight = roundIndex => {
    updateActiveRoundData(roundIndex);
  }

  const changeActiveFight = fightData => {
    if (fightData && JSON.stringify(fightData).indexOf('__') === -1) {
      setActiveFightData(fightData);
    }
  }

  const getMarketContent = () => {
    return activeRoundData.length
      ? <div className='wrestling-market'>
        <LiveFightInfo fightData={liveFightData}/>
        <FightsBlock fightsData={fightsData} changeFightCallback={changeActiveFight}
                     activeFightID={activeFightData.id}/>
        {getMarket()}
      </div>
      : null;
  }

  if (fightsData.length && isFirstDrawing) {
    setIsFirstDrawing(false);
    updateActiveFight(0);
  }

  return getMarketContent();
}

export default memo(WrestlingMarket);