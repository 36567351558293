import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { TennisContext } from "../../../../../../../components/PromotionBlock/PromotionBlock";
import Utils from "../../../../../../../utils/Utils";
import "./TennisInstantBetItem.scss";

function TennisInstantBetItem({
  itemData,
  setBetItems,
  betItems,
  setErrorMessage,
  setSelectedBetsId,
  key,
  placeBets,
  checkExistError,
}) {
  const tennisContext = useContext(TennisContext);
  const deleteBetItem = () => {
    let currentBets = betItems.filter(
      ({ bet_id }) => bet_id !== itemData.bet_id
    );

    setBetItems(currentBets);
    setSelectedBetsId(currentBets.map(({ bet_id }) => bet_id));
    checkExistError(currentBets, true);
  };

  const handleBetPosibleAmount = (betAmount, betID, oddType) => {
    let showBetAmountError = ""

    if (+betAmount < tennisContext.stake.get.min) {
      showBetAmountError = `Bet amount is less than min allowed ${tennisContext.stake.get.min} ${tennisContext.currency.get}`;
      setErrorMessage(true);
    } else if (
      Math.round(betAmount * (oddType - 1)) > tennisContext.stake.get.max
    ) {
      showBetAmountError = "Over Max Bet"
      setErrorMessage(true);
    } else {
      showBetAmountError = ""
      setErrorMessage(false);
    }

    let betItem = betItems.map((item) => {
      if (item.bet_id === betID) {
        item.win_amount = betAmount * item.odd_type;
        item.bet_amount = betAmount;
        item.error_message = showBetAmountError;
      }

      return item;
    });

    setBetItems(betItem);
  };

  const placeBetWithEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      placeBets();
    }
    return Utils.blockInvalidNumbers;
  };

  return (
    <div className="tennis-instant-bet-item" key={key}>
      <span className="ti-icon ti-close-button" onClick={deleteBetItem}>
        ✖
      </span>
      <p className="title">{itemData.market_name}</p>
      <p className="winner-name">
        <span>{itemData.selection_name}</span> <span>{itemData.odd_type}</span>
      </p>
      <form>
        <input
          type="text"
          onChange={(e) => {
            Utils.correctBetInputValue(e);
            handleBetPosibleAmount(
              e.target.value,
              itemData.bet_id,
              itemData.odd_type
            );
          }}
          onKeyDown={(e) => {
            Utils.blockInvalidNumbers(e);
            placeBetWithEnter(e);
          }}
          onPaste={(e) => {
            Utils.blockInvalidPastedNumbers(e);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          autoFocus
          value={itemData.bet_amount}
        />
        <button
          type="button"
          onClick={(e) => {
            handleBetPosibleAmount(
              (tennisContext.stake.get.max / (itemData.odd_type - 1)).toFixed(
                2
              ),
              itemData.bet_id,
              itemData.odd_type
            );
          }}
          className="max-bet-btn"
        >
          MAX
        </button>
      </form>
      <p className="wining-amount">
        <span>Possible Winning Amount: </span>
        <span>{itemData.win_amount?.toFixed(2) || 0} AMD</span>
      </p>
      {itemData.error_message ? (
        <p className="bet-amount-error">{itemData.error_message}</p>
      ) : null}
    </div>
  );
}

export default memo(TennisInstantBetItem);
