import React, {memo} from 'react';
import './InstantContainer.scss';
import TennisInstantGame from "../Games/TennisInstantGame/TennisInstantGame";

function InstantContainer({gameID, closeCallback}) {
  const gamesData = [
    {
      id: 1,
      content: <TennisInstantGame key={0}/>,
    }
  ];

  const getContent = () => {

    return <div className="viewport-content">
      {
        gamesData.map(({id, content}) => gameID === id ? content : null)
      }
    </div>
  }

  return <div className='instant-container'>
    <div className='instant-viewport'>
      <div className='controls'>
        <div className='close-button' onClick={closeCallback}>✕</div>
      </div>
      {getContent()}
    </div>
    <div className='instant-container-background'/>
  </div>
}

export default memo(InstantContainer);