import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./TennisInstantBetSlip.scss";
import TennisInstantBetItem from "./TennisInstantBetItem/TennisInstantBetItem";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";
import { END_MATCH_DATA, START_SCENE_DATA } from "../../../../../VSAPI/APIData";
import apiRequest from "../../../../../VSAPI/ApiRequest";
import IsLoadingHOC from "../../../../../Hoc/IsLoadingHOC";

function TennisInstantBetSlip({
  changeScene,
  isSkipScene,
  setSkipeScene,
  betItems,
  setBetItems,
  setLoading,
  setSelectedBetsId,
  isLoading
}) {
  const tennisContext = useContext(TennisContext);
  const { home_score, away_score } = tennisContext.marketsData.get;
  const API_REQUEST = apiRequest();
  const [errorMessage, setErrorMessage] = useState(false);
  const imagesBaseURL = process.env.PUBLIC_URL + "./assets/instants/icons/";
  let allAmount = 0;

  useEffect(() => {
    setLoading(false);
  }, []);

  const checkExistError = (betItems, withDelete = false) => {
    let existError = false;
    START_SCENE_DATA.Events = betItems
      .map(function (item) {
        if (item.bet_amount && !item.error_message?.length) {
          allAmount += +item.bet_amount;
          return {
            SelectionId: item.bet_id,
            Price: item.odd_type,
            BetAmount: +item.bet_amount,
          };
        }
      })
      .filter((item) => item != undefined);

    if (betItems.length !== START_SCENE_DATA.Events.length && !withDelete) {
      setErrorMessage("Input the correct stake");
      existError = true;
    } else if (allAmount > tennisContext.balance.get) {
      setErrorMessage("Insufficient balance");
      existError = true;
    } else if (!betItems.length && !home_score && !away_score && !withDelete) {
      setErrorMessage("There are no bets, to start the match");
      existError = true;
    } else {
      setErrorMessage("");
    }

    if (existError) {
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
    }

    return existError;
  };

  const placeBets = () => {
    let existError = null;
    START_SCENE_DATA.UserMatchID = tennisContext.marketsData.get.id;
    if (!errorMessage) {
      existError = checkExistError(betItems);
    }

    if (
      (home_score || away_score || START_SCENE_DATA.Events.length ) &&
      !existError &&
      !errorMessage && isLoading == false
    ) {
      setLoading(true);
      API_REQUEST("startScene", "POST", START_SCENE_DATA)
        .then((res) => {
          if (res.message_id === 15) {
            let currentMarketData = tennisContext.marketsData.get;
            currentMarketData.w = res.current_scene.w;
            tennisContext.isMatchEnded.set(true);
            tennisContext.marketsData.set(currentMarketData);
          } else if (res.message_id === 3) {
            if (allAmount > tennisContext.balance.get) {
              tennisContext.balance.set(res.balance);
              setErrorMessage("Insufficient balance");
              existError = true;
            }
          }
          res.current_scene.serving_player =
            tennisContext.marketsData.get.serving_player;
          tennisContext.sceneData.set(res);
          setLoading(false);
          changeScene(2);
          setSkipeScene(false);
        })
        .catch((err) => {
          console.log(err, ">>>");
          setLoading(false);
        });
    }
  };

  const changePlayers = async () => {
    END_MATCH_DATA.UserMatchID = tennisContext.marketsData.get.id;
    await API_REQUEST("endMatch", "POST", END_MATCH_DATA);
    changeScene(0);
    tennisContext.isMatchEnded.set(true);
  };

  const getTotalPossibleWinAmount = useMemo(() => {
    return betItems.reduce(function (sum, current) {
      return (sum += current.win_amount > 0 ? current.win_amount : 0);
    }, 0);
  }, [betItems]);

  return (
    <>
      <div className={`error-message  ${errorMessage.length ? "active" : ""}`}>
        <span
          className="close-error-msg"
          onClick={() => setErrorMessage(false)}
        >
          ✖
        </span>
        <img src={imagesBaseURL + "/errorIcon.png"} />
        <span className={`${errorMessage.length ? "loading" : ""}`}></span>
        {errorMessage}
      </div>
      {betItems.length ? (
        <div className="bet-items-block-instant-content-board">
          <div
            className="tennis-instant-bet-slip-items"
            id="bet-items-content-board"
          >
            {betItems.map((itemData, i) => (
              <TennisInstantBetItem
                itemData={itemData}
                betItems={betItems}
                setBetItems={setBetItems}
                setErrorMessage={setErrorMessage}
                setSelectedBetsId={setSelectedBetsId}
                key={itemData.bet_id}
                isSkipScene={isSkipScene}
                setSkipeScene={setSkipeScene}
                placeBets={placeBets}
                checkExistError={checkExistError}
              />
            ))}
          </div>
          <footer className="tennis-instant-bet-slip-items-footer">
            <span>Total Possible Winnings</span>
            <p className="posible-winning-amount">
              {`${getTotalPossibleWinAmount?.toFixed(2)} ${
                tennisContext.currency.get
              }`}
            </p>
          </footer>
        </div>
      ) : null}
      <div className="buttons bet-slip-btns">
        <div className="button" onClick={() => changeScene(3)}>
          MY BETS
        </div>
        {home_score || away_score ? (
          <div
            className="button"
            onClick={() => {
              changeScene(4);
              tennisContext.isMatchEnded.set(true);
              tennisContext.isSkipScene.set(false);
            }}
          >
            END MATCH
          </div>
        ) : null}
        {!home_score && !away_score ? (
          <div className="button" onClick={changePlayers}>
            CHANGE PLAYERS
          </div>
        ) : null}

        <button
          className={`button place-bet ${
            !(!home_score && !away_score) ? "next-point" : ""
          }`}
          onClick={placeBets}
        >
          {!home_score && !away_score
            ? `PLACE BET${betItems.length > 1 ? "S" : ""}`
            : ""}
        </button>
      </div>
    </>
  );
}

export default memo(IsLoadingHOC(TennisInstantBetSlip));
