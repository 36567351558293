import React, { useContext, useState, useEffect, memo } from 'react';
import './PromotionBlock.scss';
import Slider from '../../layout/Slider/Slider';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';
import InstantContainer from '../../instants/InstantContainer/InstantContainer';
import Utils from '../../utils/Utils';
import { useHistory } from 'react-router-dom';
import { LOGIN_INSTANTS_DATA } from '../../instants/VSAPI/APIData';
import apiRequest from '../../instants/VSAPI/ApiRequest';
import IsLoadingHOC from '../../instants/Hoc/IsLoadingHOC';

export const TennisContext = React.createContext({});

function PromotionBlock({ partnerConfig, setLoading }) {
  const { t } = useTranslation();
  const history = useHistory();
  const context = useContext(AppContext);
  const userLoggedIn = context.userLoggedIn.get;
  const baseURL = process.env.PUBLIC_URL + './assets/promotion/';
  const [ activeTab, setActiveTab ] = useState(0);
  const [ visibleInstants, setVisibleInstants ] = useState([]);
  const [ activeInstantGameData, setActiveInstantGameData ] = useState(null);
  const showInstants = context.partnerConfig.get['has_instant'];
  const showVirtual = context.partnerConfig.get['has_virtual'];
  const API_REQUEST = apiRequest();
  const [ teams, setTeams ] = useState([]);
  const [ activeScene, setActiveScene ] = useState(false);
  const [ marketsData, setMarkets ] = useState([]);
  const [ sceneData, setSceneData ] = useState(null);
  const [ isMatchEnded, setIsMatchEnded ] = useState(false);
  const [ isSkipScene, setSkipeScene ] = useState(false);
  const [ balance, setBalance ] = useState(0);
  const [ currency, setCurrency ] = useState('AMD');
  const [ stake, setStake ] = useState({
    min: null,
    max: null,
  });
  const [ teamStars, setTeamStars ] = useState({
    awayTeam: null,
    homeTeam: null,
  });

  const instantsStore = {
    teams: { get: teams, set: setTeams },
    activeScene: { get: activeScene, set: setActiveScene },
    marketsData: { get: marketsData, set: setMarkets },
    sceneData: { get: sceneData, set: setSceneData },
    isSkipScene: { get: isSkipScene, set: setSkipeScene },
    isMatchEnded: { get: isMatchEnded, set: setIsMatchEnded },
    balance: { get: balance, set: setBalance },
    currency: { get: currency, set: setCurrency },
    teamStars: { get: teamStars, set: setTeamStars },
    stake: { get: stake, set: setStake },
    activeInstantGameData: {
      get: activeInstantGameData,
      set: setActiveInstantGameData,
    },
  };

  useEffect(() => {
    if (context.partnerConfig.get && !Utils.IS_VBET && showInstants) {
      getPartnerDataFromCRM();
    }
  }, [ context.partnerConfig.get ]);

  const getPartnerDataFromCRM = async () => {
    const partnerID = partnerConfig['partner_id'];
    const res = await API_REQUEST(`partnerConfigs/${ partnerID }`, 'GET');

    if (showInstants) {
      setVisibleInstants(res?.partner_configs?.available_sports);
    }
  };

  const openSelectedGame = (id) => {
    context.activeGameID.set(id);
    context.promotionNotShown.set(false);

    history.push('?gameI=' + id.toString());
    window.parent.postMessage(
      { action: 'setLocation', location: { game: id.toString() } },
      '*'
    );
  };

  const openInstantGame = (id, name) => {
    setActiveScene(false);
    setIsMatchEnded(false);
    if (!userLoggedIn) {
      window.parent.postMessage({ action: 'openSlider', tab: 'login' }, '*');
    } else {
      setLoading(true);
      setActiveInstantGameData({ name: name, id: id });

      LOGIN_INSTANTS_DATA.SportId = id;
      API_REQUEST('auth', 'post', LOGIN_INSTANTS_DATA).then((res) => {
        setStake({
          min: res.min_bet_stake,
          max: res.max_stake,
        });

        if (res.message_id === 2) {
          setActiveInstantGameData(null);
        }

        if (res.message_id == 12) {
          setMarkets(res.auth.match);
          setBalance(res.balance);
          setCurrency(res.currency);
          setTeamStars({
            awayTeam: res.auth?.match.away_team_strength_stars,
            homeTeam: res.auth?.match.home_team_strength_stars,
          });
          setActiveScene(1);
        } else {
          localStorage.setItem('teams', JSON.stringify(res.auth?.teams));
          setTeams(res.auth?.teams);
          setActiveScene(0);
        }
        setLoading(false);
      });
    }
  };

  const getItemsContainer = () => {
    return (
      <div className="items-container">
        { partnerConfig
          ? activeTab === 0
            ? getVirtualContent()
            : getInstantContent()
          : null }
      </div>
    );
  };

  const getVirtualContent = () => {
    const newIcon = process.env.PUBLIC_URL + './assets/icons/new.png';

    if (context.siteID.get === 333) {
      openSelectedGame(1);
      return
    }

    return partnerConfig['available_sports'].length
      ? partnerConfig['available_sports'].map(({ name, id }, index) => {
        return (
          <div className="item" key={ index } onClick={ () => openSelectedGame(id) }>
            {
              id === 12 ? <img className="new-icon" src={ newIcon } alt=""/> : null
            }
            <video
              src={ baseURL + 'games/videos/' + name + '.mov' }
              onMouseOver={ (event) => event.currentTarget.play() }
              onMouseOut={ (event) => event.currentTarget.pause() }
              loop
              muted
            />
            <div className="game-name">{ t(name) }</div>
          </div>
        );
      })
      : null;
  };

  const getInstantContent = () => {
    return visibleInstants?.length
      ? visibleInstants.map(({ name, id }, index) => {
        return (
          <div
            className="item"
            key={ index }
            onClick={ () => openInstantGame(id, name) }
          >
            <img
              src={ baseURL + 'instant/' + id + '.png?' + Date.now() }
              alt={ name }
            />
          </div>
        );
      })
      : null;
  };

  const getInstantGamesContainer = () => {
    return activeInstantGameData ? (
      <InstantContainer
        gameID={ activeInstantGameData['id'] }
        closeCallback={ () => {
          setActiveInstantGameData(null);
          setSkipeScene(false);
        } }
      />
    ) : null;
  };

  const promotionBlockContent = () => {

    return (
      <div className="promotion-block-content">
        <div className="slider">
          <Slider clickHandler={ openSelectedGame }/>
        </div>
        { !activeInstantGameData ? (
          <div className="promotion-content">
            <div className="promotion-filters">
              { showVirtual ? (
                <div
                  className={
                    'promotion-tab' + (activeTab === 0 ? ' active' : '')
                  }
                  onClick={ () => setActiveTab(0) }
                >
                  { Utils.upperCase(t('virtual'), context.language.get) }
                </div>
              ) : null }
              { showInstants ? (
                <div
                  className={
                    'promotion-tab' + (activeTab === 1 ? ' active' : '')
                  }
                  onClick={ () => setActiveTab(1) }
                >
                  { Utils.upperCase(t('instant'), context.language.get) }
                </div>
              ) : null }
            </div>
            <div className="promotion-items">{ getItemsContainer() }</div>
          </div>
        ) : null }
      </div>
    );
  };

  return (
    <div
      className={
        activeInstantGameData
          ? 'promotion-block instant-view'
          : 'promotion-block'
      }
    >
      { promotionBlockContent() }
      <TennisContext.Provider value={ instantsStore }>
        { getInstantGamesContainer() }
      </TennisContext.Provider>
    </div>
  );
}

export default memo(IsLoadingHOC(PromotionBlock));
