import React, { memo, useState, useContext, useEffect } from "react";
import "./TennisInstantGame.scss";
import PlayNow from "./Scenes/PlayNow/PlayNow";
import Match from "./Scenes/Match/Match";
import VideoScene from "./Scenes/VideoScene/VideoScene";
import { TennisContext } from "../../../components/PromotionBlock/PromotionBlock";
import Bets from "./Scenes/Bets/Bets";
import EndMatchBets from "./Scenes/Bets/EndMatchBets/EndMatchBets";

function TennisInstantGame() {
  const tennisContext = useContext(TennisContext);
  const [betItems, setBetItems] = useState([]);
  const [selectedBetsId, setSelectedBetsId] = useState([]);
  const [showBetAmountError, setShowBetAmountError] = useState("");

  const imagesBaseURL =
    process.env.PUBLIC_URL + "./assets/instants/backgrounds/";

  useEffect(() => {
    if (tennisContext.isMatchEnded.get || tennisContext.isSkipScene.get) {
      setBetItems([]);
      setSelectedBetsId([]);
    }
  }, [tennisContext.isMatchEnded.get, tennisContext.isSkipScene.get]);

  const getActiveScene = () => {
    const scenes = {
      0: <PlayNow changeScene={tennisContext.activeScene.set} />,
      1: (
        <Match
          changeScene={tennisContext.activeScene.set}
          isSkipScene={tennisContext.isSkipScene.get}
          setSkipeScene={tennisContext.isSkipScene.set}
          setSelectedBetsId={setSelectedBetsId}
          betItems={betItems}
          selectedBetsId={selectedBetsId}
          setBetItems={setBetItems}
          setShowBetAmountError={setShowBetAmountError}
          showBetAmountError={showBetAmountError}
        />
      ),
      2: (
        <VideoScene
          changeScene={tennisContext.activeScene.set}
          setSkipeScene={tennisContext.isSkipScene.set}
          isSkipScene={tennisContext.isSkipScene.get}
        />
      ),
      3: <Bets changeScene={tennisContext.activeScene.set} />,
      4: <EndMatchBets />,
    };
    return scenes[tennisContext.activeScene.get] || null;
  };

  return (
    <div className="tennis-instant-game">
      <div className="bet-content-instant">{getActiveScene()}</div>
      <img className="background" src={imagesBaseURL + "tennis.jpg"} alt="" />
    </div>
  );
}

export default memo(TennisInstantGame);
