import React, {memo, useContext, useState} from 'react';
import './Results.scss';
import ResultsPopUp from "./ResultsPopUp";
import {useTranslation} from "react-i18next"
import Utils from "../../utils/Utils";
import {AppContext} from "../../App";

function ResultsBlock({gameName, spotsData}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);

  const [showPopup, setShowPopUp] = useState(false);
  const showResults = () => {
    setShowPopUp(true);
  }

  return <div className='results-block'>
    <div className='competition-name'>{gameName}</div>
    <button className='results-button' onClick={showResults}>{Utils.upperCase(t('results'), context.language.get)}</button>
    {showPopup ? <ResultsPopUp spotsData={spotsData} closePopup={() => setShowPopUp(false)}/> : null}
  </div>
}

export default memo(ResultsBlock);
