import { memo, useContext, useEffect, useState, useCallback } from "react";
import { TennisContext } from "../../../../../components/PromotionBlock/PromotionBlock";
import BetInformation from "./BetInformation/BetInformation";
import BetSlip from "./BetSlip/BetSlip";
import "./bets.scss";

const Bets = ({ changeScene }) => {
  const tennisContext = useContext(TennisContext);
  const [totalStake, setTotalStake] = useState(0);
  const [totalPossibleAmount, setTotalPossibleAmount] = useState(0);
  const currency = tennisContext.currency.get;

  const bettingAccounts = [
    {
      name: "BALANCE:",
      amount: tennisContext.balance.get,
    },
    {
      name: "TOTAL STAKES:",
      amount: totalStake,
    },
    {
      name: "POSSIBLE WINNING:",
      amount: totalPossibleAmount > 0 ? totalPossibleAmount : 0,
    },
  ];

  return (
    <>
      <div className="content">
        <BetInformation setTotalStake={setTotalStake} setTotalPossibleAmount={setTotalPossibleAmount}/>
      </div>
      <BetSlip>
        <>
          <div className="betting-accounts">
            {bettingAccounts.map((item, i) => (
              <div key={i}>
                <span className="name">{item.name}</span>
                <span className="amount">{`${item.amount} ${currency}`}</span>
              </div>
            ))}
          </div>
          <div className="buttons">
            <div className="button back" onClick={() => changeScene(1)}>
              BACK
            </div>
          </div>
        </>
      </BetSlip>
    </>
  );
};

export default memo(Bets);
