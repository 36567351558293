import React, {memo, useContext, useEffect, useMemo, useState} from 'react';
import './UpcomingGames.scss';
import {MARKET_DATA, UNSUBSCRIBE_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";

function UpcomingGames({marketDataParser, updateMarketData}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;

  const [activeTimeIndex, setActiveTimeIndex] = useState(0);
  const [stadiumName, setStadiumName] = useState('');
  const [gameTimesData, setGameTimesData] = useState([]);
  let className;

  useEffect(() => {
    let timeData;
    const timesData = [];
    const activeGameID = context.activeGameID.get;
    const activeSportData = context.sportsData.get.find(data => data.id === activeGameID);

    for (let key in activeSportData.game) {
      if (activeSportData.game.hasOwnProperty(key) && activeSportData.game[key]) {
        timeData = {};
        timeData.id = key;
        timeData.startTime = activeSportData.game[key]['start_ts'];
        timesData.push(timeData)
      }
    }

    setGameTimesData([...timesData]);
  }, [context.sportsData.get, context.activeGameID.get])

  useEffect(() => {
    setActiveTimeIndex(0);
  }, [context.activeGameID.get])

  useEffect(() => {
    if (!gameTimesData || !gameTimesData[activeTimeIndex]) {
      return;
    }

    if (MARKET_DATA.subID && UNSUBSCRIBE_DATA.params.subid !== MARKET_DATA.subID) {
      UNSUBSCRIBE_DATA.params.subid = MARKET_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    }

    const activeSportData = context.sportsData.get.find(data => data.id === context.activeGameID.get);
    const gameID = gameTimesData.find(data => data.startTime === gameTimesData[activeTimeIndex].startTime).id;

    MARKET_DATA.params.where.game.id = parseInt(gameID);
    MARKET_DATA.params.where.sport.id = parseInt(activeSportData.id);
    MARKET_DATA.params.where.region.id = parseInt(activeSportData.region_id);

    socket.send(JSON.stringify(MARKET_DATA));
    socket.addEventListener(RequestManager.MARKET_DATA_EVENT, response => initData(response.detail), {once: true});
    socket.addEventListener(RequestManager.AUTO_MARKET_DATA, response => updateMarketData(response.detail));
  }, [activeTimeIndex, gameTimesData, context.activeGameID.get]);

  const initData = response => {
    const marketData = response.data.data;
    const marketSubID = response.data['subid'];

    if (marketData) {
      marketData['event_time'] = timestampToTime(gameTimesData[activeTimeIndex].startTime);
      marketDataParser(marketData, marketSubID);

      let data = marketData['sport'];
      if (Object.keys(data).length) {
        data = data[Utils.firstKey(data)].region;
        data = data[Utils.firstKey(data)].competition;
        const stadium = data[Utils.firstKey(data)].name;
        setStadiumName(stadium);
      }
    }
  }

  const timestampToTime = timestamp => {
    return Utils.timestampToLocalTime(timestamp);
  }

  const getStadiumName = useMemo(() => <div className='stadium-name'>
    <p>{stadiumName}</p>
  </div>, [stadiumName])

  const getUpcomingGameContent = useMemo(() => {
    return <div className='upcoming-games-content'>
      <p>{Utils.upperCase(t('upcoming games'), context.language.get)}</p>
      <div className='upcoming-games-times'>
        {
          gameTimesData.map(({id, startTime}, index) => {
            if (gameTimesData[activeTimeIndex] && gameTimesData[activeTimeIndex].startTime) {
              className = startTime === gameTimesData[activeTimeIndex].startTime ? 'game-start-time active' : 'game-start-time';
              return <div className={className} key={index} onClick={() => setActiveTimeIndex(index)}>
                {timestampToTime(startTime)}
              </div>
            }
          })
        }
      </div>
    </div>
  }, [activeTimeIndex, context.sportsData.get, context.activeGameID.get, gameTimesData])

  return <div className='upcoming-games'>
    {getUpcomingGameContent}
    {getStadiumName}
  </div>
}

export default memo(UpcomingGames);