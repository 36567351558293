import React, {memo, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './VideoPlayer.scss';
import {AppContext} from "../../../App";
import Utils from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import ResultsBlock from "../../ResultsBlock/ResultsBlock";

function VideoPlayer({video, gameName, spotsData, loaded, isHD, changeQualityCallback, forceUpdate}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const tennisData = context.tennisData.get;
  const isTennis = context.activeGameID.get === 14;
  const [playerMuted, setPlayerMute] = useState(false);
  const [playerPaused, setPlayerPaused] = useState(false);

  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';
  const flagBaseURL = process.env.PUBLIC_URL + './assets/flags/';
  const imgBaseURL = process.env.PUBLIC_URL + './assets/tennis/';

  const preloader = () => loaded ? null : <div className='video-preloader'/>;
  const volumeIconURL = iconBaseURL + (playerMuted ? 'mute.png' : 'volume.png');
  const playPauseIconURL = iconBaseURL + (playerPaused ? 'vplay.png' : 'vpause.png');
  const playerClassName = loaded ? 'video-player loaded' : 'video-player loading';

  const volumeValue = Utils.getStorageData('volumeValue') || 0;
  const volumeBeforeMute = useRef(50);

  useEffect(() => {
    if (isTennis) {
      forceUpdate();
    }

    setVolume(volumeValue)
  }, [context.tennisUpdateState.get])

  const playStopVideo = () => {
    const player = video.current;
    const maxTime = 100000000000;

    if (player) {
      if (player.paused) {
        player.play();

        if (isTennis) {
          forceUpdate();
        } else {
          player.currentTime = maxTime;
        }
      } else {
        player.pause();
      }

      setPlayerPaused(player.paused);
    }
  }

  const setVolume = value => {
    Utils.writeStorage('volumeValue', parseInt(value));

    const player = video.current;
    value = value / 100;

    if (player) {
      player.volume = value;

      if (value === 0) {
        setPlayerMute(true);
      } else if (playerMuted) {
        setPlayerMute(false);
      }
    }
  }

  const muteUnmuteVideo = () => {
    const player = video.current;
    const volumeSlider = document.getElementsByClassName("video-volume-slider")[0];

    if (player) {
      if (playerMuted === false && volumeSlider.value) {
        volumeBeforeMute.current = volumeSlider.value;
      }

      const value = playerMuted ? volumeBeforeMute.current : 0;

      volumeSlider.value = value;
      setVolume(value);
    }
  }

  const resizePlayer = e => {
    const videoItem = document.getElementsByClassName("video-item")[0];

    if (videoItem.classList.contains("big")) {
      videoItem.classList.remove("big");
    } else {
      videoItem.classList.add("big");
    }
  }

  const changeQuality = e => {
    const value = e.currentTarget.textContent === '720' ? 0 : 1;
    changeQualityCallback(value);
  }

  const showControls = () => {
    const controls = document.getElementsByClassName("video-controls")[0];
    controls.classList.remove("hide");
  }

  const hideControls = () => {
    const controls = document.getElementsByClassName("video-controls")[0];
    controls.classList.add("hide");
  }

  const showHideQuality = forceHide => {
    const qualityControls = document.getElementsByClassName("video-quality")[0];

    if (forceHide === true || qualityControls.classList.contains("hide") === false) {
      qualityControls.classList.add("hide");
    } else {
      qualityControls.classList.remove("hide");
    }
  }

  const getVideoControls = () => {

    return <div className='video-controls hide'>
      <input className="video-volume-slider" type="range" min="0" max="100"
             defaultValue={volumeValue} step="1" onChange={e => setVolume(e.target.value)}/>
      <img className="control-icon play-pause" src={playPauseIconURL} onClick={playStopVideo} alt=''/>
      <img className="control-icon" src={volumeIconURL} onClick={muteUnmuteVideo} alt=''/>
      {isHD ? <img className="control-icon hd" src={iconBaseURL + 'hd.svg'} alt=''/> : null}
      <img className="control-icon resize" src={iconBaseURL + 'resize.svg'} onClick={resizePlayer} alt=''/>
      <div className='setting-container' onClick={showHideQuality}>
        <div className='video-quality hide'>
          <div className='quality-icon high-quality' onClick={changeQuality}>720</div>
          <img className="hd hd-720" src={iconBaseURL + 'hd.svg'} alt=''/>
          <div className='quality-icon low-quality' onClick={changeQuality}>360</div>
          <img className={`selected-quality ${isHD ? 'hd' : 'sd'}`} src={iconBaseURL + 'selected.svg'} alt=''/>
        </div>
        <img className="control-icon setting" src={iconBaseURL + 'setting.svg'} alt=''/>
      </div>
    </div>
  }

  const getTennisStartInfo = () => {
    const firstName1 = tennisData['Player1Name'].split(" ")[0];
    const firstName2 = tennisData['Player2Name'].split(" ")[0];

    return <div className='tennis-info'>
      <div className='start-info'>
        <div className='players-block'>
          <div className="p-block left">
            <img src={imgBaseURL + 'left/' + firstName1.toLowerCase() + '_left.png?' + Date.now()} alt={''}/>
            <div className='p-name left'>
              <p>{tennisData['Player1Name']}</p>
            </div>
          </div>
          <div className="p-block right">
            <img src={imgBaseURL + 'right/' + firstName2.toLowerCase() + '_right.png?' + Date.now()} alt={''}/>
            <div className='p-name right'>
              <p>{tennisData['Player2Name']}</p>
            </div>
          </div>
        </div>
        <div className='game-info'>
          <div className="p-info p1">
            <div className='p-info-list'>
              <p>{tennisData['Markets'][2]['Player1Price']}</p>
              <p>{tennisData['Markets'][1]['Player1Price']}</p>
              <p>{tennisData['Markets'][0]['Player1Price']}</p>
              <p>{tennisData['Player1Results']}</p>
            </div>
          </div>
          <div className='match-info'>
            <p>{tennisData['Markets'][2]['Name']}</p>
            <p>{tennisData['Markets'][1]['Name']}</p>
            <p>{tennisData['Markets'][0]['Name']}</p>
            <p>Last 5 Matches</p>
          </div>
          <div className="p-info p2">
            <div className='p-info-list'>
              <p>{tennisData['Markets'][2]['Player2Price']}</p>
              <p>{tennisData['Markets'][1]['Player2Price']}</p>
              <p>{tennisData['Markets'][0]['Player2Price']}</p>
              <p>{tennisData['Player2Results']}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  const getTennisProgressInfo = () => {
    const maxScore = 40;
    const showCutOut = context.tennisUpdateState.get === 13;
    let gameScoreHome = tennisData['GameScore']['Home'] + tennisData['TieBreakScore']['Home'];
    let gameScoreAway = tennisData['GameScore']['Away'] + tennisData['TieBreakScore']['Away'];

    if (gameScoreHome > maxScore) {
      gameScoreHome = 'AD';
      gameScoreAway = '';
    } else if (gameScoreAway > maxScore) {
      gameScoreAway = 'AD';
      gameScoreHome = '';
    }

    const getBallIcon = showBall => {
      return showBall ? <div className='ball-icon'/> : null
    }

    return <div className='tennis-info'>
      <div className='process-info'>
        <div className='scores-info'>
          <div className="players-scores p1">
            <div className="player-name">{tennisData['Player1Name'].split(' ')[0]}</div>
            <div className='score ball'>
              {getBallIcon(tennisData['Player'] === 0)}
            </div>
            <div className='game-score-info'>
              <div className='score match-score'>{tennisData['MatchScore']['Home']}</div>
              <div className='score set-score'>{tennisData['SetScore']['Home']}</div>
              <div className='score game-score'>{gameScoreHome}</div>
            </div>
          </div>
          <div className="players-scores p2">
            <div className="player-name">{tennisData['Player2Name'].split(' ')[0]}</div>
            <div className='score ball'>
              {getBallIcon(tennisData['Player'] === 1)}
            </div>
            <div className='game-score-info'>
              <div className='score match-score'>{tennisData['MatchScore']['Away']}</div>
              <div className='score set-score'>{tennisData['SetScore']['Away']}</div>
              <div className='score game-score'>{gameScoreAway}</div>
            </div>
          </div>
        </div>
      </div>
      {
        showCutOut ? <div className='cat-out'>
          <div className="cat-out-container">
            {Utils.upperCase(t('place your bets'), context.language.get)}
          </div>
        </div> : null
      }
    </div>
  }

  const getPlayerShortName = name => {
    const sepName = name.split(" ");

    return sepName[0][0] + '. ' + sepName[1].substring(0, 3);
  }

  const getTennisFinalInfo = () => {
    const winIconURL = tennisData['Type'] === 4 ? '' : process.env.PUBLIC_URL + './assets/tennis/win.png';

    return <div className='tennis-info'>
      <div className='final-info'>
        <div className='player1-info'>
          <img className='player-img' alt=''
               src={imgBaseURL + tennisData['Player1Name'].split(' ')[0].toLowerCase() + '.png'}/>
          <div className='name-block left'>
            {tennisData['Winner'] === 0 ? <img alt='' className='win-icon l' src={winIconURL}/> : null}
            <p>{tennisData['Player1Name']}</p>
            <img className='flag' src={flagBaseURL + tennisData['Player1Flag'].toLowerCase() + '.png'} alt=''/>
          </div>
        </div>
        <div className='match-result'>
          <div className='container'>
            <div className="header">
              <div className='list'>
                {
                  tennisData['SetScores'].map((score, i) => i === 0 ? <p key={i}>&nbsp;</p> : <p key={i}>{i}</p>)
                }
              </div>
            </div>
            <div className="match-result-content">
              <div className='scores-block'>
                <img className='flag' src={flagBaseURL + tennisData['Player1Flag'].toLowerCase() + '.png'} alt=''/>
                <p className='player-name'>{getPlayerShortName(tennisData['Player1Name'])}</p>
                <div className='scores-list first'>
                  {
                    tennisData['SetScores'].map((scoreInfo, i) => {
                      return <p className={i === 0 ? 'result-score' : ''} key={i}>{scoreInfo['Home']}</p>
                    })
                  }
                </div>
              </div>
              <div className='scores-block'>
                <img className='flag' src={flagBaseURL + tennisData['Player2Flag'].toLowerCase() + '.png'} alt=''/>
                <p className='player-name'>{getPlayerShortName(tennisData['Player2Name'])}</p>
                <div className='scores-list'>
                  {
                    tennisData['SetScores'].map((scoreInfo, i) => {
                      return <p className={i === 0 ? 'result-score' : ''} key={i}>{scoreInfo['Away']}</p>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='player2-info'>
          <img className='player-img' alt=''
               src={imgBaseURL + tennisData['Player2Name'].split(' ')[0].toLowerCase() + '.png'}/>
          <div className='name-block right'>
            {tennisData['Winner'] === 1 ? <img alt='' className='win-icon r' src={winIconURL}/> : null}
            <img className='flag' src={flagBaseURL + tennisData['Player2Flag'].toLowerCase() + '.png'} alt=''/>
            <p>{tennisData['Player2Name']}</p>
          </div>
        </div>
      </div>
    </div>
  }

  const getTennisInfo = useMemo(() => {
    if (!isTennis || !tennisData['Type'] || !loaded) {
      return null;
    }

    if (tennisData['Type'] === 1) {
      return getTennisStartInfo();
    } else if (tennisData['Type'] === 2) {
      return getTennisProgressInfo();
    } else if (tennisData['Type'] === 4) {
      return getTennisFinalInfo();
    }
  }, [isTennis, loaded, tennisData])

  return <div className="video-item" onMouseLeave={() => showHideQuality(true)}>
    <div className="player-container" onMouseOver={showControls} onMouseLeave={hideControls}>
      <video className={playerClassName} ref={video} src={''} autoPlay={!playerPaused}/>
      {getVideoControls()}
    </div>
    {preloader()}
    {getTennisInfo}
    <ResultsBlock gameName={gameName} spotsData={spotsData}/>
  </div>
}

export default memo(VideoPlayer);