import React, {memo, useState} from 'react';
import './HistoryBetItem.scss';
import {useTranslation} from "react-i18next";
import Utils from "../../../../utils/Utils";
import ReactTooltip from 'react-tooltip';

function HistoryBetItem({itemData, betTypes, oddType, isTaxCase}) {
  const {t} = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const outcomes = ['Accepted', 'Lost', 'Return', 'Won', '', 'Cashed-out', 'WinReturn', 'LoseReturn', 'Accepted'];
  const iconsBaseURL = process.env.PUBLIC_URL + './assets/icons/';

  const getOutCome = value => {
    const outComes = ['accepted', 'lost', 'return', 'win', '', 'cashouted', 'winReturn', 'loseReturn'];
    const index = outComes[parseInt(value)] ? parseInt(value) : 0;

    return outComes[index];
  }

  const getMoreInfoBlock = () => {
    if (!isOpened) {
      return null;
    }

    const events = itemData['events'];
    let splitName;

    return events.map((event, i) => {
      splitName = event['sport_name'].split(' ');

      return <div className='bet-item-more-info' key={i}>
        <div className="data-id bet-info-item more">
          <p className="date-and-time">
            {Utils.timestampToLocalTime(event['game_start_date'], false, 'YYYY-MM-DD HH:mm')}
            <span>{t('matchID')}: {event.game_id} </span>
          </p>
        </div>
        <div className="bet-type bet-info-item">
          <div className="status-icon"><p>{splitName[0][0] + (splitName[1] ? splitName[1][0] : splitName[0][1])}</p>
          </div>
          <div className='game-info'>
            <p className='bet-type-name'>{event['game_name']}</p>
            <p className='bet-market-name'>{event['market_name']}</p>
          </div>
        </div>
        <div className='bet-info-item event-info'>
          <p className='bet-event-info'>{t('event')}</p>
          <p className='bet-event-info name'>{event['event_name']}</p>
          <img className='info-icon' src={iconsBaseURL + 'info.svg'} alt='info-icon' data-tip={event['game_info']}/>
          <ReactTooltip/>
        </div>
        <div className="bet-info-item coef-info more">
          <p>{t('odds') + ':  '}</p>
          <p>{Utils.getFormattedCoef(event['coeficient'], oddType)}</p>
        </div>
        <div className="bet-info-item outcome-info-more">
          <p>{t('outcome') + ': '}</p>
          <p className={getOutCome(event['outcome'])}>{outcomes[parseInt(event['outcome'])]}</p>
        </div>
      </div>
    })
  }

  const copyBetID = (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    navigator.clipboard.writeText(id);
  }

  const getTaxInfoIcon = () => {
    if (isTaxCase === false || itemData['tax_amount'] === 0 || itemData['payout'] === 0) {
      return null;
    }


    const amountTP = (itemData['payout'] + itemData['tax_amount']).toFixed(2);
    const dataTip = amountTP.toString() + ' - ' + itemData['tax_amount'].toString() + ' = ' + itemData['payout'];
    return <img className='info-icon' src={iconsBaseURL + 'info.svg'} alt='info-icon' data-tip={dataTip}/>
  }

  const getItemContent = () => {
    const outcome = outcomes[parseInt(itemData['outcome'])];
    const possibleWin = outcome === 'Accepted' ? '-' : itemData['payout'] + ' ' + itemData['currency'];

    const systemCoef = itemData['type'] === 3 &&  Utils.getBinomalCoef(itemData['system_min_count'], itemData['events'].length);
    const betOptionName =itemData['type'] === 3 ?  `${itemData['system_min_count']}/${itemData['events'].length} ( ${systemCoef} opt. )` : ''

    return <div className='history-bet-item-container'>
      <div className="history-bet-item" onClick={() => setIsOpened(!isOpened)}>
        <div className="data-id bet-info-item">
          <p className="date-and-time">
            {Utils.timestampToLocalTime(itemData['date_time'], false, 'YYYY-MM-DD HH:mm')}
          </p>
          <div className="id-container">
            <div className="id-block">
              <p className="id">ID: {itemData['id']}</p>
              <img className='copy-icon' src={iconsBaseURL + 'copy.svg'} alt="" onClick={event => copyBetID(event, itemData['id'])}/>
            </div>
          </div>
        </div>
        <div className="bet-type bet-info-item">
          <div className="status-icon bet-info-item"><p>{itemData['events'].length}</p></div>
          <p className='bet-type-name'>{betTypes[itemData['type']] + ' ' + betOptionName}</p>
        </div>
        <div className="bet-stake bet-info-item">{itemData['amount'] + ' ' + itemData['currency']}</div>
        <div className="odds bet-info-item">{Utils.getFormattedCoef(itemData['k'], oddType)}</div>
        <div className="outcome bet-info-item">
          {possibleWin}
          {getTaxInfoIcon()}
          <ReactTooltip/>
        </div>
        <div className="bet-status bet-info-item">
          <p className={getOutCome(itemData['outcome'])}>{outcome}</p>
        </div>
        <div className="up-down-icon bet-info-item">
          <i className={isOpened ? 'arrow-up' : 'arrow-down'}/>
        </div>
      </div>
      {getMoreInfoBlock()}
    </div>
  }

  return getItemContent();
}

export default memo(HistoryBetItem);