import { memo } from "react/cjs/react.production.min";
import "./popUp.scss";

const PopUp = ({ children, show, callback, classN = "" }) => {
  return (
    <>
      {show ? (
        <div className="pop-up">
          <div className={`pop-up-body ${classN}`}>
            {children}{" "}
            {callback ? (
              <span className="close-button" onClick={callback}>
                ✖
              </span>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(PopUp);
