import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next"
import {AppContext} from "../../../App"
import {PLACE_BETS_DATA, RE_CAPTCHA_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";

function QuickBetBlock({clearBetsCallback, isActive}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const partnerConfig = context.partnerConfig.get;
  const socket = RequestManager.getInstance().webSocket;
  const betsData = context.betsData.get;

  const [minBetCase, setMinBetCase] = useState(false);
  const [placeProcess, setPlaceProcess] = useState(false);
  const [betResult, setBetResult] = useState(null);
  const [betValue, setBetValue] = useState('');
  const [stakeEditState, setStakeEditState] = useState(false);
  const stakeRef = useRef();

  const currency = context.currency.get;
  const minBetValue = partnerConfig['min_bet_stakes'][currency];

  useEffect(() => {
    document.addEventListener("keydown", handleEnterSave);

    return () => {
      document.removeEventListener("keydown", handleEnterSave);
    }
  }, [stakeEditState])

  useEffect(() => {
    if (isActive) {
      stakeRef.current.focus();  
      if(!betValue) {
        context.disableMarket.set(true)
      }
    } else {
      stakeRef.current.value = '';
      setStakeEditState(false);
      setBetValue('');
      context.disableMarket.set(false)
    }
  }, [isActive])
  
  useEffect(() => {
    if (betValue && betsData.length && !minBetCase) {
      prepareToBet();
    }
  }, [context.betsData.get])

  const handleEnterSave = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      saveStake();
    }
  }

  const prepareToBet = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      initReCaptchaScript();
    } else {
      initPlaceBetsRequest(betValue);
    }
  }

  const initReCaptchaScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + context.reCaptchaData.get.siteKey; 
    script.addEventListener("load", handleReCaptchaScriptLoaded, {once: true});

    document.body.appendChild(script);
  }

  const handleReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, {action: "do_bet"}).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token)
    })

    RE_CAPTCHA_DATA.rid = Utils.getUniqNumber().toString();

    socket.send(JSON.stringify(RE_CAPTCHA_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_EVENT, handleReCaptchaResponse, {once: true});
  }

  const handleReCaptchaResponse = ({detail}) => {
    // TODO needs to check response status.
    initPlaceBetsRequest(betValue);
  }

  const initPlaceBetsRequest = betValue => {
    if (!context.userLoggedIn.get) {
      return;
    }

    const betsData = context.betsData.get;

    PLACE_BETS_DATA.rid = Utils.getUniqNumber().toString();
    PLACE_BETS_DATA.params.type = 1;
    PLACE_BETS_DATA.params.amount = betValue;
    PLACE_BETS_DATA.params.bets = betsData.map(item => {
      if (item['status'] === 'success') {
        return {event_id: item.id, price: item.price};
      }
    });

    setBetResult(t('bet in progress'));
    socket.send(JSON.stringify(PLACE_BETS_DATA));
    socket.addEventListener(RequestManager.PLACE_BETS_EVENT,
      response => handlePlaceBetsResponse(response.detail), {once: true});
  }

  const handlePlaceBetsResponse = ({data}) => {
    const successfulBet = data.result && data.result === "OK";
    const resultText = data.result_text;

    if (successfulBet) {
      setBetResult(t('bet accepted'));
      setPlaceProcess(false);

      context.activeBets.set([]);
    } else {
      setBetResult(t(resultText));
    }

    clearBetsCallback();
  }

  const checkBetValue = value => {
    value = Number(value);

    if (value < minBetValue) {
      setMinBetCase(true);
    } else {
      setMinBetCase(false);
    }
  }

  const getWarningPanel = () => {
    if (!minBetCase) {
      return null;
    }

    const warningMessage = `${minBetCase && t('min bet case') + " (" + minBetValue + currency + ")"}`;
    return <div className='quick-bet-info'>{warningMessage}</div>
  }

  const getPlaceBetProcessingBlock = () => {
    if (!placeProcess) {
      return null;
    }

    return <div className='place-bet-process'>
      <div className='loader'/>
    </div>
  }

  const getBetResultBlock = () => {
    if (betResult === null) {
      return null;
    }

    setTimeout(() => setBetResult(null), 4000);

    let className;
    if (betResult === t('bet in progress')) {
      className = 'place-bet-results progress';
    } else if (betResult === t('bet accepted')) {
      className = 'place-bet-results success';
    } else if (betResult === t('bet failed') || betResult === t('BetAmountError')) {
      className = 'place-bet-results fail';
    }

    return <div className={className}>{betResult}</div>
  }

  const saveStake = () => {
    if (stakeRef.current.value) {
      setStakeEditState(true);
      setBetValue(stakeRef.current.value);
      context.disableMarket.set(false)
    }
  }

  return <div className={isActive ? 'quick-bet-content' : 'quick-bet-content hide'}>
    <div className='quick-bet-info'>{t('quick bets about')}</div>
    <div className="stake-container">
      <input className={stakeEditState ? 'stake-input saved' : 'stake-input'} maxLength={12}
             type="text" id="stake" placeholder={t('stake')} min="0" ref={stakeRef}
             onKeyDown={Utils.blockInvalidNumbers} onPaste={e => Utils.blockInvalidPastedNumbers(e)}
             onKeyUp={e => checkBetValue(e.target.value)} onChange={Utils.correctBetInputValue}
             disabled={stakeEditState ? 'disabled' : ''}/>
      {
        stakeEditState === false
          ? <div className="save-stake" onClick={saveStake}>{t('save')}</div>
          : <div className="edit-stake" onClick={() => {
              setStakeEditState(false); 
              setBetValue(''); 
              context.disableMarket.set(true)}}>{t('edit')}</div>
      }
    </div>
    {getWarningPanel()}
    {getPlaceBetProcessingBlock()}
    {getBetResultBlock()}
  </div>
}

export default memo(QuickBetBlock);