import React from 'react';
import './MatchdayBlock.scss';
import {useTranslation} from 'react-i18next';

function MatchdayBlock({changeRoundCallback, activeRoundIndex, rounds}) {
  const {t} = useTranslation();
  const sortedData = rounds.sort((a, b) => a - b);

  let buttonClassName;

  return <div className="select-matchday">
    <div className='matchday-container'>
      <p>{t('select day')}</p>
      <div className='matchday-list'>
        {
          sortedData.map((item, index) => {
            buttonClassName = index === activeRoundIndex ? 'matchday-button active-round' : 'matchday-button';

            return <div className={buttonClassName} key={index} onClick={() => changeRoundCallback(index)}>{item}</div>
          })
        }
      </div>
    </div>
    <div className='stadium-name'/>
  </div>
}

export default MatchdayBlock;