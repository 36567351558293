import React, {useContext, useState, memo} from 'react';
import Utils from "../../../../utils/Utils"
import {AppContext} from "../../../../App"
import { LOGIN_DATA } from '../../../../services/RequestDatas';

function LargeMarketElement({elementData, selectedID, selectCallback, addRemoveBookmarksCallback, marketDisabled}) {
  const context = useContext(AppContext);
  const marketListData = elementData['event'];
  const elementKeys = Object.keys(marketListData);
  const isMarketDisabled = marketDisabled || context.disableMarket.get;

  const [isOpen, setIsOpen] = useState(true);

  const openCloseElement = () => {
    setIsOpen(!isOpen);
  }

  const getInfoItemStyle = (isLastItem, typesCount) => {
    const multiTypes = typesCount > 5;
    const count = multiTypes ? 2 : typesCount;
    const percentValue = (isLastItem && typesCount > 5 && elementKeys.length % 2 === 1) ? 100 : 100 / count;

    return `0 1 calc(${percentValue}% - 1.2px)`;
  }

  const addRemoveToBookmarks = event => {
    event.preventDefault();
    event.stopPropagation();

    addRemoveBookmarksCallback(elementData['market_type']);
  }

  const getFormattedPrice = price => {
    return Utils.getFormattedCoef(price, context.oddType.get);
  }

  const getCashOutIcon = () => {
    return elementData['cashout'] ? <div className="cash-out-icon">&#36;</div> : null;
  }

  const getMarketItems = () => {
    // Get all types from data.
    const types = [];
    elementKeys.map(key => {
      if (types.indexOf(marketListData[key]['type_1']) === -1) {
        types.push(marketListData[key]['type_1'])
      }
    });

    return elementKeys.map((key, index) => {
      const currentListItem = marketListData[key];

      const price = getFormattedPrice(currentListItem['price']);
      const isLastItem = index === elementKeys.length - 1;
      const betType = elementData.name;
      const activebets = context.betsData.get;
      const isActiveItem = activebets.find(el => el.id === currentListItem.id);

      let className = 'large-market-info-item list-info-item price';
      className += isActiveItem ? ' active' : '';

      return <div className={className} style={{flex: getInfoItemStyle(isLastItem, types.length)}} key={index}
                  onClick={() => selectCallback(currentListItem, betType, isActiveItem)}>
        <p className='large-market-info-item-type'>{currentListItem.name} {currentListItem.base}</p>
        <p className='large-market-info-item-price'>{price}</p>
      </div>
    })
  }

  const getElementOptions = () => {
    const userbookmarkedMarkets = context.bookmarks.get[LOGIN_DATA.params.user_id]
    const bookmarkedMarkets = userbookmarkedMarkets && userbookmarkedMarkets[context.activeGameID.get];
    const isBookmarked = bookmarkedMarkets && bookmarkedMarkets.includes(elementData['market_type']);
    const bookmarkClasses = isBookmarked ? 'star-bookmark bookmarked' : 'star-bookmark';

    return <div className='market-element-options'>
      {getCashOutIcon()}
      { context.userLoggedIn.get ?
        <span className={bookmarkClasses} onClick={addRemoveToBookmarks}>&#9733;</span>
      : ''}
      <span className='market-item-open-close-arrow'>
        <p className={isOpen ? 'up' : 'down'}>&#10094;</p>
      </span>
    </div>
  }

  return <div className={`large-market-element ${isMarketDisabled ? 'disable' : ''}`}>
    <div className='large-market-element-item list-title' onClick={openCloseElement}>
      {elementData.name}
      {getElementOptions()}
    </div>
    <div className={isOpen ? 'large-market-element-item-prices' : 'large-market-element-item-prices closed'}>
      {getMarketItems()}
    </div>
  </div>
}

export default memo(LargeMarketElement);