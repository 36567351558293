import React, {memo} from 'react';
import './LiveFightInfo.scss';

function LiveFightInfo({fightData}) {
  const flagsBaseURL = process.env.PUBLIC_URL + './assets/flags/';

  const getFormattedName = name => {
    if (!name) {
      return null
    }

    const splitName = name.split(" ");
    return splitName.length > 1 ? splitName[0][0] + '. ' + splitName[1].toUpperCase() : '';
  }

  const getPlayerBlock = index => {
    const country = fightData[`team${index}_region`];
    const flagURL = flagsBaseURL + country + '.png';

    return <div className={'fight-player-block'}>
      {index === 1 ? <img className='flag-img' src={flagURL} alt=''/> : null}
      <p className='name'>{getFormattedName(fightData[`team${index}_name`]).toUpperCase()}</p>
      {index === 2 ? <img className='flag-img' src={flagURL} alt=''/> : null}
    </div>
  }

  const getScoreInfo = index => {
    if (fightData['homescore'] === -1 || fightData['awayscore'] === -1) {
      return '';
    }

    const className = index === 1 ? 'left' : 'right';

    return <div className={'scores-info ' + className}>
      <div className={'score ' + className}>{index === 1 ? fightData['homescore'] : fightData['awayscore']}</div>
    </div>
  }

  const getKGBlock = () => {
    return <div className='kg-block'>
      <div className="kg-value">{fightData['weight'] + ' KG'}</div>
    </div>
  }

  return fightData ? <div className='live-fight-info'>
    <div className='live'>LIVE</div>
    <div className='players-info'>
      {getPlayerBlock(1)}
      {getScoreInfo(1)}
      {getKGBlock()}
      {getScoreInfo(2)}
      {getPlayerBlock(2)}
    </div>
  </div> : null;
}

export default memo(LiveFightInfo);