import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import CrossfadeImage from "react-crossfade-image";
import {AppContext} from "../../App";
import './Slider.scss';
import Utils from "../../utils/Utils";

function Slider({clickHandler}) {
  const [sliderData, setSliderData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const context = useContext(AppContext);
  const sliderTimeout = useRef();

  const gamesIDsData = [
    {gameID: 1, id: 173, vid: 9},   // Virtual Football Pro
    {gameID: 2, id: 174, vid: 10},  // The Penalty Kicks
    {gameID: 3, id: 54, vid: 13},   // Virtual Horse Racing
    {gameID: 4, id: 55, vid: 11},   // Virtual Greyhounds Racing
    {gameID: 6, id: 150, vid: 15},  // Virtual Bicycle
    {gameID: 7, id: 188, vid: 16},  // Virtual Football League
    {gameID: 9, id: 209, vid: 21},  // Virtual Drag Racing
    {gameID: 12, id: 118, vid: 20}, // Virtual Marble Racing
    {gameID: 14, id: 1, vid: 23},   // Virtual Tennis
  ];

  useEffect(() => {
    if (sliderData.length) {
      sliderTimeout.current = setTimeout(() => changeSlideItem(activeIndex + 1), 4000);
    } else {
      prepareToGetBannersData();
    }

    return () => clearTimeout(sliderTimeout.current);
  }, [sliderData, activeIndex, context.siteID.get]);

  useEffect(() => {
    setSliderData([]);
  }, [context.language.get])

  const prepareToGetBannersData = () => {
    if (context.siteID.get) {
      const lang = context.language.get;
      const siteID = context.siteID.get;
      const platform = context.platform.get;
      let host = context.baseHost.get ? context.baseHost.get : 'vbet';

      // TODO yaxq!
      if (context.siteID.get === 1333) {
        host = 'www.grandcasino.by';
      }

      const dataURL = typeof platform === 'number'
        ? `https://cmsbetconstruct.com/api/public/v1/${lang}/partners/${siteID}/components/virtual_sport_banners/contents?use_webp=1&platform=${platform}`
        : `https://cmsbetconstruct.com/json?base_host=${siteID}&ssl=1&lang=${lang}&json=widgets/get_sidebar&sidebar_id=virtual-sports-${lang}`;

      fetch(dataURL)
        .then(response => response.json())
        .then(data => {
          if (data['widgets'] && data['widgets'].length) {
            initBannersData(data);
          } else if (data?.data) {
            initNewCMSBannersData(data?.data);
          } else {
            forceLoadVBetBanners();
          }
        })
        .catch(error => {
          forceLoadVBetBanners();
        })
    }
  }

  const forceLoadVBetBanners = () => {
    const lang = context.language.get;
    const VBetURL = `https://cmsbetconstruct.com/json?base_host=vbet&ssl=1&lang=${lang}&json=widgets/get_sidebar&sidebar_id=virtual-sports-${lang}`;

    fetch(VBetURL)
      .then(response => response.json())
      .then(data => {
        initBannersData(data)
      })
      .catch(error => console.log('>> VBET ERROR:', error))
  }

  const initNewCMSBannersData = data => {
    const dataArray = [];

    if (data) {
      data.forEach(item => {
        dataArray.push({link: item['href'], image: `https://cmsbetconstruct.com${item['src']}`})
      })
    }

    setSliderData(dataArray);
  }

  const initBannersData = data => {
    const dataArray = [];
    data = data['widgets'];

    if (data) {
      data.forEach(item => {
        if (Utils.IS_VBET === false && item['instance']['link'] && item['instance']['link'].indexOf('/casino') !== -1)
        {
          // TODO: temp fix for harmony promo.
        } else {
          // TODO: only for "alegrebet" partner (ID: 1876555).
          const isAlegrabetHorceBanner = context.siteID.get === 1876555 && item['instance']['link'].indexOf('?game=3') !== -1;
          if (isAlegrabetHorceBanner === false) {
            dataArray.push({link: item['instance']['link'], image: item['instance']['image']})
          }
        }
      })

      setSliderData(dataArray);
    }
  }

  const changeSlideItem = toNext => {
    setActiveIndex(getIndexByAction(toNext));
  }

  const getIndexByAction = toNext => {
    const maxIndex = sliderData.length - 1;
    let index = toNext ? activeIndex + 1 : activeIndex - 1;

    if (index < 0) {
      index = maxIndex;
    } else if (index > maxIndex) {
      index = 0;
    }

    return index;
  }

  const changeGame = link => {
    if (link && link.indexOf('game=') !== -1) {
      const url = new URL(link);
      const gameID = url.searchParams.get("game");

      if (gameID) {
        clickHandler(parseInt(gameID));
      }
    }
  }

  return sliderData.length ? <div className='slider'>
    <div className="slider-container">
      <div onClick={() => changeGame(sliderData[activeIndex]['link'])}>
        <CrossfadeImage className={'slide'} duration={1000} timingFunction={"linear"} alt=''
                        src={sliderData[activeIndex]['image']}/>
      </div>
      <div className="slider-buttons">
        <div className="previous slider-button" onClick={() => changeSlideItem(false)}>
          <i className='arrow left'/>
        </div>
        <div className="next slider-button" onClick={() => changeSlideItem(true)}>
          <i className='arrow right'/>
        </div>
      </div>
    </div>
  </div> : null;
}

export default memo(Slider);