import React, {useEffect, useContext, useState, memo, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../App";
import './MyBets.scss';
import {OPENED_BETS_DATA} from "../../../services/RequestDatas"
import RequestManager from "../../../services/RequestManager"
import MyBetItem from "./MyBetItem/MyBetItem";

function MyBets({showHistoryPopUp, showCashOutPopUp, updateBetsCount}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const [openedBets, setOpenedBets] = useState([]);
  const [update, updateState] = React.useState(false);
  const forceUpdate = () => updateState(!update);

  useEffect(() => {
    if (context.userLoggedIn.get) {
      getOpenedBets();
    }
  }, [context.userLoggedIn.get, update]);

  useEffect(() => {
    if (openedBets.length) {
      socket.addEventListener(RequestManager.AUTO_GAME_DATA, checkMyBets);
      socket.addEventListener(RequestManager.AUTO_TENNIS_MARKET_UPDATE, handleTennisMarketUpdate);
      socket.addEventListener(RequestManager.CASH_OUT_EVENT, forceUpdate);
    }

    return () => {
      socket.removeEventListener(RequestManager.AUTO_GAME_DATA, checkMyBets);
      socket.removeEventListener(RequestManager.AUTO_TENNIS_MARKET_UPDATE, handleTennisMarketUpdate);
      socket.removeEventListener(RequestManager.CASH_OUT_EVENT, forceUpdate);
    }
  }, [openedBets])

  const handleTennisMarketUpdate = ({detail}) => {
    const openedBetsString = JSON.stringify(openedBets);
    const dataString = JSON.stringify(detail);

    if (openedBetsString.includes('sport_id":14') && dataString.includes('null')) {
      let needsToUpdate = false;

      openedBets.forEach(openBet => {
        openBet.events.forEach(event => {
          if (dataString.includes(event['selection_id'] + '":null')
            || dataString.includes(event['match_display_id'] + '":null')) {
            needsToUpdate = true;
          }
        })
      })

      if (needsToUpdate) {
        socket.addEventListener(RequestManager.AUTO_TENNIS_UPDATE, () => {
          setTimeout(forceUpdate, 3000)
        }, {once: true});
      }
    }
  }

  const checkMyBets = ({detail}) => {
    const dataString = JSON.stringify(detail);

    if (dataString.includes('null')) {
      let needsToUpdate = false;
      let isCompletePattern;

      openedBets.forEach(openBet => {
        openBet.events.forEach(event => {
          isCompletePattern = event['game_id'] + '":null';

          if (dataString.includes(isCompletePattern)) {
            needsToUpdate = true;
          }
        })
      })

      if (needsToUpdate) {
        forceUpdate();
      }
    }
  }

  const getOpenedBets = () => {
    socket.send(JSON.stringify(OPENED_BETS_DATA));
    socket.addEventListener(RequestManager.OPENED_BETS_EVENT, response => updateOpenedBets(response.detail), {once: true});
  }

  const updateOpenedBets = ({data}) => {
    if (data && data.bets) {
      setOpenedBets([...data.bets]);
      updateBetsCount(data.bets.length);
    }
  }

  const getActiveBets = useMemo(() => {
    return openedBets.length
      ? openedBets.map((betData, i) => <MyBetItem betData={betData} key={i} showCashOutPopUp={showCashOutPopUp}/>)
      : null;
  }, [openedBets])

  return context.userLoggedIn.get ? <div className='bet-history-container'>
    <div className='view-history-hint'>
      {t('view all bets')} <br/>
      <b onClick={() => showHistoryPopUp()}>{t('bet history')}</b>
    </div>
    {getActiveBets}
  </div> : null;
}

export default memo(MyBets);