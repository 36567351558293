import moment from "moment-timezone";
import ThemeManager, {BBIN_THEME_DATA} from "../theme/ThemeManager";
import HLS from "hls.js";

let hls;
export default class Utils {
  static DEV_MODE = process.env.NODE_ENV === "development";
  static STAGING_MODE = window.document.domain.indexOf("vs-stage") !== -1;

  static IS_BBIN = document.referrer.indexOf("betcoapps") !== -1;
  static IS_VBET = document.referrer.indexOf("vbet") !== -1 && document.referrer.indexOf(".lat") === -1;

  static getUniqNumber() {
    return Date.now() * Math.floor(Math.random() * 100) * Math.random();
  }

  /**
   *
   * @param timestamp
   * @returns {string} time in "10:30:23" format
   */
  static timestampToUTC(timestamp) {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();

    return hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  }

  /**
   *
   * @param timestamp
   * @param bySeconds format of result
   * @param hardFormat ignore bySeconds param and hard set param value.
   */
  static timestampToLocalTime(timestamp, bySeconds, hardFormat) {
    const offset = new Date(timestamp).getTimezoneOffset();
    const utcString = new Date(timestamp * 1000 - offset).toUTCString();
    const format = hardFormat ? hardFormat : bySeconds ? "HH:mm:ss" : "HH:mm";

    return moment.utc(utcString).local().format(format);
  }

  /**
   * @returns object first key
   */
  static firstKey(object) {
    return object ? Object.keys(object)[0] : "";
  }

  static toFractionalFormat = (amount) => {
    amount = amount.toFixed(2);

    const gcd = (a, b) => {
      return b < 0.0000001 ? a : gcd(b, Math.floor(a % b));
    };

    const len = amount.toString().length - 2;
    let denominator = Math.pow(10, len);
    let numerator = amount * denominator;
    const divisor = gcd(numerator, denominator);

    numerator /= divisor;
    denominator /= divisor;
    amount = Math.floor(numerator) + "/" + Math.floor(denominator);

    return amount;
  };

  /**
   * @param coef coefficient value.
   * @param oddType coefficient type.
   */
  static getFormattedCoef = (coef, oddType) => {
    if (oddType === 0) return coef;

    let formattedCoef = coef;
    oddType = Number(oddType);

    switch (oddType) {
      case 1:
        formattedCoef = this.toFractionalFormat(formattedCoef - 1);
        return formattedCoef;
      case 2:
        formattedCoef =
          (coef >= 2 ? "+" : "-") +
          Math.floor(coef >= 2 ? (coef - 1) * 100 : -100 / (coef - 1));
        break;
      case 3:
        formattedCoef = coef - 1;
        break;
      case 4:
        formattedCoef =
          (coef >= 2 ? "" : "-") + (coef >= 2 ? -1 / (coef - 1) : coef - 1);
        break;
      case 5:
        formattedCoef =
          (coef >= 2 ? "" : "-") + (coef >= 2 ? coef - 1 : 1 / (coef - 1));
        break;
      default:
    }

    formattedCoef = formattedCoef.toString();
    const cropIndex =
      formattedCoef.indexOf(".") > 0 ? formattedCoef.indexOf(".") + 3 : 5;

    return formattedCoef.substring(0, cropIndex);
  };

  /**
   * Write data to local storage by parameters.
   * @param key local storage key.
   * @param data local storage data.
   * @param expirationSeconds local storage data expiration time by seconds.
   */
  static writeStorage = (key, data, expirationSeconds) => {
    try {
      localStorage.setItem(key, JSON.stringify(data));

      if (expirationSeconds) {
        const date = new Date();
        const schedule = Math.round(
          date.setSeconds(date.getSeconds() + expirationSeconds) / 1000
        );

        localStorage.setItem(key + "_expiration", JSON.stringify(schedule));
      }
    } catch {
      console.log("ERROR: Write to local storage by key:", key);
    }
  };

  static getStorageData = (key, widthExpiration) => {
    try {
      if (widthExpiration) {
        const currentTime = Math.round(Date.now() / 1000);
        const storedTime = parseInt(localStorage.getItem(key + "_expiration"));

        if (storedTime && currentTime > storedTime) {
          localStorage.setItem(key, null);
          return [];
        }
      }

      return JSON.parse(localStorage.getItem(key));
    } catch {
      console.log("ERROR: Get from local storage by key:", key);
    }
  };

  static blockInvalidNumbers = (e, blockDot) => {
    const pattern = blockDot ? ["e", "E", "+", "-", "."] : ["e", "E", "+", "-"];

    if (pattern.includes(e.key)) {
      e.preventDefault();
    }
  };

  static correctNumericInputValue = event => {
    let inputValue = event.currentTarget.value;
    const regExp = new RegExp(/^\d+$/);

    if (regExp.test(inputValue) === false) {
      event.currentTarget.value = inputValue.slice(0, inputValue.length - 1);
      inputValue = event.currentTarget.value;
    }

    event.currentTarget.value = inputValue;
  }

  static correctBetInputValue = event => {
    let inputValue = event.currentTarget.value;

    if (inputValue === '.' || inputValue === '00' || inputValue === '0..' || inputValue === '0.') {
      event.currentTarget.value = '0.'
    } else {
      const regExp = new RegExp('^([0.01-9]{1,12})$');

      if (inputValue !== '0' && regExp.test(inputValue) === false) {
        event.currentTarget.value = inputValue.slice(0, inputValue - 1);
        inputValue = event.currentTarget.value;
      }

      const pointIndex = inputValue.indexOf(".");

      if (pointIndex !== -1 && inputValue.length > pointIndex + 3) {
        event.currentTarget.value = inputValue.slice(0, pointIndex + 3);
      }
    }
  }

  static blockInvalidPastedNumbers = (e, blockDot) => {
    const cbValue = e.clipboardData.getData("Text");
    if (
      (blockDot && cbValue.includes(".")) ||
      cbValue.includes("E") ||
      cbValue.includes("e") ||
      cbValue.includes("+") ||
      cbValue.includes("-")
    ) {
      e.preventDefault();
    }
  };

  /**
   * Apply toUpperCase native method, if the language has capital letters.
   * @activeLanguage the application current language.
   */
  static upperCase = (word, activeLanguage) => {
    const notHaveCapitalList = ["geo"];
    const haveCapitalLetters =
      notHaveCapitalList.indexOf(activeLanguage) === -1;

    return haveCapitalLetters ? word.toUpperCase() : word;
  };

  static getBinomalCoef = (m, n) => {
    const mFact = Utils.factorial(m);
    const nFact = Utils.factorial(n);
    const minusFact = Utils.factorial(n - m);
    const temp = mFact * minusFact;

    return nFact / temp;
  };

  static getSubs = (arr, m) => {
    if (arr.length < 3) return [];
    
    const n = arr.length;
    const a = [];
    const result = [];

    for (let i = 0; i < m; i++) {
      a[i] = i + 1;
    }

    let p = m;
    while (p >= 0) {
      let r = 1;
      for (let j = 0; j < a.length; j++) {
        r = r * arr[a[j] - 1];
      }

      result.push(r);
      p = a[m - 1] === n ? p - 1 : m - 1;

      if (p >= 0) {
        for (let i = m - 1; i >= p; i--) {
          a[i] = a[p] + i - p + 1;
        }
      }
    }

    return result;
  };

  static factorial = (n) => {
    return n !== 1 ? n * Utils.factorial(n - 1) : 1;
  };

  static applyApplicationTheme = (themeData) => {
    if (Utils.IS_BBIN) {
      ThemeManager.applyTheme(BBIN_THEME_DATA);
    } else if (themeData) {
      ThemeManager.applyTheme({
        mainBG: themeData["background-color"],
        color0: themeData["color0"],
        color1: themeData["color1"],
        color2: themeData["color2"],
        color3: themeData["color3"],
        color4: themeData["color4"],
        color5: themeData["color5"],
        color6: themeData["color6"],
        odd: themeData["odd"],
        odd_hover: themeData["odd_hover"],
        odd_active: themeData["odd_active"],
        selection_name: themeData["selection_name"],
        selection_name_hover: themeData["selection_name_hover"],
        selection_name_active: themeData["selection_name_active"],
        listInfoItemColor: themeData["listInfoItemColor"],
        selectionHighlight: themeData["selection_highlight"],
        promotionLine: themeData["promotion_line"],
        gameHighlight: themeData["game_highlight"],
        betSlipLine: themeData["betslip_line"],
        marketGroupUnderline: themeData["marketgroup_underline"],
        maxBet: themeData["maxbet"],
        maxBetHover: themeData["maxbet_hover"],
        maxBetActive: themeData["maxbet_active"],
        myBetsLine: themeData["mybets_line"],
        resultsLine: themeData["results_line"],

        asset1: themeData["line-color"],
        asset2: themeData["place-bet"],
        asset3: themeData["place-bet-light"],
        asset4: themeData["text-buttons-color"],
      });
    }
  };

  static getVideo = (url, ref, startTime = null) => {
    if (HLS.isSupported()) {
      hls = new HLS({startPosition: startTime});
      hls.loadSource(url);
      hls.attachMedia(ref.current);
      hls.on(HLS.Events.MANIFEST_PARSED, () => {
        ref.current.play();
      });
    }
  };

  static correctLanguageKey(value) {
    const LANGS = {
      en: "eng",
      ru: "rus",
      fa: "fas",
      hy: "arm",
      uk: "ukr",
      pt: "por",
      es: "spa",
      fr: "fra",
      tr: "tur",
      ka: "geo",
      zhh: "chn",
      zs: "zhh",
      ar: "arb",
      de: "ger",
      ko: "kor",
      pl: "pol",
      ja: "jpn",
      pb: "por_2",
    };

    return LANGS[value] ? LANGS[value] : value;
  }

  static getURLParams = () => {
    const iFrameURL = new URL(document.location.href);
    const urlParams = Object.fromEntries([...iFrameURL.searchParams.entries()]);
    switch (urlParams.gameId) {
      case "InstantTennis":
        urlParams.gameId = 1;
        break;
    }
    // TODO: In Firefox old versions, the native "fromEntries" method can't worked correctly.
    return urlParams;
  };

  static formatPeriodDate = value => {
    const currentTime = Date.now();
    const hoursList = [1, 2, 3, 6, 12, 24, 48, 72];
    const hour = hoursList[parseInt(value)];
    const oneHourInMilliseconds = 3600000;

    return currentTime - hour * oneHourInMilliseconds
  }

  static manageGoogleScript = activeGameID => {
    let scriptSRC;

    if (activeGameID === 14) {
      const tennisScript = document.getElementsByClassName("vs-analytic-tennis");
      tennisScript.type = "text/javascript";
      tennisScript.src = "https://www.googletagmanager.com/gtag/js?id=G-S77VETVTQE";

      const oldScript = document.getElementsByClassName('vs-analytic-tennis');
      if (oldScript.length) {
        oldScript[0].parentNode.removeChild(oldScript[0]);
      }

      document.head.append(tennisScript);

      scriptSRC = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'GTM-M4PN6X5');`;
    } else {
      const keysByGame = {
        1: 'GTM-56Z4KNG',
        2: 'GTM-NPXHHCR',
        3: 'GTM-KFPSGZ9',
        4: 'GTM-WGQ7JML',
        6: 'GTM-WZ87G9T',
        7: 'GTM-54BPCKT',
        9: 'GTM-56PBLHM',
        12: 'GTM-5QDS62C'
      }

      scriptSRC = `if ('<%= process.env.REACT_APP_ENV %>' === 'production') {((w, d, s, l, i) => {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js',});var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', "${keysByGame[activeGameID]}");}`;
    }

    const script = document.createElement('script');
    const oldScript = document.getElementsByClassName('vs-analytic');
    script.setAttribute('class', 'vs-analytic');
    if (oldScript.length) {
      oldScript[0].parentNode.removeChild(oldScript[0]);
    }

    const inlineCode = document.createTextNode(scriptSRC);
    script.appendChild(inlineCode);
    document.body.appendChild(script);
  }
}
