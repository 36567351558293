import React, { memo, useState } from "react";
import BetSlip from "../Bets/BetSlip/BetSlip";
import "./Match.scss";
import TennisInstantBetSlip from "./TennisInstantBetSlip/TennisInstantBetSlip";
import TennisInstantMarket from "./TennisInstantMarket/TennisInstantMarket";

function Match({
  changeScene,
  isSkipScene,
  setSkipeScene,
  selectedBetsId,
  setSelectedBetsId,
  betItems,
  setBetItems,
  setShowBetAmountError,
  showBetAmountError
}) {
  return (
    <>
      <TennisInstantMarket
        setBetItems={setBetItems}
        betItems={betItems}
        isSkipScene={isSkipScene}
        setSelectedBetsId={setSelectedBetsId}
        selectedBetsId={selectedBetsId}
      />
      <BetSlip>
        <TennisInstantBetSlip
          changeScene={changeScene}
          isSkipScene={isSkipScene}
          setSkipeScene={setSkipeScene}
          betItems={betItems}
          setBetItems={setBetItems}
          setSelectedBetsId={setSelectedBetsId}
          setShowBetAmountError={setShowBetAmountError}
          showBetAmountError={showBetAmountError}
        />
      </BetSlip>
    </>
  );
}

export default memo(Match);
