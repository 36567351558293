const BBIN_THEME_DATA = {
  mainBG: '#000000',

  color0: '#181818',
  color1: '#1f1f1f',
  color2: '#1c1c1c',
  color3: '#262626',
  color4: '#323232',
  color5: '#3b3b3b',
  color6: '#5a5a5a',
  odd: '#d4961b',
  odd_hover: "#e5a630",
  odd_active: "#cf911e",
  selection: "#cf911e",
  selection_hover: "#cf911e",
  selection_active: "#cf911e",
  listInfoItemColor: "#aa8136",

  asset1: '#e6524f',
  asset2: '#e6524f',
  asset3: '#ff615e',
  asset4: '#c29440',
}

export default class ThemeManager {

  /**
   * Apply the parsed dynamic theme data.
   */
  static applyTheme(themeData) {

    document.documentElement.style.setProperty('--main-bg', themeData.mainBG);
    document.documentElement.style.setProperty('--vs-col0', themeData.color0);
    document.documentElement.style.setProperty('--vs-col1', themeData.color1);
    document.documentElement.style.setProperty('--vs-col2', themeData.color2);
    document.documentElement.style.setProperty('--vs-col3', themeData.color3);
    document.documentElement.style.setProperty('--vs-col4', themeData.color4);
    document.documentElement.style.setProperty('--vs-col5', themeData.color5);
    document.documentElement.style.setProperty('--vs-col6', themeData.color6);
    document.documentElement.style.setProperty('--vs-odd', themeData.odd);
    document.documentElement.style.setProperty('--vs-odd_hover', themeData.odd_hover);
    document.documentElement.style.setProperty('--vs-odd_active', themeData.odd_active);
    document.documentElement.style.setProperty('--vs-asset1', themeData.asset1);
    document.documentElement.style.setProperty('--vs-asset2', themeData.asset2);
    document.documentElement.style.setProperty('--vs-asset3', themeData.asset3);
    document.documentElement.style.setProperty('--vs-asset4', themeData.asset4);

    if (themeData.selection_name)
    document.documentElement.style.setProperty('--vs-selection', themeData.selection_name);

    if (themeData.selection_name_hover)
    document.documentElement.style.setProperty('--vs-selection_hover', themeData.selection_name_hover);

    if (themeData.selection_name_active)
    document.documentElement.style.setProperty('--vs-selection_active', themeData.selection_name_active);

    if (themeData.listInfoItemColor)
    document.documentElement.style.setProperty('--vs-listInfoItemColor', themeData.listInfoItemColor);

    if (themeData.selectionHighlight)
    document.documentElement.style.setProperty('--vs-selectionHighlight', themeData.selectionHighlight);

    if (themeData.promotionLine)
    document.documentElement.style.setProperty('--vs-promotionLine', themeData.promotionLine);

    if (themeData.gameHighlight)
    document.documentElement.style.setProperty('--vs-gameHighlight', themeData.gameHighlight);

    if (themeData.betSlipLine)
    document.documentElement.style.setProperty('--vs-betSlipLine', themeData.betSlipLine);

    if (themeData.marketGroupUnderline)
    document.documentElement.style.setProperty('--vs-marketGroupUnderline', themeData.marketGroupUnderline);

    if (themeData.maxBet)
    document.documentElement.style.setProperty('--vs-maxBet', themeData.maxBet);

    if (themeData.maxBetHover)
    document.documentElement.style.setProperty('--vs-maxBetHover', themeData.maxBetHover);

    if (themeData.maxBetActive)
    document.documentElement.style.setProperty('--vs-maxBetActive', themeData.maxBetActive);

    if (themeData.myBetsLine)
    document.documentElement.style.setProperty('--vs-myBetsLine', themeData.myBetsLine);

    if (themeData.resultsLine)
    document.documentElement.style.setProperty('--vs-resultsLine', themeData.resultsLine);
  }
}

export {BBIN_THEME_DATA}