import React, { useState, useEffect, useContext, memo } from 'react';
import './MyBetItem.scss';
import { useTranslation } from "react-i18next";
import Utils from "../../../../utils/Utils";
import ReactTooltip from 'react-tooltip';
import RequestManager from "../../../../services/RequestManager";
import { AppContext } from "../../../../App";

function MyBetItem({ betData, index, showCashOutPopUp }) {
  const context = useContext(AppContext);
  const oddType = context.oddType.get;
  const [isOpen, setIsOpen] = useState(true);
  const [cashOutAmount, setCashOutAmount] = useState(betData['total_partial_cashout_amount']);

  const socket = RequestManager.getInstance().webSocket;
  const { t } = useTranslation();
  const betTypes = [t('single'), t('multiple'), t('system')];
  const openCloseClass = isOpen ? 'up' : 'down';
  const clockIconURL = process.env.PUBLIC_URL + './assets/icons/clock.png';

  useEffect(() => {
    socket.addEventListener(RequestManager.AUTO_CASH_OUT_DATA, handleCashOutUpdate);

    return () => {
      socket.removeEventListener(RequestManager.AUTO_CASH_OUT_DATA, handleCashOutUpdate);
    };
  }, [])

  const handleCashOutUpdate = ({ detail }) => {
    const cashOutUpdates = detail['cashout'];

    if (cashOutUpdates && Object.keys(cashOutUpdates).length) {
      Object.keys(cashOutUpdates).map(betID => {
        if (parseInt(betID) === betData['id']) {
          betData['total_partial_cashout_amount'] = Number(cashOutUpdates[betID]['amount']).toFixed(2);
          setCashOutAmount(Number(cashOutUpdates[betID]['amount']).toFixed(2));
        }
      })
    }
  }

  const getBetItems = () => {
    const items = [];

    betData.events.forEach((itemData, index) => {
      items.push(<div className='my-bets-bet-item' key={index}>
        <div className="mb-item-header">
          <p className="mb-time">{Utils.timestampToLocalTime(betData['date_time'])}</p>
          <p className="mb-id">{t('id') + ' ' + itemData['bet_id']}</p>
          <p className="mb-stadium-name">{itemData['sport_name']}</p>
          <img src={clockIconURL} alt="" className="clock-icon" data-tip={t("unsettled")} />
          <ReactTooltip />
        </div>
        <div className='mb-type'>{itemData['market_name']}</div>
        <div className="mb-item-footer">
          <p className="mb-player-name">{itemData['event_name']}</p>
          <p className="mb-coef">{Utils.getFormattedCoef(itemData['coeficient'], oddType)}</p>
        </div>
      </div>)
    });

    return items;
  }

  const getCashOutBlock = () => {
    if (betData['events'].length !== 1 || !betData['total_partial_cashout_amount']) {
      return;
    }

    return <div className="cash-out-block" onClick={() => showCashOutPopUp(betData)}>
      <div className='cash-out-button'>
        <p>{Utils.upperCase(t('cash-out'), context.language.get)}</p>
        <p>{cashOutAmount + ' ' + betData['currency']}</p>
      </div>
    </div>
  }

  const getPossibleWin = () => {

    return betData['possible_tax_amount']
      ? (betData['possible_win'] - betData['possible_tax_amount']).toFixed(2) + ' ' + betData['currency']
      : betData['possible_win'] + ' ' + betData['currency'];
  }

  return <div className='my-bet-element' key={index}>
    <div className='my-bet-element-title' onClick={() => setIsOpen(!isOpen)}>
      {betTypes[betData['type'] - 1]}
      <span className='open-close-arrow'><p className={openCloseClass}>&#10094;</p></span>
    </div>
    <div className='my-bet-element-content'>
      {isOpen ? getBetItems() : null}
    </div>
    {isOpen ? getCashOutBlock() : null}
    {
      isOpen ? <div className='win-info'>
        <div className="container">
          <p>{t('stake')}</p>
          <p>{betData['amount'] + ' ' + betData['currency']}</p>
        </div>
        {
          betData['stake_tax_amount'] ? <div className="container">
            <p>{t('tax amount')}</p>
            <p>{betData['stake_tax_amount'].toFixed(2) + ' ' + betData['currency']}</p>
          </div> : null
        }
        <div className="container">
          <p>{t('possible win')}</p>
          <p>{getPossibleWin()}</p>
        </div>
      </div> : null
    }
  </div>
}

export default memo(MyBetItem);