import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './BetSlipBlock.scss';
import Switcher from "./Switcher/Switcher";
import BannerContainer from "./BannerContainer/BannerContainer";
import {AppContext} from "../../App";
import BetItem from "./BetItem/BetItem";
import MaxBet from "./MaxBet/MaxBet";
import Select from "../Native/Select/Select";
import {useTranslation} from "react-i18next";
import QuickBetBlock from "./QuickBetBlock/QuickBetBlock";
import MyBets from "./MyBets/MyBets";
import RequestManager from "../../services/RequestManager";
import {CASH_OUT_SUBSCRIBE_DATA, OPENED_BETS_COUNT_DATA, UNSUBSCRIBE_DATA} from "../../services/RequestDatas";
import Utils from "../../utils/Utils";

function BetSlipBlock({showHistoryPopUp, showCashOutPopUp}) {
  const QUICK_BET = 'quick-bet';

  const SINGLE_BET = 'Single';
  const MULTIPLE_BET = 'Multiple';
  const SYSTEM_BET = 'System';

  const {t} = useTranslation();
  const socket = RequestManager.getInstance().webSocket;
  const context = useContext(AppContext);
  const betsData = context.betsData.get;
  const userLoggedIn = context.userLoggedIn.get;
  const isTaxCase = context.partnerConfig.get['tax_integration_type'] === 1;
  const betTypes = [SINGLE_BET, MULTIPLE_BET, SYSTEM_BET];
  const decimalTypes = [t('decimal'), t('fractional'), t('american'), t('hongkong'), t('malay'), t('indo')];

  const [activeTab, setActiveTab] = useState(0);
  const [betType, setBetType] = useState(Utils.getStorageData('betTypes') || SINGLE_BET);
  const [activeType, setActiveType] = useState(null);
  const [openedBetsCount, setOpenedBetsCount] = useState();

  // For "System" bet
  const [currentOpt, setCurrentOpt] = useState(null);
  const [isFailed, setIsFailed] = useState(false);
  const [betCoef, setBetCoef] = useState({names: [], coef: [], itemPrices: []});
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [selectedOption, setSelectedOption] = useState(0);
  const [typeIsManuallySelected, setBetTypeIsManuallySelected] = useState(false);
  const [shouldUpdateBetType, setShouldUpdateBetType] = useState(false);

  const subID = useRef(null);

  useEffect(() => {
    if(userLoggedIn) {
      socket.addEventListener(RequestManager.CASH_OUT_EVENT, getOpenedBetsCount);
    }
    return () => {
      socket.removeEventListener(RequestManager.CASH_OUT_EVENT, getOpenedBetsCount);
    }
  }, [userLoggedIn])

  useEffect(() => {
    const marketIsOpened = context.tennisUpdateState.get === 13;

    if (userLoggedIn && marketIsOpened && openedBetsCount) {
      getOpenedBetsCount();
    }
  }, [context.tennisUpdateState.get])

  useEffect(() => {
    if (activeTab === 0 && userLoggedIn) {
      socket.addEventListener(RequestManager.AUTO_GAME_DATA, checkMyBetsCount);
    }

    return () => {
      socket.removeEventListener(RequestManager.AUTO_GAME_DATA, checkMyBetsCount);
    }
  }, [activeTab, userLoggedIn])

  useEffect(() => {
    if (betType === SYSTEM_BET) {
      changeBetCoefOpt('0');
      updateSystemBetCoef(betsData.length);
    }

    if (userLoggedIn === false) {
      if(betsData.length > 0) {
        setOpenedBetsCount(0);
      }

      if(activeType === QUICK_BET) {
        setActiveType(null);
      }
    } else if (userLoggedIn) {
      getOpenedBetsCount();

      if (betsData.length <= 1) {
        setShouldUpdateBetType(true)
        Utils.writeStorage('betTypes', SINGLE_BET);
      }
    }

    if (activeTab === 1) {
      setActiveTab(0)
    }

    try {
      setIsFailed(Boolean(betsData.filter(el => el.status === 'failed').length));
    } catch {
      console.log('>> ERROR: fail status detection.');
    }
  }, [context.userLoggedIn.get, context.betsData.get]);

  useEffect(() => {
    const betsCount = context.betsData.get?.length;

    if (typeIsManuallySelected === true) {
      if (betsCount <= 1) {
        setBetTypeIsManuallySelected(false);
        changeBetType(0);
      }
      return;
    }
    if ( betType === SINGLE_BET && shouldUpdateBetType && betsCount > 1) {
      changeBetType(1);
    } else if (betsCount <= 1) {
      changeBetType(0);
    }
  }, [context.betsData.get])

  const checkMyBetsCount = ({detail}) => {
    const dataString = JSON.stringify(detail);
    if (userLoggedIn && openedBetsCount && dataString.includes('null')) {
      getOpenedBetsCount();
    }
  }

  const getOpenedBetsCount = () => {
      socket.send(JSON.stringify(OPENED_BETS_COUNT_DATA));
      socket.addEventListener(RequestManager.OPENED_BETS_COUNT_EVENT, updateOpenedBetsCount, {once: true});
  }

  const updateOpenedBetsCount = ({detail}) => {
    const data = detail['data'];
    if (data) {
      setOpenedBetsCount(parseInt(data['bets']));
    }
  }

  const changeDecimal = value => {
    context.oddType.set(value);
    Utils.writeStorage('oddType', value, 3600);
  }

  const getDecimalTypesMenu = () => {
    return <div className="decimal-types-checkbox">
      <Select defaultValue={context.oddType.get} list={decimalTypes} onChangeCallback={changeDecimal}/>
    </div>
  }

  /*const openCalculator = () => {
    context.calculatorOpened.set(!context.calculatorOpened.get);
  }*/

  const openBetSlip = () => {
    setActiveTab(0);

    if (subID.current) {
      UNSUBSCRIBE_DATA.params.subid = subID.current;
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    }
  }

  const openMyBets = () => {
    setActiveTab(1);
    if (userLoggedIn) {
      CASH_OUT_SUBSCRIBE_DATA.rid = Utils.getUniqNumber().toString();

      // Send cash-out request.
      socket.send(JSON.stringify(CASH_OUT_SUBSCRIBE_DATA));
      socket.addEventListener(RequestManager.CASH_OUT_SUBSCRIBE_EVENT, data => handleCashOutSubscribe(data.detail), {once: true});
    }
  }

  const handleCashOutSubscribe = ({data}) => {
    subID.current = data['subid'];
  }

  const getMyBetsTab = () => {
    return <div className={activeTab === 1 ? 'bet-slip-menu-tab active-tab' : 'bet-slip-menu-tab'} onClick={openMyBets}>
      {Utils.upperCase(t('my bets'), context.language.get)}
      {openedBetsCount && userLoggedIn ? <div className='active-bets-count'>{openedBetsCount}</div> : null}
    </div>
  }

  const getBetSlipMenu = () => {
    //const iconsURL = process.env.PUBLIC_URL + './assets/betslip/icons/calculator.svg';

    return <div className='bet-slip-menu'>
      <div className={activeTab === 0 ? 'bet-slip-menu-tab active-tab' : 'bet-slip-menu-tab'} onClick={openBetSlip}>
        {Utils.upperCase(t('bet slip'), context.language.get)}
        {/* <img className='calculator-icon' src={iconsURL} onClick={openCalculator} alt=''/>*/}
      </div>
      {getMyBetsTab()}
    </div>
  }

  const switcherEnableHandler = (id, enabled) => {
    setActiveType(enabled ? id : null);
  }

  const generateSingleBetOptions = n => {
    const betList = [];
    const coefList = [];
    let coef;

    for (let i = 2; i < n; i++) {
      coef = Utils.getBinomalCoef(i, n);
      coefList.push(coef);
      betList.push(`${i}/${n} ( ${coef} opt. )`);
    }

    return {betList, coefList};
  }

  const updateSystemBetCoef = n => {
    const itemPrices = [];
    betsData.forEach(item => {
      if (item) {
        itemPrices.push(item['price']);
      }
    });

    const options = generateSingleBetOptions(n);
    setBetCoef(betCoef => ({...betCoef, names: options['betList'], coef: options['coefList'], itemPrices}));
    setCurrentOpt(options['coefList'][0]);
  }

  const changeBetType = type => {
    if (betTypes[type] === SYSTEM_BET) {
      updateSystemBetCoef(betsData.length);
    }

    Utils.writeStorage('betTypes', betTypes[type], 3600);
    setBetType(betTypes[type]);
  }

  const getTotalCoef = () => {
    let totalCoef = 1;

    if (betType === SYSTEM_BET) {
      if (betsData.length <= 2) {
        return <div className='warning-bets-count'><span>{t('betsCount')}</span></div>
      }
      totalCoef = 0;
      const subs = Utils.getSubs(betCoef['itemPrices'], selectedIndex);
      subs.forEach(el => {
        totalCoef = totalCoef + el;
      })

      totalCoef = +totalCoef.toFixed(2);
    } else {
      betsData.forEach(item => {
        if (item) {
          totalCoef *= item['price'];
        }
      });
    }

    return totalCoef.toFixed(3);
  }

  const getOddsBlock = () => {
    if (betType === MULTIPLE_BET && betsData && betsData.length > 1) {
      const totalCoef = getTotalCoef();

      return <div className='bet-odds-block'>
        <p>{t('odds')} : <b>{Utils.getFormattedCoef(totalCoef, context.oddType.get)}</b></p>
      </div>
    }
  }

  const updateBetsData = data => {
    Utils.writeStorage('betsData', data, 3600);
    context.betsData.set(data);
  }

  const clearBetsData = useCallback(() => {
    context.betsData.get.forEach(item => {
      UNSUBSCRIBE_DATA.params.subid = item['subID'];
      socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
    })

    Utils.writeStorage('betsData', []);
    Utils.writeStorage('betTypes', null);
    setShouldUpdateBetType(false)
    context.betsData.set([]);
  }, [])

  const removeItem = itemData => {
    UNSUBSCRIBE_DATA.params.subid = itemData['subID'];
    socket.send(JSON.stringify(UNSUBSCRIBE_DATA));

    const filteredItems = context.betsData.get.filter(item => item.id !== itemData.id);
    updateBetsData(filteredItems);
  }

  const changeBetCoefOpt = opt => {
    const indexValue = +opt + 2;

    setSelectedOption(opt);
    setSelectedIndex(indexValue);
    setCurrentOpt(betCoef['coef'][opt]);
  }

  const getSystemBetSelect = () => {
    const showSelect = betType === SYSTEM_BET && betCoef['names'] && betCoef['names'].length && betsData.length > 2;

    return showSelect ? <Select list={betCoef['names']} hardValue={selectedOption} onChangeCallback={changeBetCoefOpt}/> : null
  }

  const getClearAllButton = () => {
    return <div className="clear-bet-items" onClick={() => clearBetsData()}>
      {betType === SYSTEM_BET ? '' : t('clear all')} &nbsp; &#10006;
    </div>;
  }

  const getBetsListContainer = () => {
    const types = [t('single'), t('multiple'), t('system')];

    return <div className="bets-list-container">
      <div className='bet-items-control-panel'>
        <Select hardValue={betTypes.indexOf(betType)} list={types} onChangeCallback={value => {
          changeBetType(value);
          setBetTypeIsManuallySelected(true);
        }}/>
        {getSystemBetSelect()}
        {getClearAllButton()}
      </div>
      {
        betsData.map((data) => {
          if (data) {
            return <BetItem itemData={data} betType={betType} isTaxCase={isTaxCase}
                            removeItem={removeItem} updateBetsData={updateBetsData} key={data.id}/>
          }
        })
      }
      {getOddsBlock()}
      <MaxBet totalCoef={getTotalCoef()} clearBetsCallback={clearBetsData} betType={betType} betTypes={betTypes}
              currentCoef={currentOpt} systemSelectedIndex={selectedIndex} isFailed={isFailed}/>
    </div>
  }

  const getActiveTabContent = () => {
    const activeTabIsBetSlip = activeTab === 0;
    if (activeTabIsBetSlip && betsData && betsData.length && activeType !== QUICK_BET) {
      return getBetsListContainer();
    }

    return <div className="container">
      <div className={activeTab === 0 ? 'bet-slip-container' : 'bet-slip-container hide'}>
        <div className='quick-bet-block'>
          <Switcher id='quick-bet' enableHandler={switcherEnableHandler} isEnabled={activeType === QUICK_BET}/>
          <p>{t('quick bets')}</p>
        </div>
        <QuickBetBlock clearBetsCallback={clearBetsData} isActive={activeType === QUICK_BET}/>
      </div>
      {
        activeTabIsBetSlip === false
          ? <MyBets showHistoryPopUp={showHistoryPopUp} showCashOutPopUp={showCashOutPopUp}
                    updateBetsCount={count => setOpenedBetsCount(count)}/>
          : null
      }
    </div>
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "login"}, '*');
  }

  const openRegistrationPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "register"}, '*');
  }

  const getLoginHint = () => {
    if (userLoggedIn) {
      return null;
    }

    return <div className='login-hint-block'>
      <div className='hint-container'>
        {t('to place bets')}
        <span onClick={() => openSignInPopUp()}> {t('sign in')} </span>
        {t('or')}<span onClick={() => openRegistrationPopUp()}> {t('register')} </span>
      </div>
    </div>
  }

  return <div className='bet-slip-block'>
    <div className='bet-slip-block-content'>
      {getDecimalTypesMenu()}
      {getBetSlipMenu()}
      {getActiveTabContent()}
      {getLoginHint()}
    </div>
    <BannerContainer/>
  </div>
}

export default BetSlipBlock;