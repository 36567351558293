import { memo, useContext, useEffect, useMemo, useState } from "react";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";
import PopUp from "../../../../../../layout/PopUp/PopUp";
import IsLoadingHOC from "../../../../../Hoc/IsLoadingHOC";
import { END_MATCH_DATA, MY_BETS_DATA } from "../../../../../VSAPI/APIData";
import ApiRequest from "../../../../../VSAPI/ApiRequest";
import PlayersBlock from "../../Match/PlayersBlock/PlayersBlock";
import BetSlip from "../BetSlip/BetSlip";
import "./endMatch.scss";

const EndMatchBets = ({ setLoading }) => {
  const tennisContext = useContext(TennisContext);
  const [data, setData] = useState(null);
  const [showPopUp, setShowPopUp] = useState(true);
  const [myBets, setMyBets] = useState([]);
  const MARKET_ID = tennisContext.marketsData.get.id;
  const baseURL = process.env.PUBLIC_URL + "./assets/instants/";
  const API_REQUEST = ApiRequest();

  useEffect(() => {
    END_MATCH_DATA.UserMatchID = MARKET_ID;
    const fetchData = async () => {
      await API_REQUEST("endMatch", "POST", END_MATCH_DATA)
        .then((res) => {
          setData(res.end_match_info);
          getMyBets();
        })
        .catch((err) => {
          console.log(err, ">>>");
        });
    };
    fetchData();
    setTimeout(() => {
      setShowPopUp(false);
    }, 5000);
  }, []);

  const getMyBets = () => {
    MY_BETS_DATA.UserMatchID = MARKET_ID;
    setLoading(true);
    API_REQUEST("myBets", "POST", MY_BETS_DATA)
      .then((res) => {
        setMyBets(res.current_bets);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, ">>>");
        setLoading(false);
      });
  };

  const winningAmount = useMemo(() => {
    const winningAmount = myBets.reduce(function (sum, current) {
      return sum + current.winning_amount;
    }, 0);

    const betStakeAmount = myBets.reduce(function (sum, current) {
      return sum + current.bet_stake;
    }, 0);

    return winningAmount > betStakeAmount ? winningAmount : 0;
  });

  const newMatch = () => {
    tennisContext.activeScene.set(0);
    tennisContext.isMatchEnded.set(false);
  };

  const showBetItem = (item) => {
    let data = {};
    switch (item.state) {
      case 1:
        data.status = "✖ Lost";
        data.text = " ";
        break;
      case 3:
        data.status = (
          <span>
            {" "}
            <span className="won-icon">✔</span> Won
          </span>
        );
        data.text = "Winning Amount";
        break;
      case 5:
        data.status = (
          <>
            <img src={baseURL + "icons/cashOutIcon.png"} /> Cashed Out
          </>
        );
        data.text = "Cashed Out";
        data.odd = "Cashed Out Amount";
    }

    return (
      <div className="my-bets-instant-item" key={item.bet_id}>
        <div>
          <p className="state">
            <span>Bet ID: {item.bet_id}</span>
            <span className={`${item.state === 1 ? "lost" : ""}`}>
              {data.status}
            </span>
          </p>
          <p>
            <span>{item.market_name}</span>
            <span>{item.selection_name}</span>
          </p>
          <p>
            <span> Stake </span>
            <span>{`${item.bet_stake} ${item.currency}`}</span>
          </p>
          <p>
            <span>{data.odd} ODD </span>
            <span className="odd">
              {item.state !== 5
                ? item.cashout_old_price
                : item.cashout_new_price}
            </span>
          </p>
          {data.text ? (
            <p>
              <span>{data.text}</span>
              <span>
                {item.winning_amount
                  ? item.winning_amount + " " + item.currency
                  : null}
              </span>
            </p>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="content endMatch">
        <PopUp show={showPopUp && winningAmount}>
          <>
            <img src={baseURL + "icons/congratulations.png"} />
            <p className="winningAmount">{`${winningAmount.toFixed(2)} ${
              tennisContext.currency.get
            }`}</p>
          </>
        </PopUp>

        <PlayersBlock
          data={data || tennisContext.marketsData.get}
          showScore={true}
        />

        <div className="results-info end-match">
          <div className="info">
            {myBets.length ? myBets.map((item, i) => showBetItem(item)) : null}
          </div>
        </div>
      </div>

      <BetSlip>
        <div className="buttons bet-slip-btns">
          <div className="button change-players" onClick={newMatch}>
            NEW MATCH
          </div>
        </div>
      </BetSlip>
    </>
  );
};

export default memo(IsLoadingHOC(EndMatchBets));
