import moment from "moment";
import { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "../../../../../../../layout/PopUp/PopUp";
import IsLoadingHOC from "../../../../../../Hoc/IsLoadingHOC";
import {
  BET_HISTORY_DATA,
  BET_URL_DATA,
} from "../../../../../../VSAPI/APIData";
import apiRequest from "../../../../../../VSAPI/ApiRequest";
import "./betHistory.scss";
import Utils from "../../../../../../../utils/Utils";
import DatePicker from "react-datepicker";
import { TennisContext } from "../../../../../../../components/PromotionBlock/PromotionBlock";

const BetsHistory = ({ setLoading }) => {
  const tennisContext = useContext(TennisContext);
  const baseURL = process.env.PUBLIC_URL + "./assets/instants/";
  const [betHistoryData, setBetHistoryData] = useState([]);
  const [betURL, setBetURL] = useState(null);
  const dayBySeconds = 24 * 60 * 60 * 1000;
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());
  const iconBaseURL = process.env.PUBLIC_URL + "./assets/instants/icons/";
  const API_REQUEST = apiRequest();
  const video = useRef();
  const { t } = useTranslation();
  const fromCalendar = useRef();
  const toCalendar = useRef();
  const { gameId } = Utils.getURLParams();

  useEffect(() => {
    getBetHistoryList();
  }, []);

  useEffect(() => {
    if (betURL) {
      Utils.getVideo(betURL, video);
    }
  }, [betURL]);

  const getBetHistoryList = () => {
    BET_HISTORY_DATA.FromDate = new Date(startDate).setHours(0, 0, 0) / 1000;
    BET_HISTORY_DATA.SportId =
      gameId || tennisContext.activeInstantGameData.get.id;
    BET_HISTORY_DATA.ToDate =
      new Date(endDate + dayBySeconds).setHours(0, 0, 0) / 1000;
    setLoading(true);

    API_REQUEST("betHistory", "POST", BET_HISTORY_DATA)
      .then((res) => {
        setBetHistoryData(res.bet_history);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, ">>>");
      });
  };

  const getBetVideoURL = (betID) => {
    BET_URL_DATA.BetId = betID;
    setLoading(true);
    API_REQUEST("beturl", "POST", BET_URL_DATA)
      .then((res) => {
        setBetURL(res.bet_url.Url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, ">>>");
      });
  };

  const manageCalendarClosing = (event, calendarType) => {
    if (event.target.tagName === "INPUT") {
      if (calendarType === "from" && fromCalendar.current.state.open) {
        setTimeout(() => fromCalendar.current.setOpen(false), 200);
      } else if (calendarType === "to" && toCalendar.current.state.open) {
        setTimeout(() => toCalendar.current.setOpen(false), 200);
      }
    }
  };

  const getBetStatus = useCallback((betItem) => {
    switch (betItem.state) {
      case 1:
        return "lost";
      case 3:
        return "win";
      case 5:
        return "cashOut";
    }
  });

  return (
    <>
      <div className="filter-bet-history-instant">
        <div className="game-name">INSTANT TENNIS</div>
        <div className="date">
          <div onMouseDown={(event) => manageCalendarClosing(event, "from")}>
            <label>FROM</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date.valueOf())}
              dateFormat="dd/MM/yyyy"
              maxDate={endDate}
              className="calendar"
              ref={fromCalendar}
              onFocus={(e) => (e.target.readOnly = true)}
            />
          </div>
          <div onMouseDown={(event) => manageCalendarClosing(event, "to")}>
            <label>TO</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date.valueOf())}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
              maxDate={Date.now()}
              className="calendar"
              ref={toCalendar}
              onFocus={(e) => (e.target.readOnly = true)}
            />
          </div>
          <div>
            <img
              src={iconBaseURL + "searchIcon.png"}
              onClick={getBetHistoryList}
            />
          </div>
        </div>
      </div>
      <div className="bet-history-instant">
        <PopUp
          show={betURL}
          callback={() => setBetURL(null)}
          classN={"history-pop-up"}
        >
          <video
            className="video-player"
            ref={video}
            src=""
            autoPlay
            type="application/x-mpegURL"
            onEnded={() => setBetURL(null)}
          />
        </PopUp>
        <div className="bet-history-instant-header">
          <div className="bet-history-instant-header-items">
            {[
              "Details",
              "Results",
              "Bet",
              "Stake",
              "Outcome",
              "Status",
              "Play back",
            ].map((item, i) => (
              <div key={i}>
                {item} {i !== 6 && <span className="border"></span>}
              </div>
            ))}
          </div>
        </div>
        <div className="bet-history-instant-content">
          {betHistoryData.length
            ? betHistoryData.map((item, i) => (
                <div key={i}>
                  <div className="row">
                    <div className="column ">
                      <div className="details">
                        <div>
                          <span className="date">
                            {moment(item.bet_date).format("MM/DD/YY")}
                          </span>
                          <span className="hour">
                            {moment(item.bet_date).format("HH:mm")}
                          </span>
                        </div>
                        <p className="bet-id">
                          Bet ID: <span>{item.bet_id}</span>
                        </p>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column results">
                      <div className="results-info">
                        <span className="user-match-name">
                          {item.user_match_name}
                        </span>
                        <span className="score">
                          Score:({item.user_match_info})
                        </span>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column bet">
                      <div>
                        <span>{item.market_name}</span>
                        <span className="winner-name">
                          {item.selection_name}
                        </span>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column">
                      <div>
                        <span className="stake-amount">{`${item.bet_stake} ${item.currency}`}</span>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column">
                      <div>
                        <span className="win-amount">
                          {" "}
                          {`${item.win_amount} ${item.currency}`}
                        </span>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column status">
                      <div>
                        <span className="status">
                          {getBetStatus(item) ? (
                            <img
                              src={iconBaseURL + getBetStatus(item) + ".png"}
                            />
                          ) : null}
                          <span className="status-text">{getBetStatus(item)}</span>
                        </span>
                      </div>
                      <span className="border"></span>
                    </div>
                    <div className="column video">
                      {item.has_url ? (
                        <img
                          src={baseURL + "icons/videoIcon.png"}
                          onClick={() => getBetVideoURL(item.bet_id)}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(BetsHistory);
