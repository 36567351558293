import Utils from "../utils/Utils";

export const AUTHORIZATION_RID = Utils.getUniqNumber().toString();
export const SPORTS_RID = Utils.getUniqNumber().toString();
export const GAME_RID = Utils.getUniqNumber().toString();
export const REGION_RID = Utils.getUniqNumber().toString();
export const PROFILE_RID = Utils.getUniqNumber().toString();
export const RE_CAPTCHA_MAX_DATA_RID = Utils.getUniqNumber().toString();
export const BONUS_RID = Utils.getUniqNumber().toString();
export const GAME_RESULTS_RID = Utils.getUniqNumber().toString();
export const CURRENT_LEAGUE_RID = Utils.getUniqNumber().toString();
export const MARKET_RID = Utils.getUniqNumber().toString();
export const LEAGUE_ROUNDS_RID = Utils.getUniqNumber().toString();
export const WRESTLING_RID = Utils.getUniqNumber().toString();
export const TENNIS_RID = Utils.getUniqNumber().toString();
export const CHECK_BET_RID = Utils.getUniqNumber().toString();
export const BET_HISTORY_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_COUNT_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_RID = Utils.getUniqNumber().toString();
export const PARTNER_CONFIG_RID = Utils.getUniqNumber().toString();
export const CASH_OUT_RID = Utils.getUniqNumber().toString();
export const MAX_BET_RID = Utils.getUniqNumber().toString();
export const UNSUBSCRIBE_RID = Utils.getUniqNumber().toString();
export const CHANGE_LANG_RID = Utils.getUniqNumber().toString();
export const AUTO_DATA_UPDATE_RID = "0";
export const SWARM_LOGIN_RID = "1";

const AUTHORIZATION_DATA = {
  command: "request_session",
  params: {
    site_id: "1",
    language: "eng",
    aut_token: "E2300C86022F96246F4C6060DDF04A59",
  },
  rid: AUTHORIZATION_RID,
};

const PARTNER_CONFIG_DATA = {
  command: "get",
  params: {
    source: "partner.config",
    what: {partner: []},
    subscribe: true,
  },
  rid: PARTNER_CONFIG_RID,
};

const SWARM_LOGIN_DATA = {
  command: "login",
  params: {
    username: "aramatanesian@gmail.com",
    password: "Atanesyan7",
  },
  rid: SWARM_LOGIN_RID,
};

const CHANGE_LANG_DATA = {
  command: "change_session_language",
  params: {
    language: ''
  },
  rid: CHANGE_LANG_RID
};

const LOGIN_DATA = {
  command: "restore_login",
  params: {
    user_id: "",
    auth_token: "F79EBA818616C513A7C2AA66C63FBC4A",
  },
  rid: null,
};

const LOGOUT_DATA = {
  command: "logout",
  params: {},
  rid: null,
};

const PROFILE_DATE = {
  command: "get",
  params: {
    source: "user",
    what: {
      profile: [],
    },
    subscribe: true,
  },
  rid: PROFILE_RID,
};

const SPORTS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      sport: ["id", "name", "alias", "order"],
      game: ["@count", "start_ts", "text_info"],
      region: ["id"],
    },
    where: {
      game: {"@or": [{type: 0}, {visible_in_prematch: true, type: 4}]},
      sport: {id: {"@in": [14, 1, 2, 7, 9, 6, 3, 4, 12]}},
    },
    subscribe: true,
  },
  subID: null,
  rid: SPORTS_RID,
};

const LEAGUE_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      competition: [
        "id",
        "name",
        "order",
        "info",
        "video_id",
        "provider",
        "info",
      ],
    },
    where: {
      game: {"@or": [{type: 0}, {visible_in_prematch: true, type: 4}]},
      sport: {id: 1},
    },
    subscribe: true,
  },
  subID: null,
  rid: CURRENT_LEAGUE_RID,
};

const UNSUBSCRIBE_DATA = {
  command: "unsubscribe",
  params: {
    subid: "6166728670616548707",
  },
  rid: UNSUBSCRIBE_RID,
};

const VIDEO_DATA = {
  command: "video_url",
  params: {
    video_id: 9,
    provider: 16,
    use_hls: true,
  },
  rid: GAME_RID,
};

const REGION_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      region: ["id", "name"],
      competition: ["id", "name"],
    },
    where: {
      sport: {
        id: 1,
      },
    },
  },
  rid: REGION_RID,
};

const GAME_RESULTS_DATA = {
  command: "get_result_games",
  params: {
    is_date_ts: 1,
    sport_id: 1,
    competition_id: 1,
    from_date: 1,
    to_date: 1,
  },
  rid: GAME_RESULTS_RID,
};

const GAME_MATCH_ID_RESULT = {
  command: "get_results",
  params: {
    game_id: 1507476873,
  },
  rid: "10925654702008.588",
};

const MARKET_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      sport: ["id", "alias"],
      competition: ["id", "name"],
      region: ["id"],
      game: [],
      market: [],
      event: [],
    },
    where: {
      game: {id: 1123279},
      sport: {id: 1},
      region: {id: 1},
    },
    subscribe: true,
  },
  subID: null,
  rid: MARKET_RID,
};

const LEAGUE_ROUNDS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: [
        "game_number",
        "team1_name",
        "team2_name",
        "id",
        "start_ts",
        "text_info",
        "info",
        "round",
        "homescore",
        "awayscore",
      ],
    },
    where: {
      competition: {id: 20},
    },
    subscribe: true,
  },
  subID: null,
  rid: LEAGUE_ROUNDS_RID,
};

const WRESTLING_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: [
        "weight",
        "game_number",
        "isLive",
        "team1_name",
        "team2_name",
        "id",
        "start_ts",
        "text_info",
        "round",
        "homescore",
        "awayscore",
        "team1_region",
        "team2_region",
      ],
    },
    where: {
      competition: {id: 28},
    },
    subscribe: true,
  },
  subID: null,
  rid: WRESTLING_RID,
};

const TENNIS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: [
        "game_number",
        "team1_name",
        "team2_name",
        "id",
        "start_ts",
        "update_state",
        "text_info",
        "info",
        "round",
        "homescore",
        "awayscore",
      ],
    },
    where: {competition: {id: 30}},
    subscribe: true,
  },
  subID: null,
  rid: TENNIS_RID,
};

const BET_CHECK_DATA = {
  command: "get",
  params: {
    source: "betting",
    is_betslip: true,
    what: {
      game: [
        "id",
        "is_blocked",
        "team1_name",
        "team2_name",
        "team1_reg_name",
        "team2_reg_name",
        "is_live",
      ],
      market: [
        "base",
        "type",
        "name",
        "home_score",
        "away_score",
        "cashout",
        "extra_info",
      ],
      event: ["id", "price", "type", "type_1", "name", "base", "ew_allowed"],
    },
    where: {
      game: {id: {"@in": []}},
      event: {id: {"@in": []}},
      market: {id: {"@in": []}},
    },
    subscribe: true,
  },
  rid: CHECK_BET_RID,
};

const RE_CAPTCHA_DATA = {
  command: "validate_recaptcha",
  params: {
    action: "do_bet",
    g_recaptcha_response:
      "03AGdBq25FtdF4fH3RrHXRcgcli6ucLVTde6mtXDz8IWkt3U4X-eFfUCURSNWjM94TGhWwl7m-JXan9Qq4xD_lekAvmCFTk_St6ILxlShbE0Fr0u6lMvLTPorg359lLdubQIC3OFpg7J6pCUr_2w7uAOcfkUxRiLNFnHTCXThi0OuP3L1VB78SdKI7a0oq3OfoXewCaULkGC9jQOg1JDykjQNJhuKDgzAs5Y3oZSuKP-ffF4qV15hbJ-nCQ5JFDJix8U43vAOmk1OA-h7wcNvN36MoAMkbb-mTSNML224w_LUPRNgWejweEEBOZxo4FOi3oRO9hDtuunvt3deNNkM6fZLkMu4nXLwV1OgbfZKlLf8TwfHZz5AkINDtHDEmC8xQCOH8GucYtyuAJ7zQi9c-E0i18YGMVEVtFx9iLPqtoMfW7Xax2ckzYPU",
  },
  rid: null,
};

const RE_CAPTCHA_MAX_DATA = {
  command: "validate_recaptcha",
  params: {
    action: "get_max_bet",
    g_recaptcha_response: "03AGdBq25FtdF4fH3RrHXRcgcli6ucLVTde6mtXDz8IWkt3U4X-eFfUCURSNWjM94TGhWwl7m-JXan9Qq4xD_lekAvmCFTk_St6ILxlShbE0Fr0u6lMvLTPorg359lLdubQIC3OFpg7J6pCUr_2w7uAOcfkUxRiLNFnHTCXThi0OuP3L1VB78SdKI7a0oq3OfoXewCaULkGC9jQOg1JDykjQNJhuKDgzAs5Y3oZSuKP-ffF4qV15hbJ-nCQ5JFDJix8U43vAOmk1OA-h7wcNvN36MoAMkbb-mTSNML224w_LUPRNgWejweEEBOZxo4FOi3oRO9hDtuunvt3deNNkM6fZLkMu4nXLwV1OgbfZKlLf8TwfHZz5AkINDtHDEmC8xQCOH8GucYtyuAJ7zQi9c-E0i18YGMVEVtFx9iLPqtoMfW7Xax2ckzYPU",
  },
  rid: RE_CAPTCHA_MAX_DATA_RID,
  requestName: 'RE_CAPTCHA_DATA'
};

const PLACE_BETS_DATA = {
  command: "do_bet",
  params: {
    type: 1,
    mode: 0,
    tags: 0,
    odd_type: 1,
    is_offer: 0,
    amount: 50,
    source: "42",
    each_way: false,
    bets: [],
  },
  rid: null,
};

const BET_HISTORY_DATA = {
  command: "bet_history",
  params: {
    where: {
      from_date: 1600718400,
      to_date: 1602619199,
    },
  },
  rid: BET_HISTORY_RID,
};

const OPENED_BETS_COUNT_DATA = {
  command: "bet_history",
  params: {
    where: {outcome: 0, only_counts: true},
  },
  rid: OPENED_BETS_COUNT_RID,
};

const OPENED_BETS_DATA = {
  command: "bet_history",
  params: {
    where: {outcome: 0},
  },
  rid: OPENED_BETS_RID,
};

const CASH_OUT_DATA = {
  command: "cashout",
  params: {
    bet_id: 1417233517,
    price: 41.3,
    mode: 0,
  },
  rid: CASH_OUT_RID,
};

const CASH_OUT_SUBSCRIBE_DATA = {
  command: "get",
  params: {
    source: "notifications",
    what: {user: []},
    subscribe: true,
  },
  rid: null,
};

const MAX_BET_DATA = {
  command: "get_max_bet",
  params: {
    type: 1,
    each_way: false,
    events: [2060437739, 2060438579],
  },
  rid: MAX_BET_RID,
};

export {
  AUTHORIZATION_DATA,
  PARTNER_CONFIG_DATA,
  SPORTS_DATA,
  VIDEO_DATA,
  REGION_DATA,
  CHANGE_LANG_DATA,
  GAME_RESULTS_DATA,
  GAME_MATCH_ID_RESULT,
  MARKET_DATA,
  LEAGUE_DATA,
  LEAGUE_ROUNDS_DATA,
  WRESTLING_DATA,
  TENNIS_DATA,
  BET_CHECK_DATA,
  PLACE_BETS_DATA,
  SWARM_LOGIN_DATA,
  BET_HISTORY_DATA,
  OPENED_BETS_DATA,
  OPENED_BETS_COUNT_DATA,
  LOGIN_DATA,
  PROFILE_DATE,
  CASH_OUT_DATA,
  CASH_OUT_SUBSCRIBE_DATA,
  LOGOUT_DATA,
  MAX_BET_DATA,
  RE_CAPTCHA_DATA,
  RE_CAPTCHA_MAX_DATA,
  UNSUBSCRIBE_DATA,
};
