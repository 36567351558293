import React, {memo, useContext} from 'react';
import './MarketBlock.scss';
import {AppContext} from "../../App";
import BasicMarket from "./BasicMarket/BasicMarket";
import LargeMarket from "./LargeMarket/LargeMarket";
import LeagueMarket from "./LeagueMarket/LeagueMarket";
import WrestlingMarket from "./WrestlingMarket/WrestlingMarket"
import Utils from "../../utils/Utils";

const TennisMarket = React.lazy(() => import('./TennisMarket/TennisMarket'));

function MarketBlock() {
  const appContext = useContext(AppContext);
  const activeGameID = appContext.activeGameID.get;
  const activeSportData = appContext.sportsData.get.find(data => data.id === activeGameID);
  const basicMarketGameIDs = [3, 4, 6, 12];

  if (activeSportData === undefined) {
    return null;
  }

  const setBetsData = bets => {
    const currentBets = [...bets];

    Utils.writeStorage('betsData', currentBets, 3600);
    appContext.betsData.set(currentBets);
  }

  const content = () => {
    const isVirtualFootballLeague = activeGameID === 7;
    const isWrestling = activeGameID === 13;
    const isTennis = activeGameID === 14;

    if (isVirtualFootballLeague) {
      return <LeagueMarket setBetsData={setBetsData}/>;
    } else if (isWrestling) {
      return <WrestlingMarket setBetsData={setBetsData}/>;
    } else if (isTennis) {
      return <React.Suspense fallback={''}>
        <TennisMarket setBetsData={setBetsData}/>
      </React.Suspense>
    }

    return basicMarketGameIDs.indexOf(activeGameID) !== -1
      ? <BasicMarket setBetsData={setBetsData}/>
      : <LargeMarket setBetsData={setBetsData}/>;
  }

  return <div className="market-block">
    {content()}
  </div>
}

export default memo(MarketBlock);