import { memo, useContext } from "react";
import ReactStars from "react-rating-stars-component";
import { TennisContext } from "../../../../../../components/PromotionBlock/PromotionBlock";
import "./playersBlock.scss";

const PlayersBlock = ({ data, showScore }) => {
  const tennisContext = useContext(TennisContext);
  const players = process.env.PUBLIC_URL + "./assets/instants/tennisPlayers/";
  const icons = process.env.PUBLIC_URL + "./assets/instants/icons/";

  const getPlayerBlock = (playerId, playerName, star, servingPlayer) => {
    const firstExample = {
      size: 30,
      value: star,
      edit: false,
      isHalf: star,
    };

    return (
      <div className="player-block-inst">
        <div className="player-block-inst-box">
          {servingPlayer ? (
            <div className="tennis-ball-box">
              <img className="ball" src={icons + "/ball.png"} />
              <div className="shadow"></div>
            </div>
          ) : null}
          <img
            className="player-icon"
            src={players + playerId + ".png"}
            alt={""}
          />
          <div className="name">{playerName}</div>
          <ReactStars {...firstExample} classNames={"player-star"} />
        </div>
      </div>
    );
  };

  return (
    <div className="tennis-players-block">
      {getPlayerBlock(
        data?.home_team_id,
        data?.home_team_name,
        tennisContext.teamStars.get.homeTeam,
        data?.serving_player === 0
      )}
      <div className="vs-container">
        {data?.home_score > 0 || data?.away_score > 0 || showScore ? (
          <div className="score-container">
            {data?.w >= 0 &&
            tennisContext.activeScene.get === 4 &&
            tennisContext.sceneData.get.message_id === 15
              ? `${!data.w ? data?.home_score + 1 : data?.home_score} - ${
                  data.w ? data?.away_score + 1 : data?.away_score
                }`
              : data
              ? `${data?.home_score} - ${data?.away_score}`
              : null}
          </div>
        ) : (
          <img
            className="vs"
            src={process.env.PUBLIC_URL + "./assets/icons/vs.png"}
            alt=""
          />
        )}
      </div>
      {getPlayerBlock(
        data?.away_team_id,
        data?.away_team_name,
        tennisContext.teamStars.get.awayTeam,
        data?.serving_player === 1
      )}
    </div>
  );
};

export default memo(PlayersBlock);
